export enum RequestStatus {
  idle,
  loading,
  succeeded,
  failed
}

export enum ResourceType {
  ANY = 'ANY',
  SYSTEM = 'SYSTEM',
  APPLICATION = 'APPLICATION',
  IAM = 'IAM',
  IOT_DEVICE = 'IOT_DEVICE'
}

export enum AuditEvent {
  START_DEPLOYMENT,
  MODIFY_USER_ACCOUNT
}

export enum DeploymentStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
  NO_DEPLOYMENT = 'NO_DEPLOYMENT'
}