import { Container, Tab, Tabs } from '@mui/material';
import { Link, Outlet, Route, Routes, matchPath, useLocation, useParams } from "react-router-dom";
import { UNDEFINED_DEVICE_ID } from "../../utils";
import { DeviceServerConfiguration, DeviceLoggingConfiguration } from "./DeviceConfiguration";
import SoftwareVersion from "./SoftwareVersion";
import { BaseRoute, SubRoute } from '../../enums';
import DeviceLogs from '../DeviceLogs';
import { Permission, hasPermisssion } from '../../model/security';
import { useAppSelector } from '../../hooks';
import { selectCurrentAuth } from '../../reducers/authSlice';

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

function IotDeviceTabs(props: { iotDevice: string }) {

  const { iotDevice } = props

  const auth = useAppSelector(selectCurrentAuth)

  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:  users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch([
    `/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.SOFTWARE}`,
    `/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.CONFIG_SERVER}`,
    `/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.CONFIG_LOGGING}`,
    `/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.LOGS}`])
  const currentTab = routeMatch?.pattern?.path

  return (
    <Tabs value={currentTab}>
      <Tab label="Software" value={`/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.SOFTWARE}`} 
        to={`/${BaseRoute.CONTROL_UNITS}/${iotDevice}/${SubRoute.SOFTWARE}`} component={Link} />

      {hasPermisssion(auth, Permission.READ_LOGS) &&
        <Tab label="Logs" value={`/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.LOGS}`} 
          to={`/${BaseRoute.CONTROL_UNITS}/${iotDevice}/${SubRoute.LOGS}`} component={Link} />
      }
      
      <Tab label="Settings" value={`/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.CONFIG_SERVER}`} 
        to={`/${BaseRoute.CONTROL_UNITS}/${iotDevice}/${SubRoute.CONFIG_SERVER}`} component={Link} />

      <Tab label="Logging" value={`/${BaseRoute.CONTROL_UNITS}/:id/${SubRoute.CONFIG_LOGGING}`} 
        to={`/${BaseRoute.CONTROL_UNITS}/${iotDevice}/${SubRoute.CONFIG_LOGGING}`} component={Link} />
    </Tabs>
  )
}

export default function IotDevice() {
  const { iotId } = useParams();
  const iotDevice = iotId ?? UNDEFINED_DEVICE_ID;

  return (
      <Container maxWidth='xl' sx={{ background: 'white' }}>
      <IotDeviceTabs iotDevice={iotDevice}/>
      <Routes>
        <Route path={SubRoute.SOFTWARE} element={<SoftwareVersion iotDevice={iotDevice} />} />
        <Route path={SubRoute.CONFIG_SERVER} element={<DeviceServerConfiguration iotDevice={iotDevice} />} />
        <Route path={SubRoute.CONFIG_LOGGING} element={<DeviceLoggingConfiguration iotDevice={iotDevice} />} />
        <Route path={SubRoute.LOGS} element={<DeviceLogs iotDevice={iotDevice} />} />
      </Routes>
      <Outlet />
    </Container>
  );
}
