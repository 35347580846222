import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { useGetCompanyAppConfigurationQuery, useUpdateCompanyAppConfigurationMutation } from '../../reducers/apiSlice';
import { selectCompanyId, selectCurrentAuth } from '../../reducers/authSlice';
import { getErrorMessage } from '../../utils';
import { Permission, hasPermisssion } from '../../model/security';

export const ApplicationConfiguration = (props: { appId: string, configKey: string }) => {
  const { appId, configKey } = props

  const [errorMessage, setErrorMessage] = useState("");
  const auth = useAppSelector(selectCurrentAuth)
  
  const companyId = useAppSelector(selectCompanyId)
  const {
    data: configuration = { value: '' },
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetCompanyAppConfigurationQuery({ appId, key: configKey, companyId})

  const [editedConfiguration, setEditedConfiguration] = useState<string>("")

  useEffect(() => {
    setEditedConfiguration(configuration.value)
  }, [configuration.value]);

  const [updateConfiguration, {
    isSuccess: isUpdateSuccess, 
    isError: isUpdateError,
    isLoading: isUpdateLoading,
    error: updateError }] = useUpdateCompanyAppConfigurationMutation()

  const saveConfigHandler = async () => {
    await updateConfiguration({
      appId,
      companyId,
      iotDevice: appId,
      config: {
        key: configKey,
        value: editedConfiguration
      }
    }).unwrap().catch(err => setErrorMessage(getErrorMessage(err)))
  }

  const handleConfigurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedConfiguration(e.target.value)
    setErrorMessage("")
  }

  let updateStatus
  if (isUpdateSuccess) {
    updateStatus = <Alert severity="info">Settings have been saved. You may need to restart the device to apply the changes.</Alert>
  } else if (isUpdateError) {
    updateStatus = <Alert severity="error">{getErrorMessage(updateError)}</Alert>
  }

  let content
  if (isLoading) {
    content = <><CircularProgress color="primary" /></>
  } else if (isSuccess || (isError && (error as any).status === 404)) {
    content =
      <>
        <Box sx={{ display: "flex", pt: 2, flexDirection: "column", width: '100%' }}>
          {isError && <Alert severity="info">{getErrorMessage(error)}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          
          {updateStatus}

          <Box sx={{ overflow: 'auto', }}>
            <TextField
              sx={{ m: 1, width: 1060 }}
              InputProps={{
                style: { fontFamily: 'Monospace', fontSize: 13 },
              }}
              fullWidth
              multiline
              minRows={4}
              disabled={hasPermisssion(auth, Permission.UPDATE_APP_CONFIGURATION) ? false : true}
              value={editedConfiguration}
              onChange={handleConfigurationChange}
          />

          {!hasPermisssion(auth, Permission.UPDATE_APP_CONFIGURATION) &&
            <Alert severity="info">
              You don't have a permission to change the configuration.
            </Alert>}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
          <LoadingButton sx={{ width: 180, m: 1 }}
            loading={isUpdateLoading}
            disabled={hasPermisssion(auth, Permission.UPDATE_APP_CONFIGURATION) ? false : true}
            startIcon={<SaveAltIcon />}
            loadingPosition="start" variant="contained"
              onClick={saveConfigHandler}>
              Apply Changes
            </LoadingButton>
          </Box>
        </Box>
      </>
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  } else if (updateError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Container maxWidth='xl' sx={{ background: 'white' }}>{content}</Container>
  )
}
