import { useAppSelector } from "../hooks";
import { selectCurrentUser } from "../reducers/authSlice";
import HomePublic from "./HomePublic";
import { Navigate } from "react-router-dom";
import { BaseRoute } from "../enums";

export default function Home() {

  const user = useAppSelector(selectCurrentUser);
 
  if (user == null) {
    return <HomePublic/>
  } else {
    return <Navigate to={"/" + BaseRoute.DASHBOARD} />;
  }
}
