import {
  Alert, Backdrop,
  Box,
  Button,
  Card, CardMedia,
  CircularProgress,
  Typography
} from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseRoute, SubRoute } from '../../enums';
import { useGetStoreApplicationQuery } from "../../reducers/apiSlice";
import { getErrorMessage } from '../../utils';

export default function StoreApplication() {
  const { idAndVersion } = useParams();
  if (idAndVersion) {
    return <StoreApplicationPrivate idAndVersion={idAndVersion} />
  } else { return <></>}
}

const StoreApplicationPrivate = ({idAndVersion} : {idAndVersion: string}) => {
  
  const navigate = useNavigate();

  const {
    data: storeApplication,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStoreApplicationQuery({ idAndVersion })

  let content

  const handleConfiguration = (idAndVersion: string) => {
    navigate(`/${BaseRoute.STORE}/${idAndVersion}/${SubRoute.CONFIGURATOR}`)
  }

  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    content =
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', mt: 4 }}>
          <Card sx={{ borderRadius: '32px', height: '203px', display: 'flex', bgcolor: 'white', width: '210px', m: 2 }}>
            <CardMedia
              sx={{ width: 'auto', mixBlendMode: 'multiply', m: 'auto' }}
              component="img"
              alt="Application"
              height="115px"
              image="/images/pick_and_place.svg" />
          </Card>
          <Box sx={{ m: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignItems: { md: 'flex-start', xs: 'center' } }}>
              <Typography variant="h6">{storeApplication.name}</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>{storeApplication.description}</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>Version: {storeApplication.version}</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>Vendor: {storeApplication.vendor}</Typography>
            </Box>
          </Box>
          <Box sx={{ m: 2 }}>
            <Button
              variant="contained"
              onClick={() => handleConfiguration(idAndVersion)}
              sx={{ mt: 2, width: 120 }}>
              Configure
            </Button>
          </Box>
        </Box>
      </Container>
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      {content}
    </>
  )
}
