import log from "loglevel";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Robot } from "../../model/model";
import { useGetCompanyRobotsQuery } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import {
  getRobotAppMetrics, getRobotAppMetricsData,
  selectAppMetricDataMulti, selectAppMetricsMulti
} from "../../reducers/metricsSlice";
import { QueryType, endOfThisDay, startOfThisDay } from "../../utils";
import UtilisationChart from "../utilisation/UtilisationChart";
  
export default function DevicesUtilisationDashboard() {

  let toDate = endOfThisDay(new Date());
  let fromDate = new Date();
  fromDate.setDate(toDate.getDate() - 14);
  fromDate = startOfThisDay(fromDate);
  let aggrPeriod = 1440; // minutes
  const metricName = 'ProgramLoopCycle'

  const [metricTypesReady, setMetricTypesReady] = useState<boolean>(false)
  const [metricsRequested, setMetricsRequested] = useState<string[]>([])

  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectCompanyId)

  const {
    data: robots = [],
  } = useGetCompanyRobotsQuery(companyId)
 
  const appMetricsMulti = useAppSelector(selectAppMetricsMulti(metricName))
  const appMetricsMultiData = useAppSelector(
    selectAppMetricDataMulti(robots.map(robot => robot.id), QueryType.Dashboard))

  let metricLegend:any = {}
  robots.forEach(robot => metricLegend[robot.id] = robot.name)

  // Once we have a list of robots we can get available metrics for them
  useEffect(() => {
    async function fetchMetricTypes() {
      if (robots.length !== 0) {
        await Promise.all(robots.map(async (robot) => {
          await dispatch(
            getRobotAppMetrics({
              robotId: robot.id,
              deviceId: robot.iotDevice
            })
          )
        }))
        log.debug('Metric types loaded.')
        setMetricTypesReady(true)
      }
    }
    fetchMetricTypes()
  }, [robots]);

  const dispatchRobotMetric = async (robot: Robot, applications: string[]) => {
      await dispatch(
        getRobotAppMetricsData({
          robotId: robot.id,
          deviceId: robot?.iotDevice ?? "no-device-id",
          metricName: metricName,
          applicationIds: applications,
          startTime: fromDate,
          endTime: toDate,
          aggrPeriod: aggrPeriod,
          type: QueryType.Dashboard,
        })
      );
  };

  // Once we have a list of metrics we can query them for data
  useEffect(() => {
    async function fetchMetrics() {
      if (robots.length !== 0) {
        // console.log("Metrics found: " + appMetricsAllRobots)
        await Promise.all(robots.map(async (robot) => {
          if (appMetricsMulti !== undefined && appMetricsMulti[robot.id] !== undefined
            && metricTypesReady) {
            if (!metricsRequested.includes(robot.id)) {
              metricsRequested.push(robot.id)
              setMetricsRequested(metricsRequested) 
              log.debug(`Loading metrics data for robot: ${robot.id} with: ${appMetricsMulti[robot.id]}`)
              await dispatchRobotMetric(
                robot,
                appMetricsMulti[robot.id] ?? []
              )
            }
          }
        }))
      }
    }
    fetchMetrics()
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricTypesReady, appMetricsMulti]);

  return (appMetricsMultiData.metrics.length === 0 ? <></>
    : <UtilisationChart
        fromDate={fromDate}
        toDate={toDate}
        timePeriod={aggrPeriod}
        metricName={metricName}
        metricsData={appMetricsMultiData.metrics}
        metricKeys={appMetricsMultiData.names}
        metricLegend={metricLegend}
        fullScreen={false}
        setFullScreen={() => {}}
    />);
}
