import Alert from "@mui/material/Alert";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Button } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from '@mui/material';
import { useGetIotComponentsQuery } from '../../reducers/apiSlice';
import { useState } from "react";
import { Component } from "../../model/model";
import { getErrorMessage } from "../../utils";

export default function SoftwareComponents(props: { iotDeviceId: string }) {

  const { iotDeviceId } = props

  const {
    data: components = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetIotComponentsQuery(iotDeviceId, {pollingInterval: 5000})

  const [showAll, setShowAll] = useState(false)

  const filterApp = (components: Component[]): Component[] => {
    return components.filter(part => part.componentName.startsWith('com.spinbotics'))
  }

  let content
  if (isLoading) {
    content = <CircularProgress color="primary" />
  } else if (isSuccess) {
    let componentsFiltered = showAll ? components : filterApp(components)
    componentsFiltered = componentsFiltered.map(x => x)
      .sort((x, y) => x.componentName > y.componentName ? 1 : -1)
    content =
      <>
        <TableContainer sx={{ width: '100%' }}>
          <Table size="small">
            <TableBody>
              {
                componentsFiltered.map((part) => (
                  <TableRow
                    key={part.componentName}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ p: 1, width: 130 }}>{part.componentName
                      .replace('aws.greengrass.', '')
                      .replace('com.spinbotics.', '')
                      .split(".").join(". ")}
                    </TableCell>
                    <TableCell sx={{ p: 1, width: 50 }}>{part.componentVersion}</TableCell>
                    <TableCell sx={{ p: 1, color: 
                          part.lifecycleState === 'FAILED' || part.lifecycleState === 'BROKEN' ||
                          part.lifecycleState === 'ERRORED' ? 'red' : 'black' }}>
                        <Box sx={{ display: "flex", flexWrap: 'wrap', flexDirection: "row" }}>
                        {/* <Box> */}
                      <Box>
                      {part.lifecycleState}
                      </Box>
                      <Box sx={{ ml:2 }}>
                        {' '}
                        {new Date(part.lastReportedTimestamp).toLocaleDateString()}
                        {' '}
                        {new Date(part.lastReportedTimestamp).toLocaleTimeString()}
                      </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {components.length > 0 ?
            <Button
              onClick={() => setShowAll(!showAll)}
              variant="text">
              {showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              {showAll ? 'less' : 'more'}
            </Button> : 'No components'
          }
        </TableContainer>
      </>
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {content}
    </Box>
  )
}