import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { BaseRoute, p } from "../enums";
import { useAppSelector } from "../hooks";
import { Role } from "../model/security";
import { selectCurrentAuth } from "../reducers/authSlice";

interface Props {
  anchorEl: any;
  handleMenuClose: () => void;
}

function UserMenu({ anchorEl, handleMenuClose }: Props) {
  const auth = useAppSelector(selectCurrentAuth);

  const isMenuOpen = Boolean(anchorEl);
  let itemList = [];

  const menuId = "primary-search-account-menu";

  interface MenuItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
  }

  function MenuItemLink(props: MenuItemLinkProps) {
    const { primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(
          function Link(itemProps, ref) {
            return (
              <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
            );
          }
        ),
      [to]
    );

    return (
      <li>
        <MenuItem component={renderLink}>
          {/* {icon ? <ListItemIcon>{icon}</ListItemIcon> : null} */}
          {primary}
        </MenuItem>
      </li>
    );
  }

  if (auth.user) {
    if (auth.role === Role.SYSADMIN) {
      itemList = [
        { to: p(BaseRoute.PROFILE), label: "Profile" },
        { to: p(BaseRoute.SETTINGS), label: "Settings" },
        { to: p(BaseRoute.SELECT_COMPANY), label: "Switch Company" },
        { to: p(BaseRoute.SIGNOUT), label: "Sign out" }
      ];
    }
    else {
      itemList = [
        { to: p(BaseRoute.PROFILE), label: "Profile" },
        { to: p(BaseRoute.SETTINGS), label: "Settings" },
        { to: p(BaseRoute.SIGNOUT), label: "Sign out" }
      ];
    }
  } else {
    itemList = [{ to: p(BaseRoute.SIGNIN), label: "Sign in" }];
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClick={handleMenuClose}
      onClose={handleMenuClose}
    >
      {itemList.map((item) => (
        <MenuItemLink key={item.label} to={item.to} primary={item.label} />
      ))}
    </Menu>
  );
}

export default UserMenu;
