import { useState } from "react";
import log from "loglevel";
import { Auth } from "aws-amplify";
import SignUpCompleted from "./SignUpCompleted";
import FormElement from "./FormElement";
import { Link as RouterLink } from "react-router-dom";
import { Alert, AlertTitle, Link, Avatar, Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PasswordField from "./PasswordField";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { CognitoUserAttrs } from "../../reducers/authSlice";
import { noCompany } from "../../utils";
import { Role } from "../../model/security";

enum SignUpStatus {
  idle,
  waitingForCode,
  completed
}

const SignUp = () => {

  const [status, setStatus] = useState(SignUpStatus.idle);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [company, setUserCompany] = useState("");
  const [phone_number, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [resetCodeSuccess, setResetCodeSuccess] = useState("");

  const phoneRegEx = /^[+][0-9]{0,15}$/;
  const lettersDisabled = /[A-Za-zA]/g
  const phoneValidation = !phoneRegEx.test(phone_number) && phone_number.length > 0 ;

  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const emailValidation = !emailRegEx.test(email) && email.length > 0;

  const signUpValidation = !emailValidation && 
    !phoneValidation && 
    given_name.length > 0 && 
    family_name.length > 0 && 
    company.length > 0 &&
    password.length > 11 &&
    confirmPassword.length > 11 && 
    password === confirmPassword
  
  const signUp = (e: any) => {
    e.preventDefault();
    Auth.signUp({
      username: email,
      password,
      attributes: {
        [CognitoUserAttrs.email] : email,
        [CognitoUserAttrs.given_name] : given_name,
        [CognitoUserAttrs.family_name] : family_name,
        [CognitoUserAttrs.companyProvided] : company,
        [CognitoUserAttrs.phone_number] : phone_number,
        [CognitoUserAttrs.role] : Role.NONE,
        [CognitoUserAttrs.company] : noCompany.companyId,
        [CognitoUserAttrs.preferred_username] : noCompany.companyId
      },
    })
      .then((data) => {
          log.debug("User succesfully registered.");
          setStatus(SignUpStatus.waitingForCode);
          setSignUpError("");
      })
      .catch((err) => {
          log.error(err);
          setSignUpError(err.message);
      });
  };

  const confirmSignUp = (e: any) => {
    e.preventDefault();
    Auth.confirmSignUp(email, code)
      .then((data) => {
        log.debug("Confirmation successfull");
        setStatus(SignUpStatus.completed);
        setSignUpError("");
      })
      .catch((err) => {
        log.error("Confirmation failed with: " + err);
        setSignUpError(err.message);
        setResetCodeSuccess('')
      });
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then(() => {
        setResetCodeSuccess('Code resent successfully')
        setSignUpError("");
      })
      .catch((err) => {
        log.error("Code re-sent failed: " + err);
        setSignUpError(err.message);
      });
  };

  const getPassword = (e: any) => {
    setPassword(e.target.value);
  }
  const getConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  }

  return (
    <Container component='main' maxWidth='xs'>
      {(status === SignUpStatus.idle) && (
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <HowToRegIcon />
          </Avatar>
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            sx={{ textAlign: "center" }}
          >
            Create new account
          </Typography>

          {signUpError && (
            <Alert severity="error" sx={{m:1}}>
              <AlertTitle>{signUpError}</AlertTitle>
            </Alert>
          )}

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label='Email'
              name='email'
              error={!emailValidation ? false : true}
              helperText={!emailValidation ?  null : 'Incorrect email address.'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: <EmailOutlinedIcon/>
              }}
            />
            <Box sx={{display: 'flex'}}>
              <TextField
                margin="normal"
                sx={{mr:1}}
                required
                fullWidth
                label='Name'
                name='name'
                value={given_name}
                onChange={(e) => setGivenName(e.target.value)}
                InputProps={{
                  endAdornment: <BadgeOutlinedIcon/>
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label='Surname'
                name='surname'
                value={family_name}
                onChange={(e) => setFamilyName(e.target.value)}
                InputProps={{
                  endAdornment: <BadgeOutlinedIcon/>
                }}
              />
            </Box>
            <Box sx={{display: 'flex'}}>
              <TextField
                margin="normal"
                sx={{mr:1}}
                required
                fullWidth
                label='Mobile Phone'
                name='phone'
                value= {phone_number}
                error={!phoneValidation ? false : true}
                helperText={!phoneValidation ?  null : 'Phone number must have (+).'}
                onChange={(e) => setPhone(e.target.value.replace(lettersDisabled, ''))}
                InputProps={{
                  endAdornment: <LocalPhoneOutlinedIcon/>
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label='Company'
                name='company'
                value={company}
                onChange={(e) => setUserCompany(e.target.value)}
                InputProps={{
                  endAdornment: <BusinessOutlinedIcon/>
                }}
              />
            </Box>  
            <PasswordField 
              password={password}
              confirmPassword={confirmPassword} 
              setPassword={getPassword}
              setConfirmPassword={getConfirmPassword}
            />  
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={signUp}
              disabled={!signUpValidation}
            >
              Sign up
            </Button>
          </Box>
          <Grid item xs>
            <Link component={RouterLink} to="/signin">
              {"Already have an account?"}
            </Link>
          </Grid>
        </Box>
      )}

      {status === SignUpStatus.waitingForCode && (
        <form>
          {signUpError && (
            <Alert severity="error">
              <AlertTitle>{signUpError}</AlertTitle>
            </Alert>
          )}
          {resetCodeSuccess && (
            <Alert severity="success">
              <AlertTitle>{resetCodeSuccess}</AlertTitle>
            </Alert>
          )}
          <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
              sx={{ textAlign: "center" }}
            >
              Confirm Registration
            </Typography>
          <Typography>
            We have just sent a validation code to your email address: {email}.
          </Typography>
          <FormElement label="Enter the code:" forId="sign-up-code">
            <TextField
              margin="normal"
              fullWidth
              id="sign-up-code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="code"
            />
          </FormElement>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={confirmSignUp}
          >
            Confirm Registration
          </Button>
          <Button type="button" onClick={resendCode}>
            Resend code
          </Button>
        </form>
      )}

      {status === SignUpStatus.completed && (<SignUpCompleted email={email} password={password}/>)}
    </Container>
  );
};

export default SignUp;