import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Alert from "@mui/material/Alert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import log from "loglevel";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getRobotAppMetrics,
  getRobotAppMetricsData,
  selectAppMetricData,
  selectAppMetrics,
  selectLoadingStatus,
} from "../../reducers/metricsSlice";
import {
  endOfThisDay,
  QueryType,
  startOfThisDay,
} from "../../utils";
import { aggregationPeriods, getMetricDefinition } from "./Utilisation";
import UtilisationChart from "./UtilisationChart";
import { RequestStatus } from "../../reducers/enums";
import { selectAppMetricsReq } from "../../reducers/uiSlice";
import { useGetCompanyRobotQuery } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import { useMediaQuery, useTheme } from "@mui/material";

export default function DeviceUtilisation() {
  const { id } = useParams();
  if (id) {
    return <DeviceUtilisationPrivate robotId={id} />
  } else { return <></>}
}

function DeviceUtilisationPrivate({ robotId }: { robotId: string }) {

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  // Default values for UI selectors
  let defaultEndTime = endOfThisDay(new Date());
  let defaultStartTime = new Date();
  defaultStartTime.setDate(defaultEndTime.getDate());
  defaultStartTime = startOfThisDay(defaultStartTime);
  const defaultAggrPeriod = aggregationPeriods[2];
  let defaultMetricName: any = null
  let defaultMetricApplications: string[] = []
  let defaultMetricAppList: string[] = []

  // Selectors for the data in the store
  const companyId = useAppSelector(selectCompanyId)
  const { data: robot } = useGetCompanyRobotQuery({companyId, robotId})
  let loading = useAppSelector(selectLoadingStatus)
  const savedQuery = useAppSelector( selectAppMetricsReq(robotId));
  const metricsData = useAppSelector(selectAppMetricData(robotId, QueryType.User))
  const metricsTypes = useAppSelector(selectAppMetrics(robotId))

   // State
  const [startTimePicker, setStartTimePicker] = useState<Date | null>(defaultStartTime)
  const [endTimePicker, setEndTimePicker] = useState<Date | null>(defaultEndTime)
  const [startTime, setStartTime] = useState<Date>(defaultStartTime)
  const [endTime, setEndTime] = useState<Date>(defaultEndTime)
  const [aggregation, setAggregation] = useState<number | null>(defaultAggrPeriod.value)
  const [metricName, setMetricName] = useState<string | null>(defaultMetricName)
  const [metricApplications, setMetricApplications] = useState<string[]>(defaultMetricApplications)
  const [metricAppOptions, setMetricAppOptions] = useState<string[]>(defaultMetricAppList)
  const [fullscreen, setFullScreen] = useState(false)
  const [initParamsReady, setInitParamsReady] = useState(false)
  
  const dispatch = useAppDispatch();

  // Load metric types for the robot
  useEffect(() => {
    async function fetchMetricTypes() {
      if (robot !== undefined && robot.iotDevice !== null) {
        log.debug('DeviceUtilisation(): Loading robot app metric types.')
        await dispatch(
          // Fetch initial metrics definitions applicable for this robot
          getRobotAppMetrics({
            robotId: robotId,
            deviceId: robot.iotDevice
          })
        )
      }
    }
    fetchMetricTypes()
  }, [robot, robot?.iotDevice, robotId]);
 
  // If there is already a metric in the store use its params to set UI selectors.
  // This is useful when you are returning to this view.
  useEffect(() => {
    if (savedQuery !== undefined && metricsTypes !== undefined && Object.keys(metricsTypes).length !== 0 
        && initParamsReady === false) {
      log.debug(`DeviceUtilisation(): Metrics types ${JSON.stringify(metricsTypes)}`)
      log.debug(`DeviceUtilisation(): Restoring stored robot app metrics query params for ${JSON.stringify(savedQuery)}`)
      setMetricName(savedQuery.metricName)
      setMetricApplications(savedQuery.applicationIds)
      setMetricAppOptions((metricsTypes === undefined) ? [] : metricsTypes[savedQuery.metricName].applicationIds)
      setStartTimePicker(new Date(savedQuery.startTime))
      setEndTimePicker(new Date(savedQuery.endTime))
      setStartTime(new Date(savedQuery.startTime))
      setEndTime(new Date(savedQuery.endTime))
      const period = aggregationPeriods.find(
        period => period.value === savedQuery.aggrPeriod
      )
      if (period) setAggregation(period.value)
      setInitParamsReady(true)
    }
  }, [savedQuery, metricsTypes, initParamsReady]);

  useEffect(() => {
    async function fetchData() {
      log.debug('DeviceUtilisation(): Loading robot app metric data for restored params.')
      if (initParamsReady) {
        await handleLoad()
      }
    }
    fetchData()
  }, [initParamsReady]);

  // When metric configuration is loaded from the state (metricName) the metricAppList 
  // populate the dropdown.
  useEffect(() => {
    if (metricName !== null && metricAppOptions.length === 0) {
      setMetricAppOptions((metricsTypes === undefined) ? [] : metricsTypes[metricName].applicationIds)
    }
  }, [metricName, metricsTypes, metricAppOptions]);

  const setDefaults = async () => {
    setStartTime(defaultStartTime)
    setEndTime(defaultEndTime)
    setStartTimePicker(defaultStartTime)
    setEndTimePicker(defaultEndTime)
    setAggregation(defaultAggrPeriod.value)
  } 

  const handleLoad = async () => {
    if (
      startTimePicker !== null &&
      endTimePicker !== null &&
      aggregation !== null &&
      metricName !== null
    ) {
      await dispatch(
        getRobotAppMetricsData({
          robotId: robotId,
          deviceId: robot?.iotDevice ?? "no-device-id",
          metricName: metricName,
          applicationIds: metricApplications,
          startTime: startTimePicker,
          endTime: endTimePicker,
          aggrPeriod: aggregation,
          type: QueryType.User,
        })
      );
      // Copy the time from the pickers to the time for the Chart to only render on handleLoad()
      setStartTime(startTimePicker);
      setEndTime(endTimePicker);
    } else {
      log.error("Not all query parameters provided.");
    }
  };

  let metricsOptions = Object.keys(metricsTypes ?? {})
  const metricDef = getMetricDefinition(metricName)

  const alert = loading.status === RequestStatus.failed ?
    <Alert severity="error">{loading.error}</Alert> : ""
   
  return (
    <Paper 
      sx={{ p: 2, display: "flex", flexDirection: "column"}}>
      {alert}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {metricDef.name}        
      </Typography>
      <Box
        sx={{
          display: "flex",
          p: 1,
          m: 1,
          justifyContent: "center",
          flexWrap: 'wrap'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              m: 0,
              justifyContent: 'center'
            }}>
            <DatePicker
              label="From"
              renderInput={(params) => <TextField sx={{width: 150, m: 1 }} {...params} />}
              value={startTimePicker}
              onChange={(newValue) => {
                setStartTimePicker(newValue);
              }}
            />
            <DatePicker
              label="To"
              renderInput={(params) => <TextField sx={{width: 150, m: 1 }} {...params} />}
              value={endTimePicker}
              onChange={(newValue) => {
                setEndTimePicker(newValue);
              }}
            />
            <FormControl sx={{ width: 100, m: 1 }}>
              <InputLabel id="aggregation-label">Aggregation</InputLabel>
              <Select
                label="Aggregation"
                labelId="aggregation-label"
                value={aggregation}
                onChange={(newValue) => {
                  if (newValue.target.value != null)
                    setAggregation(+newValue.target.value);
                }}
              >
                {aggregationPeriods.map(period => <MenuItem key={period.value} value={period.value}>{period.label}</MenuItem>)}
              </Select>
            </FormControl>
            <Autocomplete
              disablePortal
              id="combo-box-metric-name"
              sx={smUp ? { minWidth: 250, m: 1 } : { minWidth: 200, m: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Metric"
                  className="dateInput"
                />
              )}
              value={metricName}
              options={metricsOptions}
              onChange={(event: any, newValue: any) => {
                setMetricName(newValue);
                setMetricAppOptions( (metricsTypes === undefined) ? [] : metricsTypes[newValue].applicationIds )
              }}
            />
            <Autocomplete
              disablePortal
              multiple
              id="combo-box-application-name"
              sx={{ minWidth: 250, width: 'auto', m: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Applications"
                  className="dateInput"
                />
              )}
              value={metricApplications}
              options={metricAppOptions}
              onChange={(event: any, newValue: any) => {
                setMetricApplications(newValue);
              }}
            />
          </Box>
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row'
            }}>
            <LoadingButton sx={{ width: "150px", m: 1 }} 
              loading={loading.status === RequestStatus.loading}
              startIcon={<RefreshIcon />}
              loadingPosition="start" variant="contained" 
              onClick={handleLoad}>
              Load
            </LoadingButton>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box>
        <Button startIcon={<FullscreenIcon />} onClick={() => setFullScreen(true)}>Full Screen</Button>
        <Button startIcon={<RestartAltIcon />} onClick={setDefaults}>Defaults</Button>
      </Box>
      <UtilisationChart
        fromDate={startTime}
        toDate={endTime}
        timePeriod={aggregation ?? 60}
        metricName={metricName}
        metricsData={metricsData}
        metricKeys={['value']}
        fullScreen={fullscreen}
        setFullScreen={setFullScreen}
      ></UtilisationChart>
    </Paper>
  );
}