import { TextField } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";

const PasswordField = (props: {password: string, setPassword: any, confirmPassword : string, setConfirmPassword: any}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checks, setChecks] = useState({
    upperCasePassword: false,
    lowerCasePassword: false,
    numberPassword: false,
    passwordLength: false,
  })

  const togglePassword = () => {
    setShowPassword(!showPassword)
  };
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const matchPasswords = props.password === props.confirmPassword && props.confirmPassword.length > 0
        
  const validAll = !checks.upperCasePassword || !checks.lowerCasePassword || !checks.numberPassword || !checks.passwordLength;
        
  const passwordHandleOnKeyUp = () => {
        
    const upperCaseRegExp   = /(?=.*?[A-Z])/;
    const lowerCaseRegExp   = /(?=.*?[a-z])/;
    const numberRegExp      = /(?=.*?[0-9])/;
    // const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{12,}/;
        
    const upperCasePassword =   upperCaseRegExp.test(props.password);
    const lowerCasePassword =   lowerCaseRegExp.test(props.password);
    const numberPassword =      numberRegExp.test(props.password);
    // const specialCharPassword = specialCharRegExp.test(password);
    const passwordLength =   minLengthRegExp.test(props.password);
      setChecks({
        passwordLength,
        upperCasePassword,
        lowerCasePassword,
        numberPassword
      })
  }
    return (
      <>
        <TextField
          margin="normal"
          required
          autoComplete="Password"
          fullWidth
          label='Password'
          type={showPassword ? 'text' : 'password'}
          value={props.password}
          onChange={(e) => props.setPassword(e)}
          onKeyUp={passwordHandleOnKeyUp}
          helperText={
            props.password.length > 0 
              ? 
                !checks.upperCasePassword ? 'Password must contains at least 1 uppercase letter' : null ||
                !checks.lowerCasePassword ? 'Password must contains at least 1 lowercase letter ' : null ||
                !checks.numberPassword ? 'Password must contains at least 1 number' : null ||
                !checks.passwordLength ? 'Password minimum length 12 characters' : null
              : 
                null
          }
          error={props.password.length > 0 && validAll ? true : false}
          InputProps={{
              endAdornment:
              showPassword  ? (
                  <VisibilityOffOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={togglePassword}
                  />
              ) : (
                  <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={togglePassword}
                  />
              ),
          }}
        />
        <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm password"
              name="confirmPassword"
              autoComplete="confirmPassword"
              helperText={
                props.confirmPassword.length > 0  && !matchPasswords ? 'Passwords are not the same' : null}
              value={props.confirmPassword}
              error={props.confirmPassword.length > 0 && !matchPasswords ? true : false}
              onChange={(e) => props.setConfirmPassword(e)}
              InputProps={{
                endAdornment:
                  showConfirmPassword  ? (
                    <VisibilityOffOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={toggleConfirmPassword}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={toggleConfirmPassword}
                    />
                  )
              }}
            />
      </>
    )
  }                  

export default PasswordField;