import { ComponentDefinition } from "./model";

const sbkModuleCodes: string[] = ['cb', 'cu', 'bc', 'du', 'lc', 'tc']

export enum ComponentType {
  SBK_MODULE_CB = "sbk-module-cb",
  SBK_MODULE_CU = "sbk-module-cu",
  SBK_MODULE_BC = "sbk-module-bc",
  SBK_MODULE_DU = "sbk-module-du",
  SBK_MODULE_LC = "sbk-module-lc",
  SBK_MODULE_TC = "sbk-module-tc",
  SBK_DEVICE = "sbk-device",
  SBK_PROGRAM = "sbk-program",
  SBK_APP = "sbk-app",
  UNKNOWN = "unknown"
}

export enum SeverityLevel {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  DEBUG = "DEBUG",
}

interface ComponentDefinitionDict {
  [index: string]: ComponentDefinition;
}

export const componentsDefinition: ComponentDefinitionDict = {
  [ComponentType.SBK_MODULE_DU]: {
    type: ComponentType.SBK_MODULE_DU,
    name: "Drive Unit",
    code: 'du'
  },
  [ComponentType.SBK_MODULE_CB]: {
    type: ComponentType.SBK_MODULE_CB,
    name: "Control Box",
    code: 'cb'
  },
  [ComponentType.SBK_MODULE_CU]: {
    type: ComponentType.SBK_MODULE_CU,
    name: "Control Unit",
    code: 'cu'
  },
  [ComponentType.SBK_DEVICE]: {
    type: ComponentType.SBK_DEVICE,
    name: "SpinBOT Device",
    code: 'device'
  },
  [ComponentType.SBK_MODULE_BC]: {
    type: ComponentType.SBK_MODULE_BC,
    name: "Base Connector",
    code: 'bc'
  },
  [ComponentType.SBK_MODULE_LC]: {
    type: ComponentType.SBK_MODULE_LC,
    name: "Link Connector",
    code: 'lc'
  },
  [ComponentType.SBK_MODULE_TC]: {
    type: ComponentType.SBK_MODULE_TC,
    name: "Tool Connector",
    code: 'tc'
  },
  [ComponentType.SBK_PROGRAM]: {
    type: ComponentType.SBK_PROGRAM,
    name: "Program",
    code: 'program'
  },
  [ComponentType.SBK_APP]: {
    type: ComponentType.SBK_APP,
    name: "App",
    code: 'app'  
  },
};

export interface StateDefinition {
  name: string
  background: string,
  color: string
  active: boolean
}

interface StateDict {
  [index: string]: StateDefinition;
}

// This is general state of a device (not a runtime-state)
// Run-time states maps to those general states.
const robotStates: StateDict = {
  "disassembled": {
    name: "Disassembled",
    active: false,
    background: "lightgrey",
    color: "black"
  },
  "passive": {
    name: "Passive",
    active: true,
    background: "lightgrey",
    color: "black"
  },
  "operational": { // Powered, Unpowered
    name: "Operational",
    active: true,
    background: "#b6f7b0",
    color: "##085c01"
  },
  "error": {
    name: "In Error",
    active: true,
    background: "#ffdbdb",
    color: "#fa0202"
  }
} 

interface RuntimeStateDict {
  [index: string]: {
    parent: StateDefinition
  }
}

// States as reported by the control unit.
const robotRuntimeStates: RuntimeStateDict = {
  "disassembled": {
    parent: robotStates.disassembled
  },
  "assembled": {
    parent: robotStates.passive
  },
  "stopped": {
    parent: robotStates.passive
  },
  "running": {
    parent: robotStates.operational
  },
  "executing": {
    parent: robotStates.operational
  },
  "piloting": {
    parent: robotStates.operational
  },
  "jogging": {
    parent: robotStates.operational
  },
  "deleted": {
    parent: robotStates.disassembled
  },
  "error": {
    parent: robotStates.error
  },
  "unknown": {
    parent: robotStates.disassembled
  }
}

export const getState = (stateName: string): StateDefinition => {
  var state = robotRuntimeStates[stateName]
  if (state === undefined) {
      state = robotRuntimeStates.unknown
  }
  return state.parent
}

const iotDeviceStates: StateDict = {
  "UNKNOWN": {
    name: "Unknown",
    active: false,
    background: "lightgrey",
    color: "black"
  },
  "HEALTHY": {
    name: "Healthy",
    active: true,
    background: "#b6f7b0",
    color: "##085c01"
  },
  "UNHEALTHY": {
    name: "Unhealthy",
    active: true,
    background: "#ffdbdb",
    color: "#fa0202"
  }
}

export const getIotDeviceState = (stateName: string): StateDefinition => {
  var state = iotDeviceStates[stateName]
  if (state === undefined) {
      state = {
        name: "Unsupported: " + stateName,
        active: false,
        background: "lightgrey",
        color: "darkgrey"
      }
  }
  return state
}

export const applicationStates: StateDict = {
  "created": {
    name: "Created",
    active: false,
    background: "lightgrey",
    color: "black"
  },
  "installed": {
    name: "Installed",
    active: true,
    background: "#b6f7b0",
    color: "##085c01"
  },
  "unknown": {
    name: "Unknown",
    active: true,
    background: "#ffdbdb",
    color: "#fa0202"
  }
}

 export const getApplicationState = (stateName: string): StateDefinition => {
  var state = applicationStates[stateName]
  if (state === undefined) {
      state = applicationStates.unknown
  }
  return state
}


export const getPartTypeCode = (partId: string): string => {
  return partId.slice(0,2).toLowerCase()
}

export const getComponentDefinition = (mcVersion: string): ComponentDefinition | undefined => {
  const typeCode = getPartTypeCode(mcVersion)
  const found = sbkModuleCodes.find( x => x === typeCode)
  return found ? componentsDefinition["sbk-module-" + typeCode] : undefined
}

