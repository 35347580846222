import { AuthStateSlice } from "../reducers/authSlice"

export const enum Role {
  NONE = "none",
  USER = "user",
  ADMIN = "admin",
  INVENTORY_ADMIN = "inventory_admin",
  SYSADMIN = "sysadmin"
}

export const Roles = [Role.NONE, Role.USER, Role.INVENTORY_ADMIN, Role.ADMIN, Role.SYSADMIN]

export enum Permission {
  MANAGE_USERS = 'manage_users',
  INSTALL_UNINSTALL_APP = 'install_uninstall_app',
  UPGRADE_APP = 'upgrade_app',
  READ_LOGS = 'read_logs',
  UPDATE_INVENTORY = 'update_inventory',
  READ_AUDIT = 'read_audit',
  READ_RELEASES = 'read_releases',
  UPDATE_DEVICE_CONFIGURATION = 'update_device_configuration',
  UPDATE_APP_CONFIGURATION = 'update_app_configuration',
}

export const rolePermissions = {
  [Role.SYSADMIN]: [
    Permission.READ_LOGS,
    Permission.INSTALL_UNINSTALL_APP,
    Permission.UPGRADE_APP,
    Permission.UPDATE_INVENTORY,
    Permission.MANAGE_USERS,
    Permission.READ_AUDIT,
    Permission.READ_RELEASES,
    Permission.UPDATE_DEVICE_CONFIGURATION,
    Permission.UPDATE_APP_CONFIGURATION
  ],
  [Role.ADMIN]: [
    Permission.UPGRADE_APP,
    Permission.READ_AUDIT,
    Permission.UPDATE_DEVICE_CONFIGURATION,
    Permission.UPDATE_APP_CONFIGURATION
  ],
  [Role.INVENTORY_ADMIN]: [Permission.UPDATE_INVENTORY],
  [Role.USER]: [],
  [Role.NONE]: []
}

export const hasPermisssion = (auth: AuthStateSlice, permission: Permission): boolean => {
  return auth.permissions.includes(permission)
}