import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, FormControl, InputLabel, MenuItem, 
  Paper, TextField, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import log from "loglevel";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Audit, getAuditLogs, selectAuditLogs,
  selectAuditQueryParams, selectLoadingStatus } from "../../reducers/auditLogsSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import { RequestStatus, ResourceType } from "../../reducers/enums";
import { endOfThisDay, getEnumKeyByEnumValue, startOfThisDay } from "../../utils";
import AuditLogsTable from './AuditLogsTable';

export const AuditLogs = () => {

  const companyId = useAppSelector(selectCompanyId)
  const dispatch = useAppDispatch();

  // Default values for UI selectors
  let defaultToDate = endOfThisDay(new Date());
  let defaultFromDate = new Date();
  let defaultResourceType = ResourceType.ANY
  let defaultSearchTerm = ''
  defaultFromDate.setDate(defaultToDate.getDate() - 1);
  defaultFromDate = startOfThisDay(defaultFromDate);

  const queryParams = useAppSelector(
    selectAuditQueryParams(companyId)
  );

  if (queryParams) {
    if (queryParams.startTime) {
      defaultFromDate = new Date(queryParams.startTime);
    }
    if (queryParams.endTime) {
      defaultToDate = new Date(queryParams.endTime);
    }
    if (queryParams.resourceType) {
      defaultResourceType = queryParams.resourceType
    }
    if (queryParams.searchTerm) {
      defaultSearchTerm = queryParams.searchTerm
    }
  }

  const [resourceType, setResourceType] = useState<ResourceType>(defaultResourceType);
  const [fromDate, setFromDate] = useState<Date | null>(defaultFromDate);
  const [toDate, setToDate] = useState<Date | null>(defaultToDate);
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm)

  const dateValidation = fromDate !== null && toDate !== null && fromDate.getTime() < toDate.getTime();

  let loading = useAppSelector(selectLoadingStatus)

  const handleLoad = () => {
    if (fromDate !== null && toDate !== null) {
      // fixme (vca): remove getEnumKeyByEnumValue
      let resourceTypeEnum = getEnumKeyByEnumValue(ResourceType, resourceType);
      resourceTypeEnum = resourceTypeEnum == null ? ResourceType.ANY : resourceTypeEnum;
      dispatch(
        getAuditLogs({
          companyId: companyId,
          startTime: fromDate,
          resourceType: resourceTypeEnum as ResourceType,
          endTime: toDate,
          searchTerm: searchTerm
        })
      );
    } else {
      log.error("Not all query parameters provided.");
    }
  };

  let auditLogs: Audit[] = useAppSelector(
    selectAuditLogs(companyId)
  );

  const handleChange = (event: SelectChangeEvent) => {
    setResourceType(event.target.value as ResourceType);
  };

  const alert = loading.status === RequestStatus.failed ?
    <Alert severity="error">{loading.error}</Alert> : ""

  return (
    <Paper
      sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      {alert}
      {!dateValidation && <Alert severity='error'>'From' time must be before 'To' time</Alert>}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Audit Logs
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField sx={{ maxWidth: 200, m: 1 }} {...props} />}
              label="From"
              value={fromDate}
              ampm={false}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
            />
            <DateTimePicker
              renderInput={(props) => <TextField sx={{ maxWidth: 200, m: 1 }} {...props} />}
              label="To"
              value={toDate}
              ampm={false}
              onChange={setToDate}
            />
          </LocalizationProvider>
        </Box>
        <FormControl sx={{ width: 150, m: 1 }}>
          <InputLabel id="resourceType-label">Resource Type</InputLabel>
          <Select
            label="Resource Type"
            labelId="resourceType-label"
            value={resourceType}
            onChange={handleChange}
          >
            {Object.keys(ResourceType).map((label) => (
              <MenuItem value={label} key={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ width: 150 }}
          id="standard-search"
          label="Search"
          type="search"
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <LoadingButton sx={{ width: 150, m: 1 }}
            loading={loading.status === RequestStatus.loading}
            startIcon={<RefreshIcon />}
            loadingPosition="start" variant="contained"
            onClick={handleLoad}
            disabled={!dateValidation}>
            Load
          </LoadingButton>
        </Box>
      </Box>
      <Box>
        <AuditLogsTable auditLogs={auditLogs} searchTerm={searchTerm} />
      </Box>
    </Paper>
  );
}