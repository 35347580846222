export const defaultAppTitle = "SpinBOT Cloud"

export enum BaseRoute {
  HOME = "",
  SIGNUP = "signup",
  SIGNIN = "signin",
  SIGNOUT = "signout",
  PROFILE = "profile",
  SETTINGS = "settings",
  RESET = "reset",
  INTRO = "intro",
  SELECT_COMPANY = "select_company",
  DASHBOARD = "dashboard",
  APPLICATIONS = "applications",
  DEVICES = "devices",
  CONTROL_UNITS = 'control-units',
  MODULES = "modules",
  MODULES_EDIT = "modules-edit",
  USERS = "users",
  USERS_EDIT = "users-edit",
  STORE = "store",
  RELEASES = 'releases',
  AUDIT = 'audit',
  END_OF_UNIVERSE = "end-of-universe"
}

export enum SubRoute {
  UTILISATION = "utilisation",
  SOFTWARE = "software",
  CONFIGURATION = "configuration",
  BASICS = "basics",
  DEPLOYMENT = "deployment",
  LOGS = "logs",
  CONFIGURATOR = "configurator",
  CONFIG_SERVER = "config-server",
  CONFIG_LOGGING = "config-logging"
}

export const p = (pathName: string) => {
  return "/"+ pathName
}