import EditIcon from '@mui/icons-material/Edit'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, TextField, Autocomplete } from "@mui/material"
import { useEffect, useState } from "react"
import { Roles } from '../../model/security'
import { Company, useGetCompaniesQuery, useUpdateUserMutation } from "../../reducers/apiSlice"
import { User } from "../../reducers/authSlice"
import { noCompany } from '../../utils'
import DialogTitleBox from '../DialogTitleBox'
import { ErrorSnackbar, SuccessSnackbar } from "../SnackbarMsg"

const companyFor = (companies: Company[], code: string): Company | undefined => {
  return companies.find(x => x.companyId === code)
}

export default function EditUser(props: { user: User }) {
  const { user } = props;

  const [role, setRole] = useState(user.role);
  const [company, setCompany] = useState<Company | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const [updateUser, { isSuccess, isError, error }] = useUpdateUserMutation();

  const { data: companies = [] } = useGetCompaniesQuery()
  const companiesDb = companies.map((company) => company);
  let allCompanies = companiesDb
  allCompanies.push(noCompany)

  const isChange = role !== user.role || company !== companyFor(allCompanies, user.company)

  useEffect(() => {
    if (allCompanies && company === undefined) {
      const company = companyFor(allCompanies, user.company)
      company ? setCompany(company) : console.log(`Warn: company for ${user.company} not found`)
    }
  }, [allCompanies, company, user.company])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRole(user.role)
    const company = companyFor(allCompanies, user.company)
    company ? setCompany(company) : console.log(`Company for ${user.company} not found`)
  };

  const saveHandler = async () => {
    if (company) {
      await updateUser({
        userId: user.userId,
        user: {
          company: company.companyId,
          role: role
        }
      })
    }
    setOpen(false)
  }

  let content;

  if (isSuccess) {
    content = <SuccessSnackbar message='Updated.' />
  } else if (isError) {
    content = <ErrorSnackbar error={error} />
  }

  return (
    <>
      {content}
      <IconButton size='small' color='inherit' onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitleBox onClose={handleClose}>Edit</DialogTitleBox>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* <DialogContentText sx={{ pb: 2 }}>
            Update user: <b>{user.familyName} {user.givenName}</b>?
          </DialogContentText> */}
          <TextField
            margin="dense"
            label='Name'
            value={user.givenName}
            disabled
          />
          <TextField
            margin="dense"
            label='Surname'
            value={user.familyName}
            disabled
          />
          <TextField
            margin="dense"
            label='Email'
            value={user.email}
            disabled
          />
          <TextField
            margin="dense"
            label='Provided Company'
            value={user.companyProvided}
            disabled
          />
          <Autocomplete
            value={company}
            fullWidth
            onChange={(event, value: any) => setCompany(value)}
            options={allCompanies}
            sx={{ mt: 2 }}
            getOptionLabel={(option: Company) => option.name}
            renderInput={(params) => <TextField {...params} fullWidth label="Company" />}
          />
          <Autocomplete
            fullWidth
            options={Roles}
            value={role}
            onChange={(event, value: any) => setRole(value)}
            sx={{ mt: 2 }}
            renderInput={(params) => <TextField {...params} fullWidth label="Role" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={saveHandler}
            disabled={!isChange || role === null || company?.name === undefined}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}