import AppsIcon from '@mui/icons-material/Apps';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NotesIcon from '@mui/icons-material/Notes';
import WrapTextIcon from '@mui/icons-material/WrapText';
import {
  Alert,
  Box, CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  useGetDeviceDeploymentTemplateQuery,
  useGetDeviceDeploymentTemplateVariantsQuery
} from '../reducers/apiSlice';
import { setVariantsOpenView } from '../reducers/uiSlice';
import { getErrorMessage } from '../utils';
import { RnNoteItem } from './iot/DialogUpgrade';

const ReleaseInfoRow = (props: { variant: string }) => {
  const { variant } = props;

  const [openVersion, setOpenVersion] = useState<string | null>(null);

  const {
    data: releases = [],
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetDeviceDeploymentTemplateQuery({ type: 'primary', variant: variant })

  const onVersionClicked = (version: string) => {
    setOpenVersion(prevOpen => {
      if (prevOpen === version) {
        return null;
      }
      return version;
    });
  };

  // fixme (vca): on sorting it crashes
  // releases.sort((a: DeploymentTemplate, b: DeploymentTemplate) =>
  //   a.version > b.version ? -1 : 1)

  let content
  if (isLoading) {
    content = <><CircularProgress color="primary" /></>
  } else if (isSuccess) {
    if (releases.length === 0) {
      content = <Alert severity="info">No software releases found.</Alert>
    } else {
      content =
        <>
          {
            releases.map((release) => (
              <List key={release.version} sx={{ mx: 2 }} component="div" disablePadding>
                <ListItemButton onClick={() => onVersionClicked(release.version)}>
                  <ListItemIcon>
                    <WrapTextIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Version: ${release.version}, Type: ${release.type}`} />
                  {openVersion === release.version ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVersion === release.version} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Box sx={{ ml: 1 }}>
                      <ListItemButton>
                        <ListItemIcon>
                          <NotesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Release Notes" />
                      </ListItemButton>
                      <Box sx={{ ml: 5 }}>
                        <RnNoteItem label='Date' items={[release.notes.date]} />
                        <RnNoteItem label='Added' items={release.notes.added} />
                        <RnNoteItem label='Fixed' items={release.notes.fixed} />
                        <RnNoteItem label='Removed' items={release.notes.removed} />
                        <RnNoteItem label='Changed' items={release.notes.changed} />
                      </Box>
                    </Box>
                    <Box sx={{ ml: 1 }}>
                      <ListItemButton >
                        <ListItemIcon>
                          <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Components" />
                      </ListItemButton>
                      <Box sx={{ ml: 5 }}>
                        {Object.keys(release.components).map((item) => {
                          return (
                            <Box key={item} sx={{ display: 'flex' }}>
                              <Box sx={{ width: '280px' }}>
                                <ListItem key={item} sx={{ p: 0, m: 0 }}>{item.replace('com.', '').replace('aws.', '').replaceAll('.', '.')}</ListItem>
                              </Box>
                              <Box>
                                <ListItem key={item} sx={{ p: 0, m: 0 }}>{release.components[item].componentVersion}</ListItem>
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                  </List>
                </Collapse>
              </List>))
          }
        </>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
    >
      {content}
    </List>
  )
}

export const Releases = () => {
  
  const dispatch = useAppDispatch();
  const openViewVariants = useAppSelector((state) => state.ui.releases.openViewVariant);

  const [openVariant, setOpenVariant] = useState<string | null>(openViewVariants);

  const {
    data: variants = [],
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetDeviceDeploymentTemplateVariantsQuery();

  const onVariantClicked = (variant: string) => {
    setOpenVariant(prevOpen => {
      if (prevOpen === variant) {
        return null;
      }
      return variant;
    });
    dispatch(setVariantsOpenView(openVariant === variant ? 'none' : variant));
  };

  let content
  if (isLoading) {
    content = <><CircularProgress color="primary"/></>
  } else if (isSuccess) {
    if (Object.keys(variants).length === 0) {
      content = <Alert severity="info">No releases found.</Alert>
    } else {
      content =
        <Box sx={{ bgcolor: 'white' }}>
          {
            variants.map((variant) => (
              <Box key={variant} sx={{ mt: 1, pt: 1 }}>
                <ListItemButton onClick={() => onVariantClicked(variant)}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Variant: ${variant}`} />
                  {openVariant === variant ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVariant === variant} timeout="auto" unmountOnExit>
                  <ReleaseInfoRow variant={variant} />
                </Collapse>
              </Box>
            ))
          }
        </Box>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      {content}
    </>
  )
}
