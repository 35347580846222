import log from "loglevel"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogsClient, { LogLine } from "../services/LogsClient";
import { AppState } from "../store";
import { QueryType } from "../utils";
import { selectIdToken, signInUser } from "./authSlice";
import { RequestStatus } from "./enums";

export interface GreenGrassComponentType {
  label: string
  value: string
}

export const greenGrassComponents = [
  { label: "Spinbot", value: "com.spinbotics.spinbot-humble" },
  { label: "IoT Bridge", value: "com.spinbotics.ros2-iot-bridge-humble" },
  { label: "Fw Updater", value: "com.spinbotics.fw-updater-humble" }
];

export interface LogParserType {
  label: string
  description: string
  code: LogParser
}

export enum LogParser {
  APPLICATION = "application",
  CONTAINER = "container"
}

export const logParsers: LogParserType[] = [
  {
    label: "Application",
    code: LogParser.APPLICATION,
    description: "Application logs"
  },
  {
    label: "Container",
    code: LogParser.CONTAINER,
    description: "Container logs"
  }
]

export interface LogLineTable extends LogLine {
  id: number;
}

export interface DeviceLogs {
  [index: string]: {
    query: LogsQuerySave,
    data: LogLine[]
  }
}

export interface LogsStateSliceMap {
  [index: string]: DeviceLogs;
}

export interface LogsStateSlice {
  logs: LogsStateSliceMap
  status: RequestStatus
  error: string
}

export type LogsQuery = {
  iotDevice: string,
  component: GreenGrassComponentType,
  startTime: Date;
  endTime: Date;
  parser: LogParser,
  filter: string
};

export interface LogsQueryReq extends LogsQuery {
  type: QueryType
}

export type LogsQuerySave = {
  startTime: number;
  endTime: number;
  parser: LogParser,
  component: GreenGrassComponentType,
  filter: string
};
const initialState: LogsStateSlice = { logs: {}, status: RequestStatus.idle, error: "" }

const getFetchFn = (params: LogsQueryReq, thunkAPI: any): any => {
  return () => { 
    const token = selectIdToken(thunkAPI.getState() as AppState)
    const client = LogsClient.getInstance(token);
    return client.getLogs(params);
  }
}

export const getDeviceLogs = createAsyncThunk(
  "getDeviceLogs",
  async (
    params: LogsQueryReq,
    thunkAPI
  ) => {
    var logs
    try {
      logs = await getFetchFn(params, thunkAPI)()
    } catch (err) {
      if (err instanceof Error) {
        if (err.name === 'NotAuthorizedException') {
          // CognitoIdentityServiceException class
          await thunkAPI.dispatch(signInUser())
          logs = await getFetchFn(params, thunkAPI)()
        } else {
          return thunkAPI.rejectWithValue(err.message)
        }
      } else {
        return thunkAPI.rejectWithValue(JSON.stringify(err))
      }
    }

    return {
      query: {
        ...params,
        startTime: +params.startTime,
        endTime: +params.endTime
      },
      logs: logs,
    };
  }
);

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDeviceLogs.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getDeviceLogs.fulfilled, (state, action) => {
        if (!state.logs[action.payload.query.iotDevice]) {
          state.logs[action.payload.query.iotDevice] = {};
        }
        state.logs[action.payload.query.iotDevice][action.payload.query.type] = {
          data: action.payload.logs,
          query: {
            startTime: action.payload.query.startTime,
            endTime: action.payload.query.endTime,
            filter: action.payload.query.filter,
            parser: action.payload.query.parser,
            component: action.payload.query.component
          }
        }
        state.status = RequestStatus.succeeded
      })
      .addCase(getDeviceLogs.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.payload as string
        log.error(action.payload);
      });
  },
});

export const selectLogsQueryParams =
  (iotDevice: string, type: QueryType) =>
    (state: AppState): LogsQuerySave => {
      if (state.logs.logs?.[iotDevice]?.[type]) {
        return state.logs.logs[iotDevice][type].query;
      } else 
        return {
          startTime: 0,
          endTime: 0,
          component: greenGrassComponents[0],
          parser: LogParser.CONTAINER,
          filter: ''
        };
    };

export const selectLogs =
  (iotDevice: string, type: QueryType) => (state: AppState): LogLineTable[] => {
    if (state.logs.logs?.[iotDevice]?.[type]) {
      let i = 0;
      return state.logs.logs[iotDevice][type].data.slice()
        .sort((a: LogLine, b: LogLine) => (a.time > b.time ? -1 : 1))
        .map((item: LogLine) => ({
          // Add unique identified for each line
          ...item,
          id: i++,
          time: item.time,
          level: item.level,
          // Add space to allow for word wrap in PATHs variables a mis semicolon separated output
          message: item.message.replaceAll(":", ": ").replaceAll(";", "; ").replaceAll(",", ", ")
        }));
    } else return [];
  };

export const selectLoadingStatus =
  (state: AppState): { status: RequestStatus, error: string } => {
    return { status: state.logs.status, error: state.logs.error }
  }

export default logsSlice.reducer;
