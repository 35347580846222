import { useState } from 'react';
import {
  IconButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Button
} from '@mui/material';
import { useAppSelector } from '../../hooks';
import { useDeletePartMutation } from '../../reducers/apiSlice';
import { selectCompanyId } from '../../reducers/authSlice';
import { Part } from '../../model/model';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorSnackbar, SuccessSnackbar } from '../SnackbarMsg';
import DialogTitleBox from '../DialogTitleBox';

export default function DeletePart(props: { part: Part }) {

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [deletePart, { isSuccess, isError, error }] = useDeletePartMutation();
  const companyId = useAppSelector(selectCompanyId)

  const deletePartHandler = async (part: Part) => {
    await deletePart({ companyId: companyId, partId: part.id })
  }

  let content

  if (isSuccess) {
    content = <SuccessSnackbar message='Deleted.' />
  } else if (isError) {
    content = <ErrorSnackbar error={error} />
  }

  return (
    <>
      {content}
      <IconButton color="error" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
      >
        <DialogTitleBox onClose={handleClose}>Delete</DialogTitleBox>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete part: <b>{props.part.id}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => deletePartHandler(props.part)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}