import { useMediaQuery, useTheme } from "@mui/material";
import { Grid, Paper, Typography } from "@mui/material";
import DeviceEventsDashboard from "./DeviceEventsDashboard";
import DevicesUtilisationDashboard from "./DevicesUtilisationDashboard";

export const Dashboard = () => {

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const utilisationHeight = smUp ? 380 : 200
  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: {utilisationHeight},
            }}
          >
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Utilisation
            </Typography>
            <DevicesUtilisationDashboard />
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 380,
            }}
          >
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              News
            </Typography>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Device Events
            </Typography>
            <DeviceEventsDashboard />
          </Paper>
        </Grid>
      </Grid>
  );
}
