import {
  Alert, Backdrop,
  CircularProgress
} from '@mui/material';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useGetStoreApplicationQuery } from "../../reducers/apiSlice";
import { getErrorMessage } from '../../utils';
import { AppConfigurator as AppConfiguratorCustom } from './AppConfiguratorCustom';
import { AppConfiguratorFlow } from './AppConfiguratorFlow';

export default function AppConfigurator() {
  const { idAndVersion } = useParams();

  if (idAndVersion) {
    return <AppConfiguratorPrivate idAndVersion={idAndVersion} />
  } else { return <></>}
}

export const AppConfiguratorPrivate = ({idAndVersion} : {idAndVersion: string}) => {
  
  const {
    data: storeApplication,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStoreApplicationQuery({ idAndVersion })

  let content
  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (storeApplication.availability !== 'public') {
      content = <AppConfiguratorCustom storeApplication={storeApplication}/>
    } else {
      content = <AppConfiguratorFlow storeApplication={storeApplication}/>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Container maxWidth='md' sx={{ p:2, background: 'white' }}>
      {content}
    </Container>
  )
}
