import log from "loglevel"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { AppState } from "../store"
import { selectIdToken } from "./authSlice"
import { RequestStatus, ResourceType } from "./enums"
import { DynamicConfig, globalConfig } from '../configuration/config';

export interface Audit {
  time: string
  user: string
  companyId: string
  event: string
  resourceType: ResourceType
  resourceId: string
  description: string
}

export interface AuditQuery {
  user?: string
  companyId: string
  resourceType: ResourceType
  startTime: Date;
  endTime: Date;
  searchTerm?: string
}

export type AuditQuerySave = {
  startTime: number;
  endTime: number;
  resourceType: ResourceType
  searchTerm?: string
}

export interface AuditLogs {
  query: AuditQuerySave;
  data: Audit[];
}

export interface AuditLogsStateSliceMap {
  [index: string]: AuditLogs; // index
}

export interface AuditLogsStateSlice {
  status: RequestStatus
  error: string
  items: AuditLogsStateSliceMap
}

const initialState: AuditLogsStateSlice = {
  status: RequestStatus.idle,
  error: "",
  items: {},
};

export const getAuditLogs = createAsyncThunk(
  "getAuditLogs",
  async (
    params: AuditQuery,
    thunkAPI
  ) => {
    let auditLogs: Audit[] = []
    try {
      const config: DynamicConfig = globalConfig.get();
      const token = selectIdToken(thunkAPI.getState() as AppState);
      const query: any = {
        companyId: params.companyId,
        startTime: +params.startTime,
        endTime: +params.endTime,
        searchTerm: params.searchTerm
      }

      if (params.resourceType !== ResourceType.ANY) {
        query.resourceType = params.resourceType;
      }

      const searchParams = new URLSearchParams({ ...query }).toString()
      const url = new URL(`${config.sbk_api}/auditLogs?${searchParams}`);

      const response = await fetch(url,
        {
          method: "GET",
          mode: 'cors',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        })
      auditLogs = await response.json()
    }
    catch (err) {
      return (err instanceof Error) ? thunkAPI.rejectWithValue(err.message) :
        thunkAPI.rejectWithValue(JSON.stringify(err))
    }

    return {
      query: {
        ...params,
        startTime: +params.startTime,
        endTime: +params.endTime
      },
      auditLogs: auditLogs,
    };
  }
);

export const auditLogsSlice = createSlice({
  name: "auditLogs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAuditLogs.pending, (state, action) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getAuditLogs.fulfilled, (state, action) => {
        if (!state.items[action.payload.query.companyId]) {
          state.items = {};
        }
        state.status = RequestStatus.succeeded
        state.items[action.payload.query.companyId] = {
          data: action.payload.auditLogs,
          query: {
            startTime: +action.payload.query.startTime,
            endTime: +action.payload.query.endTime,
            resourceType: action.payload.query.resourceType,
            searchTerm: action.payload.query.searchTerm
          },
        };
      })
      .addCase(getAuditLogs.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.payload as string
        log.error(action.payload);
      });
  },
});

export const selectAuditQueryParams =
  (companyId: string) =>
    (state: AppState): AuditQuerySave => {
      if (state.auditLogs.items?.[companyId]) {
        return state.auditLogs.items[companyId].query;
      } else return { startTime: 0, endTime: 0, resourceType: ResourceType.ANY, searchTerm: '' };
    };

export const selectLoadingStatus =
  (state: AppState): { status: RequestStatus, error: string } => {
    return { status: state.auditLogs.status, error: state.auditLogs.error }
  }

export const selectAuditLogs =
  (companyId: string) =>
    (state: AppState): Audit[] => {
      if (state.auditLogs.items?.[companyId]) {
        // Add unique identified for each line
        let i = 0;
        return state.auditLogs.items[companyId].data.slice()
          .sort((a: Audit, b: Audit) => (a.time > b.time ? -1 : 1)).map((item: Audit) => ({
            ...item,
            id: (i++).toString(),
          }));
      } else return [];
    };

export default auditLogsSlice.reducer;