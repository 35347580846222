import auditLogsReducer, {AuditLogsStateSlice}  from './reducers/auditLogsSlice';
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import authReducer, { AuthStateSlice } from "./reducers/authSlice";
import eventsReducer, { EventsStateSlice } from "./reducers/eventsSlice";
import logsReducer, { LogsStateSlice } from "./reducers/logsSlice";
import metricsReducer, { MetricsStateSlice } from "./reducers/metricsSlice";
import uiReducer, { UiStateSlice } from "./reducers/uiSlice";
import { apiSlice } from './reducers/apiSlice'

// This is to ensure that after login (or retrieval of local token)
// the store is populated with a basic data.
/*
const authMiddleware = ({ dispatch, getState }: any) => {
  return (next: any) => async (action: any) => {
    const result = next(action)
    if (action.type === signInUser.fulfilled.type) {
      dispatch(getRobots({}));
    }
    return result;
  }
};
*/

const uiPersistConfig = {
  key: "ui",
  storage: storage,
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
}

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  metrics: metricsReducer,
  events: eventsReducer,
  logs: logsReducer,
  auditLogs: auditLogsReducer,
  ui: persistReducer(uiPersistConfig, uiReducer),
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export interface AppState {
  auth: AuthStateSlice
  metrics: MetricsStateSlice
  events: EventsStateSlice
  ui: UiStateSlice
  logs: LogsStateSlice
  auditLogs: AuditLogsStateSlice
}

let persistor = persistStore(store);

export { persistor };
export default store;

// See: https://react-redux.js.org/using-react-redux/usage-with-typescript
