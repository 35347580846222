import { Box, Button, Card, CardContent, CardMedia, CardHeader, CircularProgress } from "@mui/material"
import { Alert } from "@mui/material";
import { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { BaseRoute, SubRoute } from "../../enums"
import { useAppSelector } from "../../hooks"
import { useGetCompanyPartQuery } from "../../reducers/apiSlice"
import { selectCompanyId } from "../../reducers/authSlice"
import { getErrorMessage, imageForPart, partTypeSn, remainingLifePercents, UNDEFINED_PART_ID } from "../../utils"
import UtilProgress from "../UtilProgress"

export default function Part() {
  const { idp } = useParams()
  const { sn } = partTypeSn(idp ?? UNDEFINED_PART_ID)

  const { t } = useTranslation();

  const companyId = useAppSelector(selectCompanyId)
  const {
    data: part = { id: "n/a", mcVersion: "n/a", firmwareVersion: "n/a", utilisation: "n/a" },
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCompanyPartQuery({ companyId: companyId, sn })

  const navigate = useNavigate();
  
  const handleUtilisation = () => {
    navigate(`/${BaseRoute.MODULES}/${idp}/${SubRoute.UTILISATION}`)
  }

  let content
  if (isLoading) {
    content = <CircularProgress color="primary" />
  } else if (isSuccess) {
    const partntInfo = [
      { label: `${t('serial-number')}`, value: part.id },
      { label: `${t('mc-version')}`, value: part.mcVersion },
      { label: `${t('firmware')}`, value: part.firmwareVersion },
      { label: `${t('utilisation')}`, value: part.utilisation }
    ]
    content = (
      <Card sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <CardHeader
          title={sn}
        />
        <CardContent sx={{ width: '80%', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-around' }}>
          <CardMedia
            sx={{ width: '200px' }}
            component="img"
            alt="Part"
            height='auto'
            image={imageForPart(part, false)}
          />
          <Box>
            <Box sx={{
              display: 'flex',
              mt: 2,
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              {partntInfo.map((item) => {
                return (
                  <Box
                    key={item.label}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ width: 150 }}>{item.label}</Box>
                    <Box>{item.value}</Box>
                  </Box>
                )
              })}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <UtilProgress value={remainingLifePercents(part)} />
              <Button
                sx={{ mt: 1, width: "130px", height: "35px" }}
                variant="contained"
                onClick={handleUtilisation}
              >
                Utilisation
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    )
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      <Suspense fallback={<p>Loading Translations ...</p>}>
        {content}
      </Suspense>
    </>
  )
}