import { Box, Button, Typography } from '@mui/material';
import { ModulesToBuy } from './ModulesToBuy';

export const ModulesReview = (props: { handleNext: () => void }) => {
  const { handleNext } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Modules to buy</Typography>
      <ModulesToBuy count={2} moduleType='Drive Units' />
      <ModulesToBuy count={4} moduleType='Control Units' />
      <ModulesToBuy count={2} moduleType='Base Connectors' />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}