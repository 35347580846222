import { ReactElement } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import log from "loglevel";
import {
  defaultConfig,
  DynamicConfig,
  globalConfig,
} from "./configuration/config";

import './i18n';

let configUrl: string
const currentURL = window.location.href
log.debug("Current URL: " + currentURL)
if (currentURL.includes("dev.")) {
  configUrl="/config-dev.json"
  log.setLevel(log.levels.DEBUG);
} else if (currentURL.includes("localhost")) {
  // configUrl="/config-local.json"
  configUrl="/config-dev.json" // if you want to test a local UI with DEV API
  log.setLevel(log.levels.DEBUG);
} else {
  configUrl = "/config.json"
  log.setLevel(log.levels.INFO);
}

const app: ReactElement = <App />;
log.debug("Fetching global config from: " + configUrl);

fetch(configUrl)
  .then((response) => {
    if (response.status === 200) return response.json();
    throw new Error("Error fetching config.");
  })
  .then((responseJson) => {
    globalConfig.set(responseJson as DynamicConfig);
    log.debug("Global config fetched: ", responseJson);
    return app;
  })
  .catch((e) => {
    // In development, treat this case as a warning, render the app and use default config values.
    // In production (and test) on the other hand, show error instead of rendering the app.
    if (process.env.NODE_ENV === "development") {
      log.warn(
        `Failed to load global configuration from '${configUrl}', using the default configuration instead:`,
        defaultConfig
      );
      globalConfig.set(defaultConfig);
      return app;
    } else {
      const errorMessage =
        "Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)";
      log.error(
        errorMessage,
        `Have you provided the config file '${configUrl}'?`,
        e
      );
      return (
        <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
      );
    }
  })
  .then((reactElement: ReactElement) => {
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    root.render(reactElement);
  });
