import {
  Alert,
  Box,
  Button,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { Permission, hasPermisssion } from '../../model/security';
import {
  useGetCompanyApplicationsQuery
} from "../../reducers/apiSlice";
import { selectCompanyId, selectCurrentAuth } from '../../reducers/authSlice';

export const AppConfiguratorMain = (props : {handleNext: (name: string, configuration: any) => void }) => {
  
  const { handleNext } = props;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const companyId = useAppSelector(selectCompanyId)
  const auth = useAppSelector(selectCurrentAuth);
  
  const {
    data: companyApplications = [],
  } = useGetCompanyApplicationsQuery({ companyId });
  
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    if (companyApplications.some(application => application.name === event.target.value)) {
      setNameError('Application with this name already exists.')
    } else {
      setNameError('')
      setErrorMessage('')
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        sx={{ mt: 1, maxWidth: 350 }}
        value={name}
        helperText={nameError}
        error={nameError === '' ? false : true}
        onChange={handleTextChange}
        label="Name"
      />

      <Alert severity="info" sx={{ mt: 2 }}>
        This page will contain parameters for the application.
        Using those parameters a required application configuration will be generated.
      </Alert>

      {!hasPermisssion(auth, Permission.UPGRADE_APP) &&
        <Alert severity="info" sx={{ mt: 1 }}>
          You don't have a permission to create the application.
        </Alert>}

      {errorMessage && <Alert severity="error" sx={{ mt: 1 }}>{errorMessage}</Alert>}

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          disabled={!hasPermisssion(auth, Permission.INSTALL_UNINSTALL_APP)}
          onClick={() => handleNext(name, 'dummy configuration')}
          sx={{ mt: 2, p: 1 }}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
