import log from "loglevel";
import { useEffect } from "react";
import { EventRecord } from "../../model/model";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getDeviceListEvents, selectDeviceListEvents, selectLoadingStatus } from "../../reducers/eventsSlice";
import { endOfThisDay, QueryType, startOfThisDay } from "../../utils";
import DeviceEventsTable from "../events/DeviceEventsTable";
import { SeverityLevel } from "../../model/enums";
import { IotDevice, useGetCompanyIotDevicesQuery } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import { RequestStatus } from "../../reducers/enums";
import { Alert, CircularProgress } from "@mui/material";

export default function DeviceEventsDashboard() {

  // Default values for UI selectors
  let toDate = endOfThisDay(new Date());
  let fromDate = new Date();
  fromDate.setDate(toDate.getDate() - 1);
  fromDate = startOfThisDay(fromDate);

  const dispatch = useAppDispatch();
  
  const companyId = useAppSelector(selectCompanyId)
  const {
    data: iotDevices = [],
  } = useGetCompanyIotDevicesQuery({ companyId });

  let events: EventRecord[] = useAppSelector(
    selectDeviceListEvents(QueryType.Dashboard)
  );

  let loading = useAppSelector(selectLoadingStatus);

  useEffect(() => {
    if (iotDevices.length !== 0) {
      loadEvents(iotDevices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iotDevices]);

  const loadEvents = (iotDevices: IotDevice[]) => {
    log.debug(`DeviceEventsDashboard(): loading events for ${JSON.stringify(iotDevices)}.`);
    dispatch(
      getDeviceListEvents({
        iotDevices: iotDevices.map(item => item.id),
        startTime: fromDate,
        severity: SeverityLevel.WARN,
        endTime: toDate,
        type: QueryType.Dashboard,
      })
    )
  }
 
  let content = <></>
  if (loading.status === RequestStatus.loading) {
    content = <CircularProgress color="primary" />
  } else if (loading.status === RequestStatus.failed) {
    content = <Alert severity="error">{loading.error}</Alert>
  } else if (loading.status === RequestStatus.succeeded) {
    if (events.length === 0) {
      content = <>All good. No new events today.</>
    } else {
      content = <DeviceEventsTable events={events} allowFullscreen={false} />
    }
  }

  return (content)
}