import {
  Alert, Backdrop,
  Box,
  CircularProgress,
  Typography
} from '@mui/material';
import { useAppSelector } from '../../hooks';
import {
  AppStoreApplication,
  RobotApplication,
  useGetCompanyApplicationQuery,
  useGetStoreApplicationQuery
} from '../../reducers/apiSlice';
import { selectCompanyId } from "../../reducers/authSlice";
import { getErrorMessage } from "../../utils";

export const CompanyApplicationBasics = ({ appId }: { appId: string }) => {
  const companyId = useAppSelector(selectCompanyId)
  
  const {
    data: application,
    isLoading,
    isError: isAppError,
    error
  } = useGetCompanyApplicationQuery({ companyId, appId: appId ?? 'undefined'}, { skip: appId === undefined})

  const {
    data: storeApplication,
    isLoading: isStoreAppLoading,
    isError: isStoreAppError,
    error: storeAppError
  } = useGetStoreApplicationQuery({ idAndVersion: `${application?.appStoreId}-${application?.appStoreVersion}` }, 
    { skip: application === undefined })

  if (application && storeApplication) {
    return <CompanyApplicationInfo application={application} storeApplication={storeApplication}/>
  } else {
    let content
    if (isLoading || isStoreAppLoading) {
      content = <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    } else if (isAppError) {
      content = <Alert severity="error">{getErrorMessage(error)}</Alert>
    } else if (isStoreAppError) {
      content = <Alert severity="error">{getErrorMessage(storeAppError)}</Alert>
    }
    return <>{content}</>
  }
}

export const CompanyApplicationInfo = (
  { application, storeApplication }: { application: RobotApplication, storeApplication: AppStoreApplication }) => {

  return (
      <Box sx={{ p:2, display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
        <Typography variant="h5">{application.name}</Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">{storeApplication.name}</Typography>
          <Typography variant='body2'>Version: {storeApplication.version}</Typography>
          <Typography variant='body2'>Vendor: {storeApplication.vendor}</Typography>
          <Typography variant='body2' sx={{mt:4}}>{storeApplication.description}</Typography>
        </Box>
      </Box>
  )
}