import log from "loglevel";
import {
  BaseQueryFn, createApi, FetchArgs,
  fetchBaseQuery, FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';
import { DynamicConfig, globalConfig } from "../configuration/config";
import { Component, Part, Robot } from '../model/model';
import { AppState } from '../store';
import { signInUser, User } from './authSlice';
import { RequestStatus } from './enums';

export interface RobotQuery {
  id?: string
  companyId?: string
  state?: string
}

export interface PartQuery {
  companyId?: string
  id?: string
  mcVersion?: string
  firmwareVersion?: string
};

export interface UserQuery {
  companyId?: string
  name?: string
};

export interface EditedPart {
  id?: string,
  firmwareVersion?: string
  utilisation?: string
};

export interface EditedUser {
  company?: string
  role?: string
};

export interface Deployment {
  deploymentId: string,
  version: string | null,
  variant: string | null,
  type: string | null,
  deviceId: string,
  status: string,
  reason: string | null,
  creationTimestamp: number,
  modifiedTimestamp: number
}

export interface DeploymentConfigQuery {
  deviceId: string,
  type: string,
  version?: string
}

export interface ReleaseNotes {
  date: string
  added: string[]
  changed: string[]
  fixed: string[]
  removed: string[]
}

export interface DeploymentConfigComponent {
  componentVersion: string
}

export interface DeploymentTemplateComponentMap {
  [index: string]: DeploymentConfigComponent;
}

export interface DeploymentTemplate {
  type: string,                // deployment type
  version: string,             // deployment version
  variant: string,             // default or customer specific
  notes: ReleaseNotes,         // release notes
  components: DeploymentTemplateComponentMap,
}

export interface DeploymentTemplateQuery {
  type?: string
  variant?: string
  version?: string
};

export interface DeploymentCreate {
  type: string,
  deviceId: string,
  variant: string,
  version: string,
  skipComponents?: string[]
}

export interface StoreDevices {
  id: string
  name: string
  description: string[]
  type: string
  partIds: string[]
}

export interface AppComponent {
  name: string
  version: string
}

export interface AppStoreApplication {
  id: string // Unique App Identifier
  name: string
  version: string
  vendor: string
  description: string
  components: AppComponent[]
  availability: string // 'public' or 'companyId'
}

export interface AppComponentDeployment {
  iotDevice: string
  component: AppComponent
}

export interface RobotApplicationCreate {
  appStoreId: string // Unique App Identifier
  appStoreVersion: string // Unique App Identifier
  name: string // Name Provided By User
  configuration: any // Application Specific Configuration
  deployment?: AppComponentDeployment[]
}

export interface RobotApplicationUpdate {
  name?: string // Name Provided By User
  configuration?: any // Application Specific Configuration
  deployment?: AppComponentDeployment[]
}

export interface RobotApplication {
  id: string // Unique User App Instance Identifier
  appStoreId: string // Unique App Identifier
  appStoreVersion: string // Unique App Version Identifier
  name: string // Name Provided By User
  deployment: AppComponentDeployment[]
}

export interface Company {
  name: string
  companyId: string
}

export interface ApiVersion {
  version: string
}
export interface Variant {
  variant: string
}

export type IotDeviceDeviceStatus = 'HEALTHY' | 'UNHEALTHY' | string

export interface IotDevice {
  id: string
  status: IotDeviceDeviceStatus
  lastStatusUpdateTimestamp: number
}

export interface DeviceConfiguration {
  key: string
  value: string
}

export interface AppConfiguration {
  key: string
  value: string
}

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
  = async (args, api, extraOptions) => {

    let config: DynamicConfig
    try {
      config = globalConfig.get()
    } catch (err) {
      return {
        error: {
          status: 400,
          statusText: 'System Error',
          data: 'Dynamic configuration in missing.',
        },
      }
    }

    const configuredFetchBaseQuery =
      fetchBaseQuery({
        baseUrl: config.sbk_api,
        prepareHeaders: (headers, { getState }) => {
          // Token is in the store, use it for requests
          const token = (getState() as AppState).auth.user?.idToken
          if (token) {
            headers.set('Authorization', `Bearer ${token}`)
            headers.set('Content-Type', 'application/json')
          }
          return headers
        },
      })

    // Check time validity and re-new to avoid 401.
    const auth = (api.getState() as AppState).auth
    if (auth.user !== null) {
      let decoded: any = jwtDecode(auth.user.idToken);
      const ttl = Math.floor(decoded.exp - Date.now() / 1000);
      if (ttl < 0 && auth.status === RequestStatus.succeeded) {
        log.debug(`ApiSlice(): token expired, ttl=${ttl}. Renewing...`);
        await api.dispatch(signInUser());
      } else {
        log.debug(`ApiSlice(): token expires in ${ttl} seconds.`);
      }
    }

    let result = await configuredFetchBaseQuery(args, api, extraOptions)
    // log.debug("RESULT: " + JSON.stringify(result))

    if (result.error) {
      if (result.error.status === 401) {
        log.debug("ApiSlice(): HTTP 401, expired token?. Renewing...");
        await api.dispatch(signInUser());
        // re-try
        result = await configuredFetchBaseQuery(args, api, extraOptions)
      } else if (result.error.status === 'FETCH_ERROR') {
        // If API Gateway is misconfired and CORS header is missing from 401
        // responses the browser will raise an exception instead.
        log.warn('ApiSlice(): FETCH_ERROR: is API Gateway returning the correct CORS headers?')
      }
    }

    return result
  }

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: [
    'StoreApplications', 'Applications', 'Application', 'AppConfigurations', 'AppConfiguration',
    'Part', 'Parts',
    'Robot', 'Robots', 'Components', 'Users', 'Companies',
    'DeploymentTemplates', 'AuditLogs',
    'IotDevices', 'IotDeviceConfiguration',
    'Variants'],

  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({

    // Store / Applications
    getStoreApplications: builder.query<AppStoreApplication[], void>({
      query: () => {
        return `/store/applications`
      },
      providesTags: ['StoreApplications'],
    }),
    getStoreApplication: builder.query<AppStoreApplication, { idAndVersion: string }>({
      query: ({ idAndVersion }) => {
        return `/store/applications/${idAndVersion}`
      },
      providesTags: ['StoreApplications'],
    }),
    deleteApplication: builder.mutation<void, { appId: string }>({
      query: ({ appId }) => ({
        url: `/applications/${appId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StoreApplications'],
    }),

    // Company / Applications
    getCompanyApplications: builder.query<RobotApplication[], { companyId: string }>({
      query: ({ companyId }) => {
        return `/companies/${companyId}/apps`
      },
      providesTags: ['Applications', 'Application'],
    }),
    getCompanyApplication: builder.query<RobotApplication, { companyId: string, appId: string }>({
      query: ({ companyId, appId }) => {
        return `/companies/${companyId}/apps/${appId}`
      },
      providesTags: ['Applications', 'Application'],
    }),
    addNewCompanyApplication: builder.mutation<void, { application: RobotApplicationCreate, companyId: string }>({
      query: ({ companyId, application }) => ({
        url: `/companies/${companyId}/apps`,
        method: 'POST',
        body: application,
      }),
      invalidatesTags: ['Applications', 'Application'],
    }),
    updateCompanyApplication: builder.mutation<void, { companyId: string, appId: string, update: RobotApplicationUpdate }>({
      query: ({ companyId, appId, update }) => ({
        url: `/companies/${companyId}/apps/${appId}`,
        method: 'PATCH',
        body: update
      }),
      invalidatesTags: (result: any) =>
        result
          ? [
            { type: 'Application', id: result.name },
            { type: 'Application', id: 'LIST' },
          ]
          : ['Application', 'Applications'],
    }),

    // Company / Application / Configuration
    getCompanyAppConfiguration:
      builder.query<AppConfiguration, { appId: string, key: string, companyId: string }>({
        query: ({ appId, key, companyId }) => {
          return `/companies/${companyId}/apps/${appId}/configuration/shared/${key}`
        },
        providesTags: ['AppConfiguration']
      }),
    updateCompanyAppConfiguration: builder.mutation<void, { appId: string, companyId: string, iotDevice: string, config: DeviceConfiguration }>({
      query: ({ appId, companyId, config }) => ({
        url: `/companies/${companyId}/apps/${appId}/configuration/shared`,
        method: 'POST',
        body: config,
      }),
      invalidatesTags: ['AppConfiguration']
    }),

    // Company / Robots
    getCompanyRobots: builder.query<Robot[], string>({
      query: (companyId) => {
        return `/companies/${companyId}/robots`
      },
      providesTags: ['Robots'],
    }),
    getRobots: builder.query<Robot[], RobotQuery>({
      query: (query: RobotQuery) => {
        const searchParams = new URLSearchParams({ ...query }).toString()
        return `/companies/${query.companyId}/robots?${searchParams}`
      },
      providesTags: ['Robots'],
    }),
    getCompanyRobot: builder.query<Robot, { companyId: string, robotId: string }>({
      query: (params: { companyId: string, robotId: string }) => {
        return `/companies/${params.companyId}/robots/${params.robotId}`
      },
      providesTags: ['Robot'],
    }),
    getCompanyRobotParts: builder.query<Part[], { companyId: string, robotId: string }>({
      query: (params: { companyId: string, robotId: string }) => {
        return `/companies/${params.companyId}/robots/${params.robotId}/parts`
      },
      providesTags: ['Parts'],
    }),

    // Company / IoT Devices
    getCompanyIotDevices: builder.query<IotDevice[], { companyId: string }>({
      query: ({ companyId }) => {
        return `/companies/${companyId}/iotDevices`
      },
      providesTags: ['IotDevices'],
    }),

    // Company / IoT Devices / Configuration
    getDeviceConfiguration: builder.query<DeviceConfiguration, { componentId: string, key: string, companyId: string, iotDevice: string }>({
      query: ({ componentId, key, companyId, iotDevice }) => {
        return `/companies/${companyId}/iotDevices/${iotDevice}/configuration/${componentId}/items/${key}`
      },
      providesTags: ['IotDeviceConfiguration'],
    }),
    updateDeviceConfiguration: builder.mutation<void, { componentId: string, companyId: string, iotDevice: string, config: DeviceConfiguration }>({
      query: ({ componentId, companyId, iotDevice, config }) => ({
        url: `/companies/${companyId}/iotDevices/${iotDevice}/configuration/${componentId}/items`,
        method: 'POST',
        body: config,
      }),
      invalidatesTags: ['IotDeviceConfiguration'],
    }),

    // Company / Parts
    getCompanyPart: builder.query<Part, { companyId: string, sn: string }>({
      query: (params: { companyId: string, sn: string }) => {
        return `/companies/${params.companyId}/parts/${params.sn}`
      },
      providesTags: ['Part', 'Parts'],
    }),
    getParts: builder.query<Part[], PartQuery>({
      query: (query: PartQuery) => {
        const searchParams = new URLSearchParams({ ...query }).toString()
        return `/parts?${searchParams}`
      },
      providesTags: ['Part', 'Parts'],
    }),
    addNewPart: builder.mutation<void, { companyId: string, part: Part }>({
      query: ({ companyId, part }) => ({
        url: `/companies/${companyId}/parts`,
        method: 'POST',
        body: part,
      }),
      invalidatesTags: ['Parts', 'Part'],
    }),
    updatePart: builder.mutation<void, { companyId: string, partId: string, part: EditedPart }>({
      query: ({ companyId, partId, part }) => ({
        url: `/companies/${companyId}/parts/${partId}`,
        method: 'PATCH',
        body: part
      }),
      invalidatesTags: (result: any) =>
        result
          ? [
            { type: 'Parts', id: result.id },
            { type: 'Parts', id: 'LIST' },
          ]
          : ['Parts'],
    }),
    deletePart: builder.mutation<void, { companyId: string, partId: string }>({
      query: ({ companyId, partId }) => ({
        url: `/companies/${companyId}/parts/${partId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Parts'],
    }),

    // Users
    getUsers: builder.query<User[], UserQuery>({
      query: (query: UserQuery) => {
        const searchParams = new URLSearchParams({ ...query }).toString()
        return `/users?${searchParams}`
      },
      providesTags: ['Users'],
    }),
    updateUser: builder.mutation<void, { userId: string, user: EditedUser }>({
      query: ({ userId, user }) => ({
        url: `/users/${userId}`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: (result: any) =>
        result
          ? [
            { type: 'Users', id: result.id },
            { type: 'Users', id: 'LIST' },
          ]
          : ['Users'],
    }),
    deleteUser: builder.mutation<void, { companyId: string, userId: string }>({
      query: ({ companyId, userId }) => ({
        url: `/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),

    // IoT
    getIotComponents: builder.query<Component[], string>({
      query: (deviceId) => {
        return `/things/${deviceId}/components`
      },
      providesTags: ['Components'],
    }),

    // Deployment Template
    getDeviceDeploymentTemplate: builder.query<DeploymentTemplate[], DeploymentTemplateQuery>({
      query: (query: DeploymentTemplateQuery) => {
        const searchParams = new URLSearchParams({ ...query }).toString()
        return `/deploymentTemplates?${searchParams}`
      },
      providesTags: ['DeploymentTemplates'],
    }),

    // Deployment Template Variants List
    getDeviceDeploymentTemplateVariants: builder.query<string[], void>({
      query: () => {
        return `/deploymentTemplates?listVariants`
      },
      providesTags: ['Variants'],
    }),

    // Deployment
    getDeviceDeployment: builder.query<Deployment, DeploymentConfigQuery>({
      query: (query: DeploymentConfigQuery) => {
        return `/things/${query.deviceId}/deployments/${query.type}`
      }
    }),

    addDeviceDeployment: builder.mutation<void, { deployment: DeploymentCreate }>({
      query: ({ deployment }) => ({
        url: `/things/${deployment.deviceId}/deployments`,
        method: 'POST',
        body: deployment,
      })
    }),

    // Companies
    getCompanies: builder.query<Company[], void>({
      query: () => {
        return `/companies`
      },
      providesTags: ['Companies'],
    }),

    // ApiVersion
    getApiVersion: builder.query<ApiVersion, void>({
      query: () => {
        return `/version`
      }
    }),
  })
})

export const {
  useGetStoreApplicationsQuery,
  useGetStoreApplicationQuery,
  useGetCompanyRobotQuery,
  useGetCompanyRobotsQuery,
  useGetCompanyPartQuery,
  useGetCompanyRobotPartsQuery,
  useUpdateCompanyApplicationMutation,
  useAddNewCompanyApplicationMutation,
  useGetCompanyApplicationsQuery,
  useGetCompanyApplicationQuery,
  useGetCompanyAppConfigurationQuery,
  useUpdateCompanyAppConfigurationMutation,
  useGetRobotsQuery,
  useGetPartsQuery,
  useAddNewPartMutation,
  useUpdatePartMutation,
  useDeletePartMutation,
  useGetIotComponentsQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useGetDeviceDeploymentTemplateQuery,
  useGetDeviceDeploymentTemplateVariantsQuery,
  useGetDeviceDeploymentQuery,
  useAddDeviceDeploymentMutation,
  useGetCompaniesQuery,
  useGetCompanyIotDevicesQuery,
  useGetDeviceConfigurationQuery,
  useUpdateDeviceConfigurationMutation,
  useGetApiVersionQuery,
} = apiSlice
