export interface DynamicConfig {
  environment: "LOCAL" | "DEV" | "TEST" | "PROD"
  region: string
  user_pool_id: string
  identity_pool_id: string
  user_pool_web_client_id: string
  sbk_api: string
}

export const defaultConfig: DynamicConfig = {
  environment: "LOCAL",
  region: "eu-central-1",
  user_pool_id: "eu-central-1_itmHlsirA",
  identity_pool_id: "eu-central-1:bec8709f-a8ef-4851-a82a-9bb79cc2171d",
  user_pool_web_client_id: "7ll629hi5js000jrro9a9mq7mn",
  sbk_api: "http://localhost:3001"
};

class GlobalConfig {
  config: DynamicConfig = defaultConfig;
  notDefinedYet = true;

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error(
        "Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing globalConfig in static context."
      );
    } else {
      return this.config;
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value;
      this.notDefinedYet = false;
    } else {
      throw new Error(
        "Global config has already been defined and now has been called second time. This is probably not intended."
      );
    }
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "/config.json";
