import { useState } from 'react';
import { Box, Alert } from '@mui/material';
import { DeploymentStatus } from '../../reducers/enums';
import DeviceDeploymentStatus from '../iot/InstallationStatus';

export interface DeviceStatusMap {
  [index: string]: string; // index is IoT device
}

export const AppInstallationStatus = (props: { statusCompletedFn: (completed: boolean) => void, iotDevices: string[] }) => {
  const { iotDevices, statusCompletedFn } = props;

  const [devicesStatus, setDevicesStatus] = useState<DeviceStatusMap>({});

  const handleReportedStatus = (device: string) => {
    return function (status: string) {
      // copy current status
      let updatedDeviceStatus: DeviceStatusMap = {}
      Object.assign(updatedDeviceStatus, devicesStatus)
      // update status of device
      updatedDeviceStatus[device] = status
      setDevicesStatus(updatedDeviceStatus);
      // evaluate if all is ok
      Object.values(updatedDeviceStatus).filter(status => status !== DeploymentStatus.COMPLETED).length === 0 &&
        statusCompletedFn(true)
    }
  };

  return (
    <Box sx={{ display: 'flex', p: 4, flexDirection: 'column' }}>
      {
        Object.values(devicesStatus).length !== 0 &&
        <Alert severity="info">
          Installation of the application is in progress.
        </Alert>
      }
      {
        iotDevices.map(device =>
          <DeviceDeploymentStatus key={device} iotDevice={device} statusFn={handleReportedStatus(device)} />
        )
      }
    </Box>
  )
}