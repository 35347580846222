import log from "loglevel";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import {
  CloudWatchLogsClient,
  GetQueryResultsCommand,
  StartQueryCommand,
  StartQueryRequest,
} from "@aws-sdk/client-cloudwatch-logs";
import { DynamicConfig, globalConfig } from "../configuration/config";
import { LogParser, LogsQueryReq } from "../reducers/logsSlice";

export const LOGS_LIMIT = 10000; 

export type LogLine = {
  time: string;
  level: string;
  message: string;
};

type QueryResultLogLine = {
  [x: string]: string;
};

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

class LogsClient {
  private static instance: LogsClient;
  private static client: any;
  private static idToken: string;

  private constructor(idToken: string) {
    const config: DynamicConfig = globalConfig.get();
    const user_pool = `cognito-idp.${config.region}.amazonaws.com/${config.user_pool_id}`;
    const loginData = {
      [user_pool]: idToken,
    };

    LogsClient.client = new CloudWatchLogsClient({
      region: config.region,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: config.region },
        identityPoolId: config.identity_pool_id,
        logins: loginData,
      }),
    });
  }

  public static getInstance(idToken: string): LogsClient {
    if (!LogsClient.instance || idToken !== this.idToken) {
      log.debug("Creating new LogsClient instance.");
      LogsClient.instance = new LogsClient(idToken);
      this.idToken = idToken;
    }
    return LogsClient.instance;
  }

  public async getLogs(params: LogsQueryReq): Promise<LogLine[]> {
    const endTime = params.endTime;
    const startTime = params.startTime;
    

    let queryString = ""
    if (params.parser === LogParser.CONTAINER ) {
      queryString = `
      fields @message 
      | filter @logStream like '${params.iotDevice}' 
      | parse '* [*] (Copier) *: *. * {' as time, level, component, stdout_or_err, message, junk
      | filter message like '${params.filter}'
      | sort @timestamp asc 
      | display time, level, message
      `
    }
    else if (params.parser === LogParser.APPLICATION) {
      queryString = `
      fields @message
      | filter @logStream like '${params.iotDevice}'
      | parse @message /(?<time1>[-:.\\w]+) (?<junk>.*)\\[(?<proc>.*)\\] \\[(?<level>.*)\\] \\[(?<time>.*)\\] \\[(?<module>.*)\\].*: (?<logmessage>.*) \\{.*/
      | filter logmessage like '${params.filter}'
      | sort @timestamp asc
      | display time, level, logmessage
      | display time, level, concat('[',  module, '] ', logmessage) as message

      `
    }
    else {
      log.error("Unsupported log parse type: " + params.parser)
    }

    var request: StartQueryRequest = {
      queryString: queryString,
      startTime: +startTime,
      endTime: +endTime,
      logGroupName: `/aws/greengrass/UserComponent/eu-central-1/${params.component.value}`,
      limit: LOGS_LIMIT,
    };

    const command = new StartQueryCommand(request);
    const query = await LogsClient.client.send(command);

    let status;
    let response: any = [];
    let processed: LogLine[] = []

    let dateFn: any
    if (params.parser === LogParser.CONTAINER) {
      dateFn = (x: any) => {
        return x}
    } 
    else {
      dateFn = (x: any) => {
        return (Math.floor(Number(x)*1000))}
    }

    // Poll the query every 2 seconds until complete. 
    // Partial resuls are discarded as only thhe final result has ALL data.
    while (status !== "Complete") {
      response = await wait(2000).then(() =>
        LogsClient.client.send(
          new GetQueryResultsCommand({
            queryId: query.queryId,
          })
        )
      )
      status = response.status;
      if (status === "Failed") {
        throw new Error("Failed to read query result: " + response);
      }
    }

    response.results.forEach((line: QueryResultLogLine[]) => {
      var retLine: any = {}
      line.forEach((lineFields: QueryResultLogLine) => {
        if (lineFields["field"] !== "@ptr") {
          if (lineFields["field"] === "time") {
            retLine[lineFields["field"]] = dateFn(lineFields["value"])
          }
          else {
            retLine[lineFields["field"]] = lineFields["value"]
          }
        }
      });
      if (Object.keys(retLine).length !== 0) {
        processed.push(retLine)
      }
    });

    return processed;
  }
}

export default LogsClient;
