import { ListItemButton, ListItemIcon, ListItemText, Box, List, Collapse, CardMedia } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NotesIcon from '@mui/icons-material/Notes';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useState } from 'react';


export const ModulesToBuy = (props: { moduleType: string, count: number }) => {
  const { moduleType, count } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <ListItemButton sx={{ background: '#f5f5f5', borderRadius: '10px', m: 2, height: '60px' }} onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={moduleType} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box sx={{ ml: 1 }}>
            <ListItemButton>
              <ListItemIcon>
                <NotesIcon />
              </ListItemIcon>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 1 }}>
                  <CardMedia
                    sx={{ width: 'auto', mixBlendMode: 'multiply' }}
                    component="img"
                    alt="Module"
                    height="50px"
                    image="../../../../parts/thumbnails/BCA02A0.png" />
                  <Box>
                    <ListItemText primary={`X ${count}`} />
                  </Box>
                </Box>
              </Box>
            </ListItemButton>
          </Box>
          <Box sx={{ ml: 1 }}>
            <ListItemButton>
              <ListItemIcon>
                <NotesIcon />
              </ListItemIcon>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 1 }}>
                  <CardMedia
                    sx={{ width: 'auto', mixBlendMode: 'multiply' }}
                    component="img"
                    alt="Module"
                    height="50px"
                    image="../../../../parts/thumbnails/CUA01A1.png" />
                  <Box>
                    <ListItemText primary={`x ${count}`} />
                  </Box>
                </Box>
              </Box>
            </ListItemButton>
          </Box>
        </List>
      </Collapse>
    </Box>
  )
}