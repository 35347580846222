import {
  Alert,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Button,
  Box
} from '@mui/material';
import DialogTitleBox from '../DialogTitleBox';
import { useState } from 'react';

export const ButtonWithConfirmationDialog = (props: { disabled: boolean, action: any, title: string, message: string }) => {
  const { disabled, action, title, message } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        disabled={disabled}
        sx={{ m: 1 }}
        variant="contained"
      >
        {title}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
      >
        <DialogTitleBox onClose={handleClose}>{title}</DialogTitleBox>
        <DialogContent>
          <Alert severity="warning">{message}</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={disabled}
            onClick={() => { handleClose(); action()}}
          >
            {title}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}