import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Alert from "@mui/material/Alert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import log from "loglevel";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectModuleMetricData,
  selectModuleMetrics,
  selectLoadingStatus,
  getRobotModuleMetricsData,
  getRobotModuleMetrics,
} from "../../reducers/metricsSlice";
import {
  endOfThisDay,
  QueryType,
  startOfThisDay,
} from "../../utils";
import { aggregationPeriods, getMetricDefinition } from "./Utilisation";
import UtilisationChart from "./UtilisationChart";
import { RequestStatus } from "../../reducers/enums";
import { selectModuleMetricsReq } from "../../reducers/uiSlice";

export default function DeviceUtilisation() {
  const { idp } = useParams();
  if (idp) {
    return <PartUtilisationPrivate idp={idp}/>
  } else { return <></>}
}

function PartUtilisationPrivate({ idp }: { idp: string }) {

  const [partType, partId] = idp.split('_')

  // Default values for UI selectors
  let defaultEndTime = endOfThisDay(new Date());
  let defaultStartTime = new Date();
  defaultStartTime.setDate(defaultEndTime.getDate());
  defaultStartTime = startOfThisDay(defaultStartTime);
  const defaultAggrPeriod = aggregationPeriods[2];
  let defaultMetricName = null

  // Selectors for the data in the store
  let loading = useAppSelector(selectLoadingStatus)
  const savedQuery = useAppSelector( selectModuleMetricsReq(idp));
  const metricsData = useAppSelector(selectModuleMetricData(idp, QueryType.User))
  const metricsTypes = useAppSelector(selectModuleMetrics(idp))
  
  // State
  const [startTimePicker, setStartTimePicker] = useState<Date | null>(defaultStartTime)
  const [endTimePicker, setEndTimePicker] = useState<Date | null>(defaultEndTime)
  const [startTime, setStartTime] = useState<Date>(defaultStartTime)
  const [endTime, setEndTime] = useState<Date>(defaultEndTime)
  const [aggregation, setAggregation] = useState<number | null>(defaultAggrPeriod.value)
  const [metricName, setMetricName] = useState<string | null>(defaultMetricName)
  const [fullscreen, setFullScreen] = useState(false)
  const [initParamsReady, setInitParamsReady] = useState(false)

  const dispatch = useAppDispatch();

  // Load metric types for the module
  useEffect(() => {
    async function fetchMetricTypes() {
      log.debug('PartUtilisation(): Loading part metric types.')
      await dispatch(
        getRobotModuleMetrics({
          sourceId: partId,
          sourceType: partType
        })
      )
    }
    fetchMetricTypes()
  }, [dispatch, partId, partType]);
 
  // If there is already a metric in the store use its params to set UI selectors.
  // This is useful when you are returning to this view.
  useEffect(() => {
    if (savedQuery !== undefined && initParamsReady === false) {
      setMetricName(savedQuery.metricName)
      setStartTimePicker(new Date(savedQuery.startTime))
      setEndTimePicker(new Date(savedQuery.endTime))
      setStartTime(new Date(savedQuery.startTime))
      setEndTime(new Date(savedQuery.endTime))
      const period = aggregationPeriods.find(
        period => period.value === savedQuery.aggrPeriod
      )
      if (period) setAggregation(period.value)
      setInitParamsReady(true)
    }
  }, [initParamsReady, savedQuery]);
 
  const handleLoad = async () => {
    if (
      startTimePicker !== null &&
      endTimePicker !== null &&
      aggregation !== null &&
      metricName !== null &&
      metricsTypes !== undefined
    ) {
      await dispatch(
        getRobotModuleMetricsData({
          deviceIds: metricsTypes[metricName].coreNames,
          sourceId: partId,
          sourceType: partType,
          metricName: metricName,
          startTime: startTimePicker,
          endTime: endTimePicker,
          aggrPeriod: aggregation,
          type: QueryType.User,
        })
      );
      // Copy the time from the pickers to the time for the Chart to only render on handleLoad()
      setStartTime(startTimePicker);
      setEndTime(endTimePicker);
    } else {
      log.error("Not all query parameters provided.");
    }
  };

  useEffect(() => {
    async function fetchData() {
      log.debug('PartUtilisation(): Loading part metric data for restored params.')
      if (initParamsReady && metricsTypes !== undefined) {
        await handleLoad()
      }
    }
    fetchData()
  }, [initParamsReady, metricsTypes]);

  const setDefaults = async () => {
    setStartTime(defaultStartTime)
    setEndTime(defaultEndTime)
    setStartTimePicker(defaultStartTime)
    setEndTimePicker(defaultEndTime)
    setAggregation(defaultAggrPeriod.value)
  } 

  let metricNames: string[] = [""]
  metricNames = Object.keys(metricsTypes ?? {})
  const metricDef = getMetricDefinition(metricName)

  const alert = loading.status === RequestStatus.failed ?
    <Alert severity="error">{loading.error}</Alert> : ""

  return (
    <Paper 
      sx={{ p: 2, display: "flex", flexDirection: "column",
       }}>
      {alert}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {metricDef.name}        
      </Typography>
      <Box
        sx={{
          display: "flex",
          p: 1,
          m: 1,
          justifyContent: "center",
          flexWrap: 'wrap'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              m: 0,
              justifyContent: 'center'
            }}>
            <DatePicker
              label="From"
              renderInput={(params) => <TextField sx={{ width: 150, m: 1 }} {...params} />}
              value={startTimePicker}
              onChange={(newValue) => {
                setStartTimePicker(newValue);
              }}
            />
            <DatePicker
              label="To"
              renderInput={(params) => <TextField sx={{ width: 150, m: 1 }} {...params} />}
              value={endTimePicker}
              onChange={(newValue) => {
                setEndTimePicker(newValue);
              }}
            />
            <FormControl sx={{ width: 100, m: 1 }}>
              <InputLabel id="aggregation-label">Aggregation</InputLabel>
              <Select
                label="Aggregation"
                labelId="aggregation-label"
                value={aggregation}
                onChange={(newValue) => {
                  if (newValue.target.value != null)
                    setAggregation(+newValue.target.value);
                }}
              >
                {aggregationPeriods.map(period => <MenuItem key={period.value} value={period.value}>{period.label}</MenuItem>)}
              </Select>
            </FormControl>
            <Autocomplete
              disablePortal
              id="combo-box-metric-name"
              sx={{ minWidth: 200, m: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Metric"
                  className="dateInput"
                />
              )}
              value={metricName}
              options={metricNames}
              onChange={(event: any, newValue: any) => {
                setMetricName(newValue);
              }}
            />
          </Box>
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row'
            }}>
            <LoadingButton sx={{ width: "150px", m: 1 }} 
              loading={loading.status === RequestStatus.loading}
              startIcon={<RefreshIcon />}
              loadingPosition="start" variant="contained" 
              onClick={handleLoad}>
              Load
            </LoadingButton>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box>
        <Button startIcon={<FullscreenIcon />} onClick={() => setFullScreen(true)}>Show Full Screen</Button>
        <Button startIcon={<RestartAltIcon />} onClick={setDefaults}>Defaults</Button>
      </Box>
      <UtilisationChart
        fromDate={startTime}
        toDate={endTime}
        timePeriod={aggregation ?? 60}
        metricName={metricName}
        metricsData={metricsData}
        metricKeys={['value']}
        fullScreen={fullscreen}
        setFullScreen={setFullScreen}
      ></UtilisationChart>
    </Paper>
  );
}