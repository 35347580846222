import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseFullcreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box, Button, Dialog, MenuItem,
  Container, Table, TableBody, TableCell,
  TableContainer,
  TableHead, TablePagination, TableRow,
  TextField, useMediaQuery, useTheme
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import log from "loglevel";
import React, { ReactElement, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RequestStatus } from "../reducers/enums";
import {
  GreenGrassComponentType,
  LogLineTable, LogParser,
  LogParserType,
  getDeviceLogs, greenGrassComponents,
  logParsers,
  selectLoadingStatus, selectLogs,
  selectLogsQueryParams
} from "../reducers/logsSlice";
import { LOGS_LIMIT, LogLine } from "../services/LogsClient";
import { QueryType, endOfThisDay, startOfThisDay } from "../utils";

interface Column {
  id: "time" | "level" | "message";
  label: string;
  maxWidth?: number;
  align?: "right";
  format?: (value: any) => any;
  show?: boolean;
}

const componentFor = (value: string): GreenGrassComponentType => {
  return greenGrassComponents.find(x => x.value === value) ?? greenGrassComponents[0]
}

export const parserFor = (code: LogParser): LogParserType => {
  return logParsers.find(x => x.code === code) ?? logParsers[0]
}

export default function DeviceLogs(props: { iotDevice: string}) {
  const { iotDevice } = props

  const dispatch = useAppDispatch()

  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  let defaultToDate = endOfThisDay(new Date());
  let defaultFromDate = new Date();
  let defaultFilter = '';
  let defaultLogParser = parserFor(LogParser.CONTAINER)
  let defaultGreenGrassComponent = greenGrassComponents[0]
  defaultFromDate.setDate(defaultToDate.getDate() - 1);
  defaultFromDate = startOfThisDay(defaultFromDate);

  const queryParams = useAppSelector(
    selectLogsQueryParams(iotDevice, QueryType.User)
  );

  if (queryParams) {
    if (queryParams.startTime) {
      defaultFromDate = new Date(queryParams.startTime);
    }
    if (queryParams.endTime) {
      defaultToDate = new Date(queryParams.endTime);
    }
    if (queryParams.filter) {
      defaultFilter= queryParams.filter;
    }
    if (queryParams.component) {
      defaultGreenGrassComponent= queryParams.component;
    }
    if (queryParams.parser) {
      defaultLogParser= parserFor(queryParams.parser);
    }
  }

  const [fullScreen, setFullScreen] = useState(false)
  const [orderLatestFirst, setOrderLatestFirst] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [fromDate, setFromDate] = useState<Date | null>(defaultFromDate);
  const [toDate, setToDate] = useState<Date | null>(defaultToDate);
  const [logParser, setLogParser] = useState<LogParserType>(defaultLogParser)
  const [searchTerm, setSearchTerm] = useState<string>(defaultFilter)
  const [greenGrassComponent, setGreenGrassComponent]
    = useState<GreenGrassComponentType>(defaultGreenGrassComponent)
    const dateValidation = fromDate !== null && toDate !== null && fromDate.getTime() < toDate.getTime();

  let loading = useAppSelector(selectLoadingStatus)

  let logs: LogLineTable[] = useAppSelector(
    selectLogs(iotDevice, QueryType.User)
  );

  // Functions

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortLogsLatestFirst = (logs: LogLineTable[]) => {
    return logs.sort((a: LogLine, b: LogLine) => a.time > b.time ? 1 : -1)
  }

  const handleLoad = () => {
    if (fromDate !== null && toDate !== null) {
      dispatch(
        getDeviceLogs({
          iotDevice: iotDevice,
          component: greenGrassComponent,
          startTime: fromDate,
          endTime: toDate,
          parser: logParser.code,
          filter: searchTerm,
          type: QueryType.User
        })
      );
    } else {
      log.error("Not all query parameters provided.");
    }
  };

  const formatDate = (date: string) => {
    let formatted = new Date(date)
    return `${formatted.toLocaleDateString()}, ${formatted.toLocaleTimeString()},${formatted.getMilliseconds()}`
  }

  const highlight = (item: string, searchTerm: string) => {
    if (searchTerm !== '') {
      const split = (item as string).split(searchTerm)
      let result: ReactElement[] = []
      for (let i = 0; i < split.length; i++) {
        if (i === split.length - 1) {
          result.push(<>{split[i]}</>)
        } else {
          result.push(
            <>
              <Box component="span">{split[i]}</Box>
              <Box component="span" sx={{ bgcolor: "yellow" }}>{searchTerm}</Box>
            </>)
        }
      }
      return <Box component="span">{result}</Box>
    }
    else {
      return item
    }
  }

  // Render logic

  const columns: Column[] = [
    {
      id: "time", label: "Time", maxWidth: 70,
      format: (item) => formatDate(item), show: showSmUp
    },
    { id: "level", label: "Level", maxWidth: 20, show: showSmUp },
    {
      id: "message", label: "Message", maxWidth: 200,
      format: (item) => highlight(item, searchTerm),
      show: true
    },
  ];

  let logsSorted = orderLatestFirst ? sortLogsLatestFirst(logs) : logs

  let logsTable =
    <Box>
      {logsSorted.length > LOGS_LIMIT
        && <Alert severity="info">{`The limit of ${LOGS_LIMIT} fetched rows has been reached. Change the time range to see more logs.`}</Alert>}
      <TableContainer sx={{ maxHeight: 900 }}>
        <Table stickyHeader size="small" aria-label="a dense sticky table" sx={{ p: 0 }}>
          <TableHead>
            <TableRow>
              {columns.filter(item => item.show).map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ p: 1, maxWidth: column.maxWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {logsSorted
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ p: 0, m: 0 }}>
                    {columns.filter(item => item.show).map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} sx={{ p: 1, m: 0, maxWidth: column.maxWidth }}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 1000]}
        component="div"
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

  const alert = loading.status === RequestStatus.failed ?
    <Alert severity="error">{loading.error}</Alert> : ""

  return (
    <Container sx={{ p: 2, display: "flex", flexDirection: "column" }} >
      {alert}
      {!dateValidation && <Alert severity='error'>'From' time must be before 'To' time</Alert>}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="From"
            renderInput={(params) => <TextField sx={{ width: 210, m: 1 }} {...params} />}
            value={fromDate}
            ampm={false}
            onChange={(newValue) => {
              setFromDate(newValue);
            }}
          />
          <DateTimePicker
            label="To"
            renderInput={(params) => <TextField sx={{ width: 210, m: 1 }} {...params} />}
            value={toDate}
            ampm={false}
            onChange={(newValue) => {
              setToDate(newValue);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: "flex",
          mx: "auto",
          justifyContent: "center",
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <TextField
          sx={{ width: 150, m: 1 }}
          id="select-parser"
          select
          label="Format Parser"
          value={logParser.code}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLogParser(parserFor(event.target.value as LogParser))
          }}
        >
          {logParsers.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ width: 150, m: 1 }}
          id="select-component"
          select
          label="Software Component"
          value={greenGrassComponent.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setGreenGrassComponent(componentFor(event.target.value))
          }}
        >
          {greenGrassComponents.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ width: 200 }}
          id="standard-search"
          label="Search"
          type="search"
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value)
          }}
        />

        <LoadingButton sx={{ width: 100, m: 1 }}
          loading={loading.status === RequestStatus.loading}
          startIcon={<RefreshIcon />}
          loadingPosition="start" variant="contained"
          onClick={handleLoad}
          disabled={!dateValidation}>
          Load
        </LoadingButton>
      </Box>
      <Box>
        <Button startIcon={<FullscreenIcon />} onClick={() => setFullScreen(true)}>Full Screen</Button>
        <Button startIcon={orderLatestFirst ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          onClick={() => setOrderLatestFirst(!orderLatestFirst)}
        >
          Time Sort
        </Button>
      </Box>
      {fullScreen && (
        <Dialog fullScreen open={true}>
          <Box>
            <Button startIcon={<CloseFullcreenIcon />} onClick={() => setFullScreen(false)}>Close Fullscreen</Button>
            <Button startIcon={orderLatestFirst ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              onClick={() => setOrderLatestFirst(!orderLatestFirst)}
            >
              Time Sort
            </Button>
          </Box>
          {logsTable}
        </Dialog>
      )}
      {!fullScreen && (<>{logsTable}</>)}
    </Container>
  );
}