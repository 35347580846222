import {
  Alert, Backdrop,
  Box,
  CircularProgress,
  FormControl, InputLabel, MenuItem, Select,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { Permission, hasPermisssion } from '../../model/security';
import {
  AppComponent, AppComponentDeployment,
  AppStoreApplication,
  RobotApplication,
  useGetCompanyApplicationQuery,
  useGetCompanyApplicationsQuery,
  useGetCompanyIotDevicesQuery,
  useGetStoreApplicationQuery,
  useUpdateCompanyApplicationMutation
} from '../../reducers/apiSlice';
import { selectCompanyId, selectCurrentAuth } from "../../reducers/authSlice";
import { appComponentShortName, getErrorMessage, iotDeviceSubId } from "../../utils";
import { ButtonWithConfirmationDialog } from '../common/ConfirmationDialogue';
import { AppInstallationStatus } from './AppInstallationStatus';

interface DeviceSelectorProps {
  companyId: string;
  usedDevices: string[]
  onDeviceSelected: (iotDevice: string) => void
}

// ========== DeviceSelector =========

const DeviceSelector = (props: DeviceSelectorProps) => {
  const {companyId, usedDevices, onDeviceSelected} = props;

  const [selectedDevice, setSelectedDevice] = useState<string>('');

  const {
    data: companyApps = [],
  } = useGetCompanyApplicationsQuery({ companyId });

  const {
    data: companyIotDevices = [],
  } = useGetCompanyIotDevicesQuery({ companyId });

  const handleDeviceSelection = (device: string) => {
    setSelectedDevice(device);
    onDeviceSelected(device);
  };

  // filter out devices that are not healthy or are already used
  const availableDevices = companyIotDevices.filter(device => {
    // fixme (vca): move HEALTHY to enum
    return device.status === 'HEALTHY' &&
      companyApps.every(app => app.deployment.every(deployment => deployment.iotDevice !== device.id)) &&
      usedDevices.every(usedDevice => usedDevice !== device.id || usedDevice === selectedDevice);
  });

  return (
    <FormControl sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', m: 2 }}>
      <InputLabel>Control Unit</InputLabel>
      <Select
        sx={{ width: '200px' }}
        value={selectedDevice}
        label="Control Units"
        onChange={(e) => handleDeviceSelection(e.target.value)}
      >
        {availableDevices.map(iotDevice => (
          <MenuItem key={iotDevice.id} value={iotDevice.id}>
            {iotDeviceSubId(iotDevice.id)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// ========== CompanyApplicationDeployment =========

// Fetch the data required for the application details page and render the component
export const CompanyApplicationDeployment = ({ appId }: { appId: string }) => {

  const companyId = useAppSelector(selectCompanyId)
  
  const {
    data: application,
    isLoading,
    isError: isAppError,
    error
  } = useGetCompanyApplicationQuery({ companyId, appId: appId ?? 'undefined'}, { skip: appId === undefined})

  const {
    data: storeApplication,
    isLoading: isStoreAppLoading,
    isError: isStoreAppError,
    error: storeAppError
  } = useGetStoreApplicationQuery({ idAndVersion: `${application?.appStoreId}-${application?.appStoreVersion}` }, 
    { skip: application === undefined })

  if (application && storeApplication) {
    return <CompanyApplicationDeploymentPrivate application={application} storeApplication={storeApplication}/>
  } else {
    let content
    if (isLoading || isStoreAppLoading) {
      content = <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    } else if (isAppError) {
      content = <Alert severity="error">{getErrorMessage(error)}</Alert>
    } else if (isStoreAppError) {
      content = <Alert severity="error">{getErrorMessage(storeAppError)}</Alert>
    }
    return <>{content}</>
  }
}

// ========== CompanyApplicationDeploymentPrivate =========

export const CompanyApplicationDeploymentPrivate = (
  { application, storeApplication }: { application: RobotApplication, storeApplication: AppStoreApplication }) => {

  const [deploymentConfigs, setDeploymentConfigs] = useState<AppComponentDeployment[]>([])
  const [deploymenInProgress, setDeploymentInProgress] = useState<Boolean>(true)
  const [errorMessage, setErrorMessage] = useState("");

  const companyId = useAppSelector(selectCompanyId)
  const auth = useAppSelector(selectCurrentAuth);

  const [updateApplication, {isLoading: isUpdateLoading}] = useUpdateCompanyApplicationMutation();

  const onDeviceSelected = (component: AppComponent) => {
    return function(iotDevice: string) {
      const deployIndex = deploymentConfigs.findIndex(deployment => deployment.component.name === component.name);
      const newDeploy = {
        iotDevice,
        component: {
          name: component.name,
          version: component.version
        }
      };
      if (deployIndex !== -1) {
        const updatedDeploymentConfigs = [...deploymentConfigs];
        updatedDeploymentConfigs[deployIndex] = newDeploy;
        setDeploymentConfigs(updatedDeploymentConfigs);
      } else {
        setDeploymentConfigs([...deploymentConfigs, newDeploy]);
      } 
    }  
  }

  const startAppInstall = async () => {
    // todo (vca): deep copy, do we need it?
    const deployment: AppComponentDeployment[] = [];
    for (let i = 0; i < deploymentConfigs.length; i++) {
      deployment.push({
        iotDevice: deploymentConfigs[i].iotDevice,
        component: {
          name: deploymentConfigs[i].component.name,
          version: deploymentConfigs[i].component.version
        },
      });
    }
    setErrorMessage("")
    await updateApplication({
      appId: application.id,
      companyId,
      update: {
        deployment,
      },
    }).unwrap().catch(err => setErrorMessage(getErrorMessage(err)))
    setDeploymentInProgress(true)
  }

  const startAppUninstall = async () => {
    setErrorMessage("")
    // update application
    await updateApplication({
      appId: application.id,
      companyId,
      update: {
        deployment: [],
      }
    }).unwrap().catch(err => setErrorMessage(getErrorMessage(err)))
    setDeploymentInProgress(true)
  }

  const handleCompletedStatus = (completed: boolean) => {
    setDeploymentInProgress(! completed)
  }

  const sortedDeployment = [...application.deployment].sort((a, b) => {
    return a.component.name > b.component.name ? 1 : -1
  });

  const installDisabled = deploymentConfigs.length !== storeApplication.components.length 
    || application.deployment.length > 0 || !hasPermisssion(auth, Permission.INSTALL_UNINSTALL_APP)

  const unInstallDisabled = application.deployment.length === 0
    || !hasPermisssion(auth, Permission.INSTALL_UNINSTALL_APP)

  // iot devices used by this application
  const iotDevices = application.deployment.map(item => item.iotDevice)

  // ------ UI ------
  let content
  if (isUpdateLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (deploymenInProgress && iotDevices && iotDevices.length > 0) {
    content = <AppInstallationStatus statusCompletedFn={handleCompletedStatus}
       iotDevices={application.deployment.map(item => item.iotDevice)} />
  }
  else {
    content =
      <>
        <Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
          <Typography component="h4" variant="h6" color="primary" gutterBottom>
            Application Components
          </Typography>
          
          Define on which control units the app components are installed.
          
          {application.deployment.length === 0 ?
              storeApplication.components.map(component => {
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                    key={`${component.name}`}>
                    <Typography sx={{ my: 2 }} variant='body1'>
                      {appComponentShortName(component.name)}
                      </Typography>
                    <DeviceSelector usedDevices={deploymentConfigs.map(item => item.iotDevice)}
                      companyId={companyId} onDeviceSelected={onDeviceSelected(component)} />
                  </Box>
                )
              })
              :
              <>
                {sortedDeployment.map(deploy => (
                  <Box key={`${deploy.iotDevice}-${deploy.component.name}`}
                    sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography variant='body1' sx={{ my: 2 }}>
                      {appComponentShortName(deploy.component.name)}
                      </Typography>
                    <TextField
                      sx={{ m: 2 }}
                      disabled
                      value={iotDeviceSubId(deploy.iotDevice)}
                    />
                  </Box>
                ))}
              </>
            }
          </Box>
          
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          {!hasPermisssion(auth, Permission.UPGRADE_APP) && 
            <Alert severity="info">
              You don't have a permission to manage the application.
            </Alert>}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
          <ButtonWithConfirmationDialog
            action={startAppInstall}
            disabled={installDisabled}
            title='Install'
            message="Are you sure you want to install the application?"
          />

          <ButtonWithConfirmationDialog
            action={startAppUninstall}
            disabled={unInstallDisabled}
            title='Uninstall'
            message="Are you sure you want to uninstall the application?"
          />
        </Box>
      </>
  }

  return (
    <>
      {content}
    </>
  )
}