import {
  Container,
  Tab,
  Tabs
} from '@mui/material';
import { Link, Outlet, Route, Routes, useParams } from "react-router-dom";
import { SubRoute } from '../../enums';
import { useRouteMatch } from "../../utils";
import { ApplicationConfiguration } from './AppConfiguration';
import { CompanyApplicationDeployment } from './CompanyApplicationDeployment';
import { CompanyApplicationBasics } from './CompanyApplicationBasics';

export const CompanyApplication = () => {
  const { appId } = useParams();

  if (appId) {
    return <CompanyApplicationPrivate appId={appId}/>
  } else { return <></>}
}

function CompanyApplicationTabs() {

  let currentTab = useRouteMatch([
    SubRoute.BASICS,
    SubRoute.DEPLOYMENT,
    SubRoute.CONFIGURATION
  ]) ?? SubRoute.BASICS

  return (
    <Tabs value={currentTab}>
      <Tab label="Basics" value={SubRoute.BASICS}
        to={SubRoute.BASICS} component={Link} />
      <Tab label="Deployment" value={SubRoute.DEPLOYMENT} 
        to={SubRoute.DEPLOYMENT} component={Link} />
      <Tab label="Configuration" value={SubRoute.CONFIGURATION}
        to={SubRoute.CONFIGURATION} component={Link} />
    </Tabs>
  )
}

const CompanyApplicationPrivate = ({ appId }: { appId: string }) => (
  <Container maxWidth='xl' sx={{ background: 'white' }}>
    <CompanyApplicationTabs />
    <Routes>
      <Route path={'/'} element={<CompanyApplicationBasics appId={appId} />} />
      <Route path={SubRoute.BASICS} element={<CompanyApplicationBasics appId={appId} />} />
      <Route path={SubRoute.DEPLOYMENT} element={<CompanyApplicationDeployment appId={appId} />} />
      <Route path={SubRoute.CONFIGURATION} element={<ApplicationConfiguration appId={appId} configKey='app-config' />} />
    </Routes>
    <Outlet />
  </Container>
)
