import { Box, Chip, Link, CircularProgress } from '@mui/material';
import { useEffect } from "react";
import { Link as RouterLink } from 'react-router-dom';
import {
  useGetDeviceDeploymentQuery
} from "../../reducers/apiSlice";
import { getErrorMessage, iotDeviceSubId } from "../../utils";
import { DeploymentStatus } from '../../reducers/enums';
import { BaseRoute, SubRoute } from '../../enums';

export default function DeviceDeploymentStatus(props: { iotDevice: string, statusFn: (status: string) => void }) {

  const { iotDevice, statusFn } = props

  const {
    data: deployment,
    isSuccess,
    isError,
    error,
  } = useGetDeviceDeploymentQuery({ deviceId: iotDevice, type: 'primary' }, { pollingInterval: 5000 })

  useEffect(() => {
    if (deployment) {
      statusFn(deployment.status)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployment]);

  let softwareStatusContent

  if (isSuccess) {
    softwareStatusContent =
      <>
        {deployment &&
        <Box sx={{ p: 2, gap: 2, display: 'flex', alignItems: 'center' }}>
          <Link to={`/${BaseRoute.CONTROL_UNITS}/${iotDevice}/${SubRoute.SOFTWARE}`}
            component={RouterLink}>{iotDeviceSubId(iotDevice)}
          </Link>
          <Chip size='small'
            label={deployment.status}
            color={deployment.status === DeploymentStatus.COMPLETED ? 'success'
              : deployment.status === DeploymentStatus.FAILED ? 'error' : 'info'} />
          {deployment.status === DeploymentStatus.IN_PROGRESS &&
            <CircularProgress color="primary" size='25px' sx={{ justifyContent: 'center' }} />}
          </Box>
        }
      </>
  } else if (isError) {
    if (error && ('status' in error) && error.status === 404) {
      softwareStatusContent = "No deployment found."
    } else {
      softwareStatusContent = getErrorMessage(error)
    }
  }

  return (
    <>
      {softwareStatusContent}
    </>
  )
}
