import {
  Alert,
  Box,
  Button,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseRoute } from '../../enums';
import { useAppSelector } from '../../hooks';
import { AppStoreApplication, RobotApplicationCreate, useAddNewCompanyApplicationMutation, 
  useGetCompanyApplicationsQuery } from "../../reducers/apiSlice";
import { selectCompanyId, selectCurrentAuth } from '../../reducers/authSlice';
import { getErrorMessage } from '../../utils';
import { Permission, hasPermisssion } from '../../model/security';

export const AppConfigurator = ({storeApplication} : {storeApplication: AppStoreApplication}) => {
  
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const companyId = useAppSelector(selectCompanyId)
  const auth = useAppSelector(selectCurrentAuth);
  
  const {
    data: companyApplications = [],
  } = useGetCompanyApplicationsQuery({ companyId });
  
  const [addCompanyApplication, 
    { data: appCreateData,
      isSuccess: isAppCreateSuccess }] = useAddNewCompanyApplicationMutation()

  const handleCreate = async () => {
    if (storeApplication && storeApplication.id && storeApplication.version) {
      const application: RobotApplicationCreate = {
        appStoreId: storeApplication.id,
        appStoreVersion: storeApplication.version,
        name: name,
        configuration: []
      }
      await addCompanyApplication({ application: application, companyId })
        .unwrap().catch(err => setErrorMessage(getErrorMessage(err)))
    }
  }
  
  useEffect(() => {
    if (isAppCreateSuccess && appCreateData) {
      navigate(`/${BaseRoute.APPLICATIONS}/${(appCreateData as any).id}`)
    }
  }, [appCreateData, isAppCreateSuccess])

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    if (companyApplications.some(application => application.name === event.target.value)) {
      setNameError('Application with this name already exists.')
    } else {
      setNameError('')
      setErrorMessage('')
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant='h6'>{storeApplication.name}</Typography>
      <TextField
        sx={{ mt: 1, maxWidth: 350 }}
        value={name}
        helperText={nameError}
        error={nameError === '' ? false : true}
        onChange={handleTextChange}
        label="Name"
      />

      <Alert severity="info" sx={{ mt: 2 }}>
        This is a custom application already licensed to you and tailored to your kinematic setup.
        Select a name and continue to the control box application installation page.
      </Alert>

      {!hasPermisssion(auth, Permission.UPGRADE_APP) &&
        <Alert severity="info" sx={{ mt: 1 }}>
          You don't have a permission to create the application.
        </Alert>}

      {errorMessage && <Alert severity="error" sx={{ mt: 1 }}>{errorMessage}</Alert>}

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          disabled={!hasPermisssion(auth, Permission.INSTALL_UNINSTALL_APP)}
          onClick={() => handleCreate()}
          sx={{ mt: 2, p: 1 }}>
          Create
        </Button>
      </Box>
    </Box>
  )
}
