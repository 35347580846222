import { Container, Paper, Typography, Button, Box } from "@mui/material";

export default function Intro() {
  return (
    <Container sx={{p:2}}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: 'center'
        }}
      >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Welcome
        </Typography>
          <p>
            You account confirmation is currently pending...
          </p>
          <p>
            We will grant you the required permissions as soon are your organisation account is approved.
            Once this is done you will be able to manage your devices. Please contact us at sales@spinbotics.com
            if you need more support.
          </p>
        <Button sx={{m:2, width: 150}}
          variant="contained"
          color="primary"
          target="_top"
          rel="noopener noreferrer"
          href={`mailto:sales@spinbotics.com`}
        >
          Open Email
        </Button>
        </Box>
      </Paper>
    </Container>
  );
}
