import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseFullcreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box, Button, Dialog, Table, TableBody, TableCell, 
  TableContainer, TableHead, TablePagination, TableRow,
  useMediaQuery, useTheme } from "@mui/material";
import { ReactElement, useState } from "react";
import { Audit } from "../../reducers/auditLogsSlice";

interface AuditLogsProps {
  auditLogs: Audit[];
  searchTerm: string
}

interface Column {
  id: "time" | "resourceType" | "resourceId" | "event" | "user" | "description";
  label: string;
  width?: number;
  align?: "right";
  show?: boolean;
  format?: (value: any) => any;
  formatRow?: (value: Audit) => any;
}

export default function AuditLogsTable({ auditLogs, searchTerm }: AuditLogsProps) {
  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [fullScreen, setFullScreen] = useState(false)
  const [orderLatestFirst, setOrderLatestFirst] = useState(false)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const formatDate = (date: number) => {
    let dateStr = new Date(+date)
    return `${dateStr.toLocaleDateString()}, ${dateStr.toLocaleTimeString()}`
  }

  const columns: Column[] = [
    {
      id: "time",
      label: "Time",
      width: 100,
      show: showSmUp,
      format: (date) => formatDate(date)
    },
    {
      id: "resourceType",
      label: "Type",
      width: 100,
      show: showSmUp,
    },
    {
      id: "resourceId",
      label: "Resource",
      width: 120,
      show: showSmUp,
    },
    { id: "event", label: "Event", width: 150, show: true },
    { id: "user", label: "User", width: 150, show: true, format: (item) => highlight(item, searchTerm), },
    { id: "description", label: "Details", width: 200, show: showSmUp }
  ];

  const highlight = (item: string, searchTerm: string) => {
    if (searchTerm !== '') {
      const split = (item as string).split(searchTerm)
      let result: ReactElement[] = []
      for (let i = 0; i < split.length; i++) {
        if (i === split.length - 1) {
          result.push(<>{split[i]}</>)
        } else {
          result.push(
            <>
              <Box key={item + i} component="span">{split[i]}</Box>
              <Box key={item + i + 1} component="span" sx={{ bgcolor: "yellow" }}>{searchTerm}</Box>
            </>)
        }
      }
      return <div>{result}</div>
    }
    else {
      return item
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortAuditLogsLatestFirst = (auditLogs: Audit[]) => {
    return auditLogs.slice().sort((a: Audit, b: Audit) => a.time > b.time ? 1 : -1)
  }

  let auditLogsSorted = orderLatestFirst ? sortAuditLogsLatestFirst(auditLogs) : auditLogs

  let auditLogsTable =
    <Box>
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader size="small" aria-label="a dense sticky table">
          <TableHead>
            <TableRow>
              {columns.filter(item => item.show).map((column) => (
                <TableCell sx={{ m: 1, p: 1, minWidth: column.width }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {auditLogsSorted
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.time}>
                    {columns.filter(item => item.show).map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell sx={{ m: 1, p: 1, minWidth: column.width }}
                                      key={column.id} align={column.align}>
                          {column.format ? column.format(value) :
                            column.formatRow ? column.formatRow(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 1000]}
        component="div"
        count={auditLogs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

  return (
    <>
      <Box>
        <Button startIcon={<FullscreenIcon />} onClick={() => setFullScreen(true)}>Show Full Screen</Button>
        <Button startIcon={orderLatestFirst ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          onClick={() => setOrderLatestFirst(!orderLatestFirst)}
        >
          Time Sort
        </Button>
      </Box>
      {fullScreen && (
        <Dialog fullScreen open={true}>
          <Box>
            <Button startIcon={<CloseFullcreenIcon />} onClick={() => setFullScreen(false)}>Close Fullscreen</Button>
            <Button startIcon={orderLatestFirst ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              onClick={() => setOrderLatestFirst(!orderLatestFirst)}
            >
              Time Sort
            </Button>
          </Box>
          {auditLogsTable}
        </Dialog>
      )}
      {!fullScreen && (<>{auditLogsTable}</>)}
    </>
  );
}