import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box, FormControl, InputLabel, MenuItem,
  Paper,
  Select, TextField, Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import log from "loglevel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { SeverityLevel } from "../../model/enums";
import { EventRecord } from "../../model/model";
import { useGetCompanyRobotQuery } from "../../reducers/apiSlice";
import { selectCompanyId } from "../../reducers/authSlice";
import { RequestStatus } from "../../reducers/enums";
import {
  getDeviceEvents, selectDeviceEvents,
  selectEventsQueryParams, selectLoadingStatus
} from "../../reducers/eventsSlice";
import {
  QueryType,
  endOfThisDay, getEnumKeyByEnumValue,
  startOfThisDay
} from "../../utils";
import DeviceEventsTable from "./DeviceEventsTable";
import { logLevels } from './Events';

export default function DeviceEvents() {
  const { id } = useParams();
  if (id) {
    return <DeviceEventsPrivate robotId={id} />
  } else { return <></>}
}

function DeviceEventsPrivate({ robotId }: { robotId: string }) {

  // Default values for UI selectors
  let defaultToDate = endOfThisDay(new Date());
  let defaultFromDate = new Date();
  let defaultSeverity = SeverityLevel.WARN
  defaultFromDate.setDate(defaultToDate.getDate() - 1);
  defaultFromDate = startOfThisDay(defaultFromDate);

  const dispatch = useAppDispatch();

  const queryParams = useAppSelector(
    selectEventsQueryParams(robotId, QueryType.User)
  );

  if (queryParams) {
    if (queryParams.startTime) {
      defaultFromDate = new Date(queryParams.startTime);
    }
    if (queryParams.endTime) {
      defaultToDate = new Date(queryParams.endTime);
    }
    if (queryParams.severity) {
      defaultSeverity = queryParams.severity;
    }
  }

  let loading = useAppSelector(selectLoadingStatus)

  const companyId = useAppSelector(selectCompanyId)
  const { data: robot = { iotDevice: "no-iot-device" } }
    = useGetCompanyRobotQuery({ companyId: companyId, robotId })
  
  let events: EventRecord[] = useAppSelector(
    selectDeviceEvents(robot.iotDevice, QueryType.User)
  );

  const [fromDate, setFromDate] = useState<Date | null>(defaultFromDate);
  const [toDate, setToDate] = useState<Date | null>(defaultToDate);
  const [severity, setSeverity] = useState<string>(defaultSeverity);
  
  const dateValidation = fromDate !== null && toDate !== null
    && fromDate.getTime() < toDate.getTime();

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robot]);

  const handleLoad = () => {
    if (fromDate !== null && toDate !== null) {
      let severityEnum = getEnumKeyByEnumValue(SeverityLevel, severity);
      severityEnum = severityEnum == null ? SeverityLevel.WARN : severityEnum;
      dispatch(
        getDeviceEvents({
          iotDevice: robot.iotDevice,
          sourceId: robotId,
          startTime: fromDate,
          endTime: toDate,
          severity: severityEnum as SeverityLevel,
          type: QueryType.User,
        })
      );
    } else {
      log.error("Not all query parameters provided.");
    }
  };

  const alert = loading.status === RequestStatus.failed ?
    <Alert severity="error">{loading.error}</Alert> : ""

  return (
    <Paper
      sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      {alert}
      {!dateValidation && <Alert severity='error'>'From' time must be before 'To' time</Alert>}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Events
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField sx={{ maxWidth: 200, m: 1 }} {...props} />}
              label="From"
              value={fromDate}
              ampm={false}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
            />
            <DateTimePicker
              renderInput={(props) => <TextField sx={{ maxWidth: 200, m: 1 }} {...props} />}
              label="To"
              value={toDate}
              ampm={false}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <FormControl sx={{ width: 150, m: 1 }}>
            <InputLabel id="severity-label">Severity</InputLabel>
            <Select
              label="Severity"
              labelId="severity-label"
              value={severity}
              onChange={(newValue) => {
                setSeverity(newValue.target.value);
              }}
            >
              {logLevels.map(level => <MenuItem key={level.value} value={level.value}>{level.label}</MenuItem>)}
            </Select>
          </FormControl>
          <LoadingButton sx={{ width: 150, m: 1 }}
            loading={loading.status === RequestStatus.loading}
            startIcon={<RefreshIcon />}
            loadingPosition="start" variant="contained"
            onClick={handleLoad}
            disabled={!dateValidation}>
            Load
          </LoadingButton>
        </Box>
      </Box>
      <Box>
        <DeviceEventsTable events={events} />
      </Box>
    </Paper>
  );
}
