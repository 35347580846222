import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";

export default function HomePublic() {
  return (
    <Container sx={{ p: 2 }}>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia sx={{ width: '100%', mixBlendMode: 'multiply', justifyContent: 'center' }}
          component="img"
          height="auto"
          image="cloud_image.png"
          alt="SpinBOT Cloud"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            SpinBOT Cloud
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Upgrade the firmware and robot control software,
            install robotic applications, see the robot utilisation,
            parts life time and more...
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small"><Link href="https://www.spinbot-kit.com/contact-us">Contact Us</Link></Button>
        </CardActions>
      </Card>
    </Container>
  );
}
