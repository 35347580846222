import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { AppMetricQueryReq, getRobotAppMetricsData, getRobotModuleMetricsData, ModuleMetricQueryReq } from "./metricsSlice";
import { DynamicConfig, globalConfig } from "../configuration/config";
import { VERSION } from '../../src/version'
import { QueryType } from "../utils";

export interface UiStateSlice {
  metrics: {
    application: {
      [index: string]: AppMetricQueryReq | undefined
    },
    module: {
      [index: string]: ModuleMetricQueryReq | undefined
    },
  }
  app: {
    environment: string
    version: string
  },
  parts: {
    openViewName: string
  }
  releases: {
    openViewVariant: string
  }
}

const initialState: UiStateSlice = {
  metrics: {
    application: {},
    module: {}
  },
  app: {
    environment: 'n/a',
    version: 'n/a'
  },
  parts: {
    openViewName: 'none'
  },
  releases: {
    openViewVariant: 'none'
  }
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    appInit: (state: any) => {
      const config: DynamicConfig = globalConfig.get();
      state.app.environment = config.environment
      state.app.version = VERSION.raw
    },
    setPartsOpenView: (state, action: PayloadAction<string>) => {
      state.parts.openViewName = action.payload;
    },
    setVariantsOpenView: (state, action: PayloadAction<string>) => {
      state.releases.openViewVariant = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRobotAppMetricsData.fulfilled, (state, action) => {
        const query = action.payload.query
        if (query.type === QueryType.User) {
          state.metrics.application[query.robotId] = query
        }
      })
      .addCase(getRobotModuleMetricsData.fulfilled, (state, action) => {
        const query = action.payload.query
        const key = `${query.sourceType}_${query.sourceId}`
        if (query.type === QueryType.User) {
          if (state.metrics.module === undefined) {
            state.metrics.module = {}
          }
          state.metrics.module[key] = query
        }
      })
  }
})

export const selectAppMetricsReq =
  (robotId: string) =>
    (state: AppState): AppMetricQueryReq | undefined => {
      if (state.ui.metrics.application?.[robotId]) {
        return state.ui.metrics.application[robotId];
      } else return undefined;
    };

export const selectModuleMetricsReq =
  (moduleUniqueId: string) =>
    (state: AppState): ModuleMetricQueryReq | undefined => {
      if (state.ui.metrics.module?.[moduleUniqueId]) {
        return state.ui.metrics.module[moduleUniqueId];
      } else return undefined;
    };

export const selectAppInfo = (state: AppState): { environment: string, version: string } => {
  return state.ui.app
}

export const selectOpenViewName = (state: AppState): string => {
  return state.ui.parts.openViewName
}
export const selectOpenViewVariant = (state: AppState): string => {
  return state.ui.releases.openViewVariant
}

export const { appInit, setPartsOpenView, setVariantsOpenView } = uiSlice.actions

export default uiSlice.reducer;