import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { signOutUser } from "../reducers/authSlice";
import { BaseRoute, p } from '../enums';
import { selectAppInfo } from "../reducers/uiSlice";
import { useGetApiVersionQuery } from "../reducers/apiSlice";

export default function Settings() {

  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string | false>(false);

  const appInfo = useAppSelector(selectAppInfo)
  const {
    data: apiVersion,
  } = useGetApiVersionQuery()

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClick = async () => {
    window.localStorage.clear();
    dispatch(signOutUser())
    navigate(p(BaseRoute.HOME))
  }

  return (
    <>
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            General settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Environment: {appInfo.environment}
          </Typography>
          <Typography>
            UI Version: {appInfo.version}
          </Typography>
          <Typography>
            API Version: {apiVersion?.version}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Advanced settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Clear the local data cache. You will be also signed out.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Clear
          </Button>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
  