import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Button, Dialog, DialogActions, DialogContent, IconButton, TextField } from "@mui/material"
import { useState } from "react"
import { User } from "../../reducers/authSlice"
import DialogTitleBox from '../DialogTitleBox'

export default function UserDetails(props: { user: User }) {
  const { user } = props;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton size='small' color='inherit' onClick={() => setOpen(true)}>
        <ReadMoreIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <DialogTitleBox onClose={() => setOpen(false)}>Details</DialogTitleBox>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* <DialogContentText sx={{ pb: 2 }}>
            <b>{user.familyName} {user.givenName}</b>
          </DialogContentText> */}
          <TextField
            margin="dense"
            label='Name'
            value={user.givenName}
            disabled
          />
          <TextField
            margin="dense"
            label='Surname'
            value={user.familyName}
            disabled
          />
          <TextField
            margin="dense"
            label='Email'
            value={user.email}
            disabled
          />
          <TextField
            margin="dense"
            label='Provided Company'
            value={user.companyProvided}
            disabled
          />
          <TextField
            margin="dense"
            label='Company'
            value={user.company}
            disabled
          />
          <TextField
            margin="dense"
            label='Role'
            value={user.role}
            disabled
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}