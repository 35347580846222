import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import {
  CloudWatch,
  GetMetricDataCommand,
  GetMetricDataCommandOutput,
  ListMetricsCommand,
  ListMetricsCommandOutput,
  Metric,
  MetricDataQuery,
} from "@aws-sdk/client-cloudwatch";
import log from "loglevel";
import { globalConfig } from "../configuration/config";
import { AppMetricQueryReq, ModuleMetricQueryReq } from "../reducers/metricsSlice";

export enum MetricsNamespace {
  MODULE = "Spinbotics/Module",
  APPLICATION = "Spinbotics/Application"
}

export type QueryParams = {
  startTime: Date,
  endTime: Date,
  aggrPeriod: number
}

export type ApplicationMetrics = {
  [index: string]: { // index is metricName
    applicationIds: string[]
  }
}

export type ApplicationMetricMulti = {
  [index: string]: string[] | undefined  // index is robotName
}

export type ModuleMetrics = {
  [index: string]: { // index is metricName
    coreNames: string[]
  }
}

export type MetricRecord = {
  time: number; // unix time
  value: number;
} | undefined;

export type MultiMetricRecordMulti =  {
  [index: string]: number | string
}

class CloudWatchClient {
  private static instance: CloudWatchClient;
  private static client: any;
  private static tokenFn: () => Promise<string>

  private constructor(tokenFn: () => Promise<string>) {
    CloudWatchClient.tokenFn = tokenFn
  }

  private static async buildConnection() {
    log.debug("CloudWatchClient(): building connection.");
    const config = globalConfig.get();
    const user_pool = `cognito-idp.${config.region}.amazonaws.com/${config.user_pool_id}`;
    const loginData = {
      [user_pool]: await CloudWatchClient.tokenFn(),
    };

    CloudWatchClient.client = new CloudWatch({
      region: config.region,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: config.region },
        identityPoolId: config.identity_pool_id,
        logins: loginData,
      }),
    });
  }

  public static async getInstance(tokenFn: any): Promise<CloudWatchClient> {
    if (!CloudWatchClient.instance) {
      log.debug("CloudWatchClient(): creating new instance.");
      CloudWatchClient.instance = new CloudWatchClient(tokenFn);
      await CloudWatchClient.buildConnection()
    }
    return CloudWatchClient.instance;
  }

  public static async send(command: any): Promise<any> {
    let response: any
    try {
      response = await CloudWatchClient.client.send(command);
    } catch (err) {
      if (err instanceof Error && err.name === 'NotAuthorizedException') {
        log.debug("CloudWatchClient(): authorization exception.");
        // CognitoIdentityServiceException class
        await this.buildConnection()
        response = await CloudWatchClient.client.send(command);
      } else {
        throw err
      }
    }
    return response
  }

  public async listModuleMetrics(
    sourceId: string,
    sourceType: string
  ): Promise<ModuleMetrics> {

    const command = new ListMetricsCommand({
      Namespace: MetricsNamespace.MODULE,
      Dimensions: [{Name: "sourceId", Value: sourceId}, {Name: "sourceType", Value: sourceType}]
    });

    const response: ListMetricsCommandOutput =
      await CloudWatchClient.send(command);

    let m: ModuleMetrics = {}

    if (response.Metrics) {
      var metrics: Metric[] = response.Metrics;
      metrics.forEach( metric => {
        //m.metricNames.push(metric.MetricName ?? "Undefined")
        if (m[metric.MetricName ?? "undefined"] === undefined) {
          m[metric.MetricName ?? "undefined"] = {coreNames: []}
        }

        metric.Dimensions?.forEach(dimension => {
          if (dimension['Name'] === 'coreName') {
            m[metric.MetricName ?? "undefined"].coreNames.push(dimension['Value'] ?? "undefined")
          }
        })
      })
    }
    return m
  }

  public async listApplicationMetrics(
    robotId: string,
    coreName: string
  ): Promise<ApplicationMetrics> {

    const command = new ListMetricsCommand({
      Namespace: MetricsNamespace.APPLICATION,
      Dimensions: [{Name: "robotId", Value: robotId}, {Name: "coreName", Value: coreName}]
    });

    const response: ListMetricsCommandOutput =
      await CloudWatchClient.send(command);

    let m: ApplicationMetrics = {}

    if (response.Metrics) {
      var metrics: Metric[] = response.Metrics;
      metrics.forEach( metric => {
        if (m[metric.MetricName ?? "undefined"] === undefined) {
          m[metric.MetricName ?? "undefined"] = {applicationIds: []}
        }

        metric.Dimensions?.forEach(dimension => {
          if (dimension['Name'] === 'applicationId') {
            m[metric.MetricName ?? "undefined"].applicationIds.push(dimension['Value'] ?? "undefined")
          }
        })
      })
    }
    return m
  }

  public async getModuleMetricsData(
    query: ModuleMetricQueryReq
  ): Promise<MetricRecord[]> {
    let metricQueries: MetricDataQuery[] = []
    query.deviceIds.forEach((deviceId, i) => {
      const metricQuery: MetricDataQuery = {
        Id: "query_module_metric" + i,
        MetricStat: {
          Metric: {
            Namespace: MetricsNamespace.MODULE,
            MetricName: query.metricName,
            Dimensions: [
              {
                Name: "coreName",
                Value: deviceId,
              },
              {
                Name: "sourceId",
                Value: query.sourceId,
              },
              {
                Name: "sourceType",
                Value: query.sourceType
              },
            ],
          },
          Period: query.aggrPeriod * 60, // minutes to seconds
          Stat: "Sum",
        },
        ReturnData: false,
      };
      metricQueries.push(metricQuery)
    });

    return await this.getMetricsData(metricQueries, {startTime: query.startTime as Date, endTime: query.endTime as Date, aggrPeriod: query.aggrPeriod})
  }
   
  public async getAppMetricsData(query: AppMetricQueryReq
  ): Promise<MetricRecord[]> {
    let metricQueries: MetricDataQuery[] = []
    query.applicationIds.forEach((applicationId, i) => {
      const metricQuery: MetricDataQuery = {
        Id: "query_app_metric" + i,
        MetricStat: {
          Metric: {
            Namespace: MetricsNamespace.APPLICATION,
            MetricName: query.metricName,
            Dimensions: [
              {
                Name: "coreName",
                Value: query.deviceId,
              },
              {
                Name: "robotId",
                Value: query.robotId,
              },
              {
                Name: "applicationId",
                Value: applicationId,
              },
            ],
          },
          Period: query.aggrPeriod * 60, // minutes to seconds
          Stat: "Sum",
        },
        ReturnData: false,
      };
      metricQueries.push(metricQuery)
    });

    return await this.getMetricsData(metricQueries, {startTime: query.startTime as Date, endTime: query.endTime as Date, aggrPeriod: query.aggrPeriod})
  }
 
  async getMetricsData(
    metricQueries: MetricDataQuery[],
    rangeParams: QueryParams,
  ): Promise<MetricRecord[]> {
    const metricQuerySum: MetricDataQuery = {
      Id: "querymetricsum",
      Expression: "SUM(METRICS())",
      Period: rangeParams.aggrPeriod * 60,
      ReturnData: true,
    };

    const command = new GetMetricDataCommand({
      StartTime: rangeParams.startTime,
      EndTime: rangeParams.endTime,
      MetricDataQueries: [...metricQueries, metricQuerySum],
      ScanBy: "TimestampAscending",
      MaxDatapoints: 100000, // no paging
    });

    const response: GetMetricDataCommandOutput =
      await CloudWatchClient.send(command);

    // Zip two arrays returned by API (timestamps + values), size must match. We assume it does.
    var metric: MetricRecord[] = [];
    if (response.MetricDataResults) {
      var timestamps = response.MetricDataResults[0].Timestamps;
      var values = response.MetricDataResults[0].Values;
      if (timestamps && values) {
        metric = timestamps.map((timestamp, index) => {
          return {
            time: timestamp.getTime() / 1000,
            value: values?.[index] ? values?.[index] : 0,
          };
        });
      } else {
        log.warn("Timestamps or Values array undefined in the response.");
      }
    }
    return metric;
  }
}

export default CloudWatchClient;
