import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SignIn from "./auth/SignIn";
import SignOut from "./auth/SignOut";
import RobotsCards from "./robots/RobotsCards";
import Robot from "./robots/Robot";
import { ProtectedRoute } from "./ProtectedRoute";
import DeviceUtilisation from "./utilisation/DeviceUtilisation";
import MyBreadcrumbs from "./MyBreadcrumbs";
import DeviceEvents from "./events/DeviceEvents";
import SignUp from "./auth/SignUp";
import UserProfile from "./auth/UserProfile";
import { ResetPassword } from "./auth/ResetPassword";
import Home from "./Home";
import Intro from "./auth/Intro";
import SelectCompany from "./auth/SelectCompany";
import PartUtilisation from "./utilisation/PartUtilisation";
import NavDrawer from "./NavDrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import Settings from "./Settings";
import { BaseRoute, SubRoute, p } from "../enums";
import Part from "./parts/Part";
import IotDevice from "./iot/IotDevice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect } from "react";
import { appInit, selectAppInfo } from "../reducers/uiSlice";
import { PartsEdit } from "./parts/PartsEdit";
import { Users } from "./users/Users";
import { Releases } from "./Releases";
import { AuditLogs } from "./audit/AuditLogs";
import { Permission, Role, hasPermisssion } from "../model/security";
import { selectCurrentAuth } from "../reducers/authSlice";
import { Dashboard } from "./dashboard/Dashboard";
import { ControlUnits } from "./ControlUnits";
import Parts from './parts/Parts';
import { CompanyApplications } from "./applications/CompanyApplications";
import { CompanyApplication } from "./applications/CompanyApplication";
import { StoreApplications } from "./store/StoreApplications";
import StoreApplication from "./store/StoreApplication";
import AppConfigurator from "./store/AppConfigurator";

function Copyright(props: any) {

  const appInfo = useAppSelector(selectAppInfo)

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://spinbotics.com/">
        Spinbotics
      </Link>{" "}
      {new Date().getFullYear()}
      {", "}
      {appInfo.version }
    </Typography>
  );
}

const mdTheme = createTheme({
  spacing: 6,
  palette: {
    primary: {
      light: '#00c5e6',
      main: '#00809A',
      dark: '#004C5A',
      contrastText: '#fff',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
  },
});

function Main() {

  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const auth = useAppSelector(selectCurrentAuth)
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(appInit())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Router>
          <NavDrawer/>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" disableGutters={false} sx={{ p: 0, mt: 0, mb: 0 }}>
              <Box sx={{p:0}}>
                {showSmUp && <MyBreadcrumbs />}
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path={p(BaseRoute.PROFILE)} element={<UserProfile />} />
                    <Route path={p(BaseRoute.INTRO)} element={<Intro />} />
                    <Route path={p(BaseRoute.SELECT_COMPANY)} element={<SelectCompany />} />
                    <Route path={p(BaseRoute.SETTINGS)} element={<Settings />} />
                    <Route path={p(BaseRoute.DASHBOARD)} element={<Dashboard />} />
                    <Route path={p(BaseRoute.APPLICATIONS)} element={<CompanyApplications />} />
                    <Route path={p(BaseRoute.APPLICATIONS) + "/:appId/*"} element={<CompanyApplication />} />
                    <Route path={p(BaseRoute.CONTROL_UNITS)} element={<ControlUnits />} />
                    <Route path={p(BaseRoute.CONTROL_UNITS) + "/:iotId/*"} element={<IotDevice />} />
                    <Route path={p(BaseRoute.MODULES)} element={<Parts />} />
                    <Route path={p(BaseRoute.MODULES) + "/:idp"} element={<Part />} />
                    <Route path={p(BaseRoute.MODULES) + "/:idp/utilisation"} element={<PartUtilisation />} />
                    <Route path={p(BaseRoute.MODULES_EDIT)} element={<PartsEdit />} />
                    <Route path={p(BaseRoute.MODULES_EDIT) + "/:idp"} element={<Part />} />
                    <Route path={p(BaseRoute.DEVICES)} element={<RobotsCards />} />
                    <Route path={p(BaseRoute.DEVICES) + "/:id"} element={<Robot />} />
                    <Route path={p(BaseRoute.DEVICES) + "/:id/utilisation"} element={<DeviceUtilisation />} />
                    <Route path={p(BaseRoute.DEVICES) + "/:id/events"} element={<DeviceEvents />} />
                    <Route path={p(BaseRoute.USERS)} element={<Users />} />
                    <Route path={p(BaseRoute.RELEASES)} element={<Releases />} />
                    <Route path={p(BaseRoute.STORE)} element={<StoreApplications />} />
                    <Route path={p(BaseRoute.STORE) + "/:idAndVersion"} element={<StoreApplication />} />
                    <Route path={p(BaseRoute.STORE) + `/:idAndVersion/${SubRoute.CONFIGURATOR}`} element={<AppConfigurator />} />
                    {(hasPermisssion(auth, Permission.READ_AUDIT) || auth.role === Role.SYSADMIN) && <Route path={p(BaseRoute.AUDIT)} element={<AuditLogs />} /> }  
                  </Route>
                  <Route path={p(BaseRoute.SIGNUP)} element={<SignUp />} />
                  <Route path={p(BaseRoute.SIGNIN)} element={<SignIn />} />
                  <Route path={p(BaseRoute.SIGNOUT)} element={<SignOut />} />
                  <Route path={p(BaseRoute.RESET)} element={<ResetPassword />} />
                </Routes>
                {showSmUp && <Copyright sx={{ pt: 4 }} />}
              </Box>
            </Container>
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default Main;
