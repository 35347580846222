import {
  Box,
  Button,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { useAppSelector } from '../../hooks';
import { selectCurrentAuth } from '../../reducers/authSlice';
import { ModulesToBuy } from './ModulesToBuy';

//export const ModulesReview = (props: { handleNext: () => void }) => {
export const PlaceOrder = (props: { name: string, configuration: any, handleNext: () => void }) => {

  const { name, configuration, handleNext } = props;

  const auth = useAppSelector(selectCurrentAuth)

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ m: 1, p: 2, height: '400px', overflow: 'auto' }}>
            <Typography component="h2" variant="h6" color="primary" sx={{ m: 2 }} gutterBottom>Modules for your Robotic Device</Typography>
            <ModulesToBuy count={2} moduleType='Drive Units' />
            <ModulesToBuy count={4} moduleType='Control Units' />
            <ModulesToBuy count={2} moduleType='Base Connectors' />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ m: 1, p: 2, height: '400px' }}>
            <Typography component="h2" variant="h6" color="primary" sx={{ m: 2 }} gutterBottom>Your Robotic Device</Typography>
            <CardMedia
              sx={{
                mixBlendMode: 'darken',
                maxWidth: '300px',
                margin: '0px auto'
              }}
              component="img"
              alt="Robot"
              height="auto"
              image="https://www.spinbot-kit.com/wp-content/uploads/2022/09/front-image-landing-page.png"
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ m: 1, p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography sx={{ textAlignL: 'center' }} variant='h5'>Summary</Typography>
              {auth.user ?
                <Typography sx={{ m: 1 }} variant='h5'>20145$</Typography>
                : <Typography sx={{ m: 1 }} variant='h5'>Login to see prices</Typography>}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Stack direction="row" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', m: 1 }}>
                <Button
                  sx={{ m: 1 }}
                  variant='contained'
                  onClick={handleNext}
                >
                  Save
                </Button>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleNext}
                >
                  Buy
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}