import log from "loglevel";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BaseRoute } from "../enums";
import { useAppSelector } from "../hooks";
import { Role } from "../model/security";
import { selectCurrentAuth } from "../reducers/authSlice";
import { RequestStatus } from "../reducers/enums";
import { noCompany } from "../utils";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./common/ErrorFallback";

export enum Component {
  NONE,
  OUTLET,
  LANDING,
  SIGNIN
}

export const ProtectedRoute = ({ children }: any) => {
  const location = useLocation();
  const auth = useAppSelector(selectCurrentAuth);

  const [render, setRender] = useState<Component>(Component.NONE);
  const [landingPage, setLandingPage] = useState<string>("/" + BaseRoute.END_OF_UNIVERSE);

  useEffect(() => {
    // Allowed routes even if no company is selected for the non-SYSADMIN role 
    const allowedNoCompany = [BaseRoute.PROFILE, BaseRoute.SETTINGS]

    if (auth.status === RequestStatus.succeeded) {
      if (auth.companyId === noCompany.companyId && auth.role !== Role.SYSADMIN) {
        setLandingPage("/" + BaseRoute.INTRO)
        if (allowedNoCompany.includes(location.pathname.split("/")[1] as BaseRoute) ||
          location.pathname === landingPage) {
          setRender(Component.LANDING)
        } else {
          log.debug("ProtectedRoute(): No company, go to: " + landingPage);
          setRender(Component.LANDING)
        }
      } else {
        setRender(Component.OUTLET)
      }
    } else if (auth.status === RequestStatus.failed || auth.status === RequestStatus.idle) {
      log.debug("ProtectedRoute(): user not authenticated, go to /signin.");
      setRender(Component.SIGNIN)
    }
    else {
      log.debug("ProtectedRoute(): Authentication in progress...");
      setRender(Component.OUTLET)
    }
  }, [location.pathname, auth.status, auth.companyId, auth.role, landingPage]);

  if (render === Component.OUTLET)
    return children ? children : <><ErrorBoundary FallbackComponent={ErrorFallback}><Outlet /></ErrorBoundary></>
  else if (render === Component.SIGNIN)
    return <Navigate to="/signin" replace state={{ from: location }} />;
  else if (render === Component.LANDING)
    return <Navigate to={landingPage} />;
};
