import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import { getErrorMessage } from '../utils';

interface Props {
  severity?: AlertColor
  message: string
  duration?: number
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function SnackbarMsg({severity = 'error', message = '', duration = 3000} : Props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export function ErrorSnackbar(props: {error: any}) {
  const message = getErrorMessage(props.error)
  return (
    <SnackbarMsg message={message} duration={6000}/>
  );
}

export function SuccessSnackbar(props: {message: string}) { 
  return (
    <SnackbarMsg message={props.message} severity='success'/>
  );
}