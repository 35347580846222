import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { hasPermisssion, Permission } from "../../model/security";
import {
  DeploymentTemplate, useAddDeviceDeploymentMutation,
  useGetDeviceDeploymentQuery, useGetDeviceDeploymentTemplateQuery
} from "../../reducers/apiSlice";
import { selectCurrentAuth } from "../../reducers/authSlice";
import { getErrorMessage } from "../../utils";
import DataView from "./DataView";
import DialogUpdate from "./DialogUpgrade";
import SoftwareComponents from "./SoftwareComponents";
import { DeploymentStatus } from '../../reducers/enums';
import { ButtonWithConfirmationDialog } from '../common/ConfirmationDialogue';

const installWarningMessage = 
  'When you continue the control software will be restarted. ' +
  'This action can DAMAGE the moving arm. ' +
  'Make sure the robotic arm is turned off. '

export default function SoftwareVersion(props: { iotDevice: string }) {

  const { iotDevice: iotDeviceId } = props

  const [noDeployment, setNodeployment] = useState(false);
  const [open, setOpen] = useState(false);
  const [deploymentInProgress, setDeploymentInProgress] = useState(false);
  const [updateConfig, setUpdateConfig] = useState<DeploymentTemplate | null>(null)

  const auth = useAppSelector(selectCurrentAuth);

  const {
    data: deployment,
    isSuccess,
    isError,
    error,
  } = useGetDeviceDeploymentQuery({ deviceId: iotDeviceId, type: 'primary' }, { pollingInterval: 5000 })

  const {
    data: deploymentTemplates,
  } = useGetDeviceDeploymentTemplateQuery({ type: 'primary', variant: deployment?.variant ?? 'no-variant' },
    { skip: deployment === null })

  const [addDeployment] = useAddDeviceDeploymentMutation()

  const getLatestUpdate = (deploymentTemplates: any[]) => {
    return deploymentTemplates.reduce(function (prev, curr) {
      return prev.version > curr.version ? prev : curr;
    })
  }

  useEffect(() => {
    if (deploymentTemplates) {
      if (deployment && deployment.status === DeploymentStatus.COMPLETED) {
        // Check if there is a newer deployment template available
        let updateConfig
        updateConfig = deployment.version === null ? getLatestUpdate(deploymentTemplates) :
          deploymentTemplates.find(item => item.version > (deployment?.version ?? 99999))

        if (updateConfig !== undefined) {
          setUpdateConfig(updateConfig)
        }
      } else if (deployment?.status === DeploymentStatus.IN_PROGRESS) {
        setUpdateConfig(null)
      } else if (noDeployment) {
        setUpdateConfig(getLatestUpdate(deploymentTemplates))
      }
    }
  }, [deployment, noDeployment, deploymentTemplates]);

  useEffect(() => {
    if (deployment) {
      if (deployment.status === DeploymentStatus.COMPLETED
        || deployment.status === DeploymentStatus.FAILED) {
        setDeploymentInProgress(false)
      }
      else {
        setDeploymentInProgress(true)
      }
    }

  }, [deployment]);

  const handleInstall = (version: string) => {
    console.log("Installing..")
    setOpen(false)
    setUpdateConfig(null)
    setDeploymentInProgress(true)
    const deploymentReq = {
      deviceId: iotDeviceId,
      type: "primary",
      variant: deployment?.variant ?? 'no-variant',
      version: version
    }
    addDeployment({ deployment: deploymentReq })
  };

  const handleUninstall = () => {
    console.log("Uninstalling..")
    setOpen(false)
    setDeploymentInProgress(true)
    const deploymentReq = {
      deviceId: iotDeviceId,
      type: "primary",
      variant: deployment?.variant ?? 'no-variant',
      version: deployment?.version ?? 'unknown-deployment-version',
      skipComponents: ['com.spinbotics.spinbot-humble', 'com.spinbotics.spinbot-feature', 'com.spinbotics.spinbot']
    }
    addDeployment({ deployment: deploymentReq })
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let statusMessage = ""
  if (deployment?.status === DeploymentStatus.IN_PROGRESS) {
    statusMessage = "Installation is in progress. Make sure the device is idle and connected to the Internet."
  }
  else {
    if (updateConfig) {
      statusMessage = `Software update is available, version: ${updateConfig.version}.`
    }
    else {
      statusMessage = "Software is up-to-date."
    }
  }

  let deploymentInfo
  if (deployment) {
    deploymentInfo = [
      { label: 'Deployment', value: deployment.status, type: 'chip' },
      { label: 'Status', value: deployment.status === DeploymentStatus.COMPLETED ? 'OK' : deployment.reason ?? 'n/a' },
      { label: 'Version', value: `${deployment.variant} v.${deployment.version ?? 'n/a'}` },
      { label: 'Modified', value: (new Date(deployment.modifiedTimestamp)).toLocaleString() }
    ]
  }

  let softwareStatusContent
  if (noDeployment)
    softwareStatusContent = <Alert severity="info">No deployment found.</Alert>

  if (isSuccess) {
    softwareStatusContent = <>
      {deploymentInfo &&
        <Box sx={{ p: 1 }}>
          <DataView data={deploymentInfo} />
        </Box>
      }
      {hasPermisssion(auth, Permission.INSTALL_UNINSTALL_APP) &&
        <Box>
          <ButtonWithConfirmationDialog
            action={handleUninstall}
            disabled={deploymentInProgress}
            title='Uninstall'
            message={installWarningMessage}
          />
          <ButtonWithConfirmationDialog
            action={() => handleInstall(deployment?.version ?? 'unknown-deployment-version')}
            disabled={deploymentInProgress}
            title='Reinstall'
            message={installWarningMessage}
          />
        </Box>
      }
    </>
  } else if (isError) {
    if (error && ('status' in error) && error.status === 404) {
      if (noDeployment === false) {
        setNodeployment(true)
      }
    } else {
      softwareStatusContent = <Alert severity="error">{getErrorMessage(error)}</Alert>
    }
  }

  return (
    <Box sx={{ display: 'flex', pt: 2, flexDirection: 'column' }}>
      {updateConfig && <DialogUpdate config={updateConfig}
        open={open} handleClose={handleClose} handleUpdate={handleInstall} />}

      {softwareStatusContent}

      <Typography component="h4" variant="h6" color="primary" gutterBottom>
        Components
      </Typography>
      <SoftwareComponents iotDeviceId={iotDeviceId} />

      <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{ mt: 2 }}>
        Updates
      </Typography>
      <Box>
        {statusMessage}
      </Box>
      {hasPermisssion(auth, Permission.UPGRADE_APP) &&
        <LoadingButton disabled={!updateConfig} sx={{ width: 150, m: 1 }}
          loading={deploymentInProgress}
          startIcon={<RefreshIcon />}
          loadingPosition="start" variant="contained"
          onClick={handleOpen}>
          {deploymentInProgress ? 'Installing...' : 'Update'}
        </LoadingButton>
      }
    </Box>
  )
}
