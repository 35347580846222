import {
  Alert,
  Backdrop,
  Box,
  Button, Card, CardActionArea, CardActions,
  CardContent,
  CardHeader, CardMedia,
  Chip,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BaseRoute, p } from '../../enums';
import { useAppSelector } from '../../hooks';
import { getApplicationState } from '../../model/enums';
import { useGetCompanyApplicationsQuery } from '../../reducers/apiSlice';
import { selectCompanyId } from '../../reducers/authSlice';
import { getErrorMessage } from '../../utils';

export const CompanyApplications = () => {

  const companyId = useAppSelector(selectCompanyId)
  const navigate = useNavigate();

  const {
    data: applications = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCompanyApplicationsQuery({ companyId });

  const sortedApplications = applications?.slice().sort((a, b) => a.appStoreId > b.appStoreId ? 1 : -1)

  const handleDetails = (applicationId: string) => {
    navigate(`${p(BaseRoute.APPLICATIONS)}/${applicationId}`)
  }

  let content
  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (sortedApplications.length === 0) {
      content = <Alert severity="info">You don't have any applications installed.</Alert>
    } else {
      content =
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
          {
            sortedApplications.map(app => {
              const state = app.deployment.length > 0 ? getApplicationState("installed") : getApplicationState("created")
              return (
                <Card key={app.id}
                  sx={{
                    m: 1, width: { xs: '165px', sm: '200px' },
                    display: 'flex', alignItems: 'center', flexDirection: 'column'
                  }}>
                  <CardActionArea onClick={() => handleDetails(app.id)}>
                    <CardHeader
                      sx={{ textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      title={app.name}
                      subheader={app.appStoreId}
                    />
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Chip label={state.name} style={{ color: state.color, backgroundColor: state.background }} />
                    </CardContent>
                    {/* <CardMedia
                      sx={{
                        width: 'auto',
                        margin: 'auto',
                        mixBlendMode: 'multiply'
                      }}
                      component="img"
                      alt="Application"
                      height="70px"
                      image='/images/pick_and_place.svg'
                    /> */}
                  </CardActionArea>
                  <CardActions>
                    <Button onClick={() => handleDetails(app.id)}>Details</Button>
                  </CardActions>
                </Card>
              )
            })
          }
        </Box>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Box>
      {content}
    </Box>
  );
}
