import { Alert, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseRoute } from '../../enums';
import { useAppSelector } from '../../hooks';
import { AppStoreApplication, RobotApplicationCreate, useAddNewCompanyApplicationMutation } from '../../reducers/apiSlice';
import { selectCompanyId } from '../../reducers/authSlice';
import { getErrorMessage } from '../../utils';
import { AppConfiguratorMain } from './AppConfiguratorMain';
import { ModulesReview } from './ModulesReview';
import { PlaceOrder } from './PlaceOrder';

export const AppConfiguratorFlow = ({storeApplication} : {storeApplication: AppStoreApplication}) => {

  const [stage, setStage] = useState(1); // 1 = appConfig stage, 2 = robotConfig stage, 3 = Order/Summary stage
  const [name, setName] = useState('');
  const [configuration, setConfiguration] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const companyId = useAppSelector(selectCompanyId)

   const [addCompanyApplication, 
    { data: appCreateData,
      isSuccess: isAppCreateSuccess }] = useAddNewCompanyApplicationMutation()
 
  const handleAppConfigured = (name: string, configuration: any) => {
    setStage(2);
    setName(name);
    setConfiguration(configuration);
    console.log('name: ' + name + ', configuration: ' + configuration)
  }

  const handleReviewDone = () => {
    setStage(3);
  }
  
  const handleCreateApp = async () => {
    if (storeApplication && storeApplication.id && storeApplication.version) {
      const application: RobotApplicationCreate = {
        appStoreId: storeApplication.id,
        appStoreVersion: storeApplication.version,
        name: name,
        configuration: configuration
      }
      await addCompanyApplication({ application: application, companyId })
        .unwrap().catch(err => setErrorMessage(getErrorMessage(err)))
    }
  }
  
  useEffect(() => {
    if (isAppCreateSuccess && appCreateData) {
      navigate(`/${BaseRoute.APPLICATIONS}/${(appCreateData as any).id}`)
    }
  }, [appCreateData, isAppCreateSuccess])

  return (
    <>
      <Typography variant='h6'>{storeApplication.name}</Typography>
      {errorMessage && <Alert severity="error" sx={{ mt: 1 }}>{errorMessage}</Alert>}
      {
        stage === 1 && <AppConfiguratorMain handleNext={handleAppConfigured} />
      }
      {
        stage === 2 && <ModulesReview handleNext={handleReviewDone} />
      }
      {
        stage === 3 && <PlaceOrder name={name} configuration={configuration} handleNext={handleCreateApp}/>
      }
    </>
  );
}
