import EditIcon from '@mui/icons-material/Edit';
import { Box, Container } from '@mui/material';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { BaseRoute, p } from "../../enums";
import { useAppSelector } from "../../hooks";
import { hasPermisssion, Permission } from '../../model/security';
import { selectCurrentAuth } from "../../reducers/authSlice";
import InventoryRead from "./PartsRead";

export default function Parts() {

  const auth = useAppSelector(selectCurrentAuth)
  const navigate = useNavigate()
  const handleClickOpen = () => {
    navigate(p(BaseRoute.MODULES_EDIT));
  }

  return (
    <Container maxWidth='md' sx={{ background: 'white' }}>
      {hasPermisssion(auth, Permission.UPDATE_INVENTORY) &&
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Button variant="outlined" endIcon={<EditIcon />} onClick={handleClickOpen}>
            Edit Modules
          </Button>
        </Box>
      }
      <InventoryRead />
    </Container>
  );
}

