import {
  Grid, Card, CardMedia,
  Alert, Button, Box, Typography, Backdrop,
  CircularProgress
} from '@mui/material';
import { useGetStoreApplicationsQuery } from '../../reducers/apiSlice';
import { useNavigate } from 'react-router-dom';
import { BaseRoute, p } from '../../enums';
import { getErrorMessage } from '../../utils';

export const StoreApplications = () => {

  const navigate = useNavigate();

  const {
    data: appStoreApplications = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStoreApplicationsQuery();

  const handleStoreApplicationClick = (idAndVersion: string) => {
    navigate(`${p(BaseRoute.STORE)}/${idAndVersion}`)
  }

  let content
  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (appStoreApplications.length === 0) {
      content = <Alert severity="info">Currently there are no applications available for you.</Alert>
    }
    else {
      content =
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            {
              appStoreApplications.map((storeApplication) => {
                return (
                  <Grid key={storeApplication.id} sx={{ width: '230px', m: 2 }}>
                    <Card sx={{ borderRadius: '32px', height: '220px', display: 'flex', bgcolor: 'white' }}>
                      <CardMedia
                        sx={{ width: 'auto', mixBlendMode: 'multiply', m: 'auto' }}
                        component="img"
                        alt="Application"
                        height="130px"
                        image="/images/pick_and_place.svg" />
                    </Card>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant='h6' sx={{ textAlign: 'center' }} >
                        {storeApplication.name}
                      </Typography>
                      <Typography variant='body2' sx={{ color: '#808080', mt: 1, textAlign: 'center' }}>
                        {storeApplication.description}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          sx={{ mt: 1 }}
                          onClick={() => handleStoreApplicationClick(`${storeApplication.id}-${storeApplication.version}`)}
                        >
                          Find out more
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                )
              })
            }
          </Box>
        </Box>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }
  
  return (
    <Box sx={{ mb: 5 }}>
      {content}
    </Box>
  );
}