import { Auth } from "aws-amplify";
import PasswordField from "./PasswordField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {Container,TextField, Box, Button,Alert, AlertTitle, Typography} from "@mui/material";

export const ResetPassword = () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  // const [emailError, setEmailError] = useState('')
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const regEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const valid = regEx.test(email);

  async function sendCode(event: any) {
    event.preventDefault();
    await Auth.forgotPassword(email)
      .then(() => {
        setStage(2);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  async function resetPassword(event: any) {
    event.preventDefault();

    await Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
          setSuccess("Your password changed successfully");
          setTimeout(() => {
            navigate("/signin");
          }, 2000);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      {stage === 1 && (
        <Box component="form" onSubmit={sendCode} noValidate sx={{ mt: 1 }}>
          <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
              sx={{ textAlign: "center" }}
            >
              Password Reset
            </Typography>
          <TextField
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            disabled={!valid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send verification code
          </Button>
        </Box>
      )}

      {stage === 2 && (
        <Box onSubmit={resetPassword}>
          {error && (
            <Alert severity="error">
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )}
          {success && !error && (
            <Alert severity="success">
              <AlertTitle>{success}</AlertTitle>
            </Alert>
          )}
          <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
              sx={{ textAlign: "center" }}
            >
              Set New Password
            </Typography>
          <TextField
            value={code}
            onChange={(event) => setCode(event.target.value)}
            margin="normal"
            required
            fullWidth
            label="Confirmation code"
            name="code"
          />
          <PasswordField
            password={password}
            setPassword={(e: any) => setPassword(e.target.value)}
            confirmPassword={confirmPassword}
            setConfirmPassword={(e: any) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={resetPassword}
            disabled={password.length > 0 && password === confirmPassword ? false : true}
          >
            Change password
          </Button>
        </Box>
      )}
    </Container>
  );
};