import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LoginIcon from '@mui/icons-material/Login';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Alert, AlertTitle, Link } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import LoadingButton from "@mui/lab/LoadingButton";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectCurrentAuth, signInUser } from '../../reducers/authSlice';
import { RequestStatus } from "../../reducers/enums";
import { BaseRoute, p } from '../../enums';
import { Role } from '../../model/security';

export default function SignIn() {
 
  const [passwordType, setPasswordType] = useState('password')

  const navigate = useNavigate();
  const location : any = useLocation();
  const dispatch = useAppDispatch()
 
  const auth = useAppSelector(selectCurrentAuth)

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
  } else {
      setPasswordType('password')
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    await dispatch(signInUser({ email: email, password: password }));
  };

  useEffect(() => {
    if (auth.status === RequestStatus.succeeded) {
      const origin = location.state?.from?.pathname;
      if (auth.role === Role.SYSADMIN) {
        navigate(p(BaseRoute.SELECT_COMPANY))
      }
      else {
        navigate(origin || p(BaseRoute.DASHBOARD))
      }
    }
  }, [auth, navigate, location.state])

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LoginIcon />
        </Avatar>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          sx={{ textAlign: "center" }}
        >
          Sign in
        </Typography>

        {auth.error ? 
          <Alert severity="error">
            <AlertTitle>{auth.error}</AlertTitle>
          </Alert> : ""
        }

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            InputProps={{
              endAdornment: (
                <EmailOutlinedIcon />
              )
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={passwordType}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                passwordType === 'password' ? 
                  <VisibilityOutlinedIcon sx={{ cursor: 'pointer' }} onClick={togglePassword} /> : 
                  <VisibilityOffOutlinedIcon sx={{ cursor: 'pointer' }} onClick={togglePassword} />
              )
            }}
          />
          <LoadingButton sx={{ mt: 3, mb: 2 }}
            type="submit"
            fullWidth
            loading={auth.status === RequestStatus.loading}
            startIcon={<RefreshIcon />}
            loadingPosition="start" 
            variant="contained">
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/reset">
              { 'Forgot password?'}
              </Link>
            </Grid>
            <Grid item xs>
              <Link component={RouterLink} to="/signup">
                {"Don't have an account?"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>  
    </Container>
  );
}
