import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Box, Button, CardActions, CardMedia, CardActionArea } from '@mui/material';
import { Card, CardContent, Chip, CardHeader, Alert } from '@mui/material'
import { BaseRoute, p } from '../../enums';
import { useAppSelector } from '../../hooks';
import { getState } from '../../model/enums';
import { selectCompanyId } from '../../reducers/authSlice';
import { useGetCompanyRobotsQuery } from '../../reducers/apiSlice';
import { Robot } from '../../model/model';
import { getErrorMessage, iotDeviceSubId } from '../../utils';

export default function RobotsCards() {

  const companyId = useAppSelector(selectCompanyId)
  const {
    data: robots = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCompanyRobotsQuery(companyId, { pollingInterval: 5000 })
  
  const navigate = useNavigate();

  const onClickHandler = (robotId: string) => {
    navigate(p(BaseRoute.DEVICES) + "/" + robotId)
  }

  const slicedTitle = (title : string) => {
    let slicedTitle = title.slice(0,13);
    if(slicedTitle.length < title.length) {
      slicedTitle += '..';
    }
    return slicedTitle;
  }

  let content
  if (isLoading) {
    content = <CircularProgress color="primary" />
  } else if (isSuccess) {
    if (Object.keys(robots).length === 0) {
      content = <Alert severity="info">No devices found.</Alert>
    }
    else {
      content =
        robots
          .slice().sort((x: Robot, y: Robot) => getState(x.state).active > getState(y.state).active ? -1 : 1 )
          .map(robot => {
            const state = getState(robot.state)
            return (
              <Card
                key={robot.id}
                sx={{
                  m: 1,
                  width: {
                    xs: '165px',
                    sm: '200px'
                  },
                  display: 'flex', alignItems: 'center', flexDirection: 'column'
                }}
                style={state.active ? { backgroundColor: '' } : { backgroundColor: '#e0e0e0' }}
              >
                <CardActionArea onClick={() => onClickHandler(robot.id)}>
                  <CardHeader
                    sx={{ textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={slicedTitle(robot.name)}
                    subheader={iotDeviceSubId(robot.iotDevice)}
                  >
                  </CardHeader>
                  <CardMedia
                    sx={{
                      width: 'auto',
                      mixBlendMode: 'multiply',
                      margin: 'auto'
                    }}
                    component="img"
                    alt="Robot"
                    height="100"
                    image="robotBoxes.png" />
                  <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Chip label={robot.state}
                      style={{ color: state.color, backgroundColor: state.background }} />
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button 
                    onClick={() => onClickHandler(robot.id)}
                  >
                    Details
                  </Button>
                </CardActions>
              </Card>
            );
          })
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <Box
      sx={{ p: 1, display: 'flex', mx: "auto", flexWrap: 'wrap', justifyContent: 'center' }}>
      {content}
    </Box>
  )
}