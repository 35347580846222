import React from "react";
import { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import log from "loglevel";
import Main from "./components/Main";
import store, { persistor } from "./store";
import { DynamicConfig, globalConfig } from "./configuration/config";

function App() {
  log.debug("App created.");

  const config: DynamicConfig = globalConfig.get();
  log.debug("Loaded config, environment: " + config.environment);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: config.region,
        userPoolId: config.user_pool_id,
        userPoolWebClientId: config.user_pool_web_client_id,
      },
    });
  });

  return (
    <React.StrictMode>
      <Provider store={store}>
        <div className="App">
          <PersistGate loading={null} persistor={persistor}>
            <Main />
          </PersistGate>
        </div>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
