import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import log from "loglevel";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectCurrentAuth, signInUser } from '../../reducers/authSlice';
import { RequestStatus } from "../../reducers/enums";
import { BaseRoute, p } from '../../enums';

interface Props {
  email: string,
  password: string
}

export default function SignUpCompleted({email, password} : Props) {
 
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
 
  let auth = useAppSelector(selectCurrentAuth)

  useEffect(() => {
    if (auth.status === RequestStatus.succeeded) {
      log.debug("Sign-up completed, navigate to " + p(BaseRoute.DASHBOARD))
      navigate(p(BaseRoute.DASHBOARD))
    }
  }, [auth, navigate])

  const handleSubmit = async () => {
    await dispatch(signInUser({ email: email, password: password }))
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up completed
        </Typography>

        <Box sx={{ mt: 1 }}>
          <Typography>
            Your have been successfully registered. 
            We will assign you a role soon and let you know by email.
          </Typography>
          <LoadingButton sx={{ mt: 3, mb: 2 }}
            type="submit"
            fullWidth
            loading={auth.status === RequestStatus.loading}
            startIcon={<RefreshIcon />}
            loadingPosition="start" 
            variant="contained"
            onClick={handleSubmit}
            >
            Sign me in and Continue
          </LoadingButton>
        </Box>
      </Box>  
    </Container>
  );
}
