import { Container, Paper, Typography, TextField, MenuItem, Box, Button, Backdrop, CircularProgress, Alert } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseRoute, p } from "../../enums";
import { useAppDispatch } from "../../hooks";
import { Company, useGetCompaniesQuery } from "../../reducers/apiSlice";
import { companySet } from "../../reducers/authSlice";
import { getErrorMessage, noCompany } from "../../utils";

export default function SelectCompany() {

  const dispatch = useAppDispatch()
  const {
    data: companies = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCompaniesQuery()

  const companyFor = (companyId: string): Company => {
    return companies.find(x => x.companyId === companyId) ?? noCompany }

  const [company, setCompany] = useState<Company>(noCompany)

  const navigate = useNavigate();

  const handleClick = async () => {
    // todo (vca): await has no effect, fix, navigate after promise is resolved
    await dispatch(companySet(company.companyId))
    navigate(p(BaseRoute.DASHBOARD));
  }

  let content
  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (Object.keys(companies).length === 0) {
      content = <Alert severity="info">No companies found.</Alert>
    } else {
      content = (
        <Container sx={{ p: 2 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: "center",
                flexDirection: 'column',
              }}
            >
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Select Company
              </Typography>
              <TextField
                sx={{ width: 150, m: 2 }}
                id="select-component"
                select
                label="Company"
                value={company.companyId ?? noCompany.companyId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompany(companyFor(event.target.value))
                }}
              >
                {companies.map((option) => (
                  <MenuItem key={option.companyId} value={option.companyId}>
                    {option.name}
                  </MenuItem>
                ))}
                <MenuItem value={noCompany.companyId}>
                  {noCompany.name}
                </MenuItem>
              </TextField>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                Continue
              </Button>
            </Box>
          </Paper>
        </Container>
      )
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      {content}
    </>
  );
}
