import { Auth } from "aws-amplify";
import { useState } from "react";
import { useAppSelector } from "../../hooks";
import { selectCurrentAuth, selectCurrentUser} from "../../reducers/authSlice";
import log from "loglevel";
import { Alert, AlertTitle,Paper,Grid, TextField, Typography, Box, Button, CardActions, Container } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PasswordField from "./PasswordField";
import { Role } from "../../model/security";

export default function UserProfile() {
  const [oldPassword, setOldPassword] = useState("");
  const [showoldPassword, setShowOldPassword] = useState(false)
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState("");
  const [dataSuccess, setDataSuccess] = useState("");
  const [dataError, setDataError] = useState("");

  const auth = useAppSelector(selectCurrentAuth)  
  const userInfo = useAppSelector(selectCurrentUser)

  const [input, setInput] = useState(userInfo || {
    givenName: "", 
    familyName: "",
    phoneNumber: "",
    companyProvided: "",
    email: ""
  });

  const oldPasswordHandler = (event: any) => {
    setOldPassword(event.target.value);
  };

  const newPasswordHandler = (event: any) => {
    setNewPassword(event.target.value);
  };

  const confirmPasswordHandler = (event: any) => {
    setConfirmPassword(event.target.value);
  };
  const toggleOldPassword = () => {
    setShowOldPassword(!showoldPassword)
  }

  const allFieldsValidation =
  oldPassword.length < 1 ||
  newPassword.length < 1 ||
  confirmPassword.length < 1 ||
  newPassword !== confirmPassword ||
  oldPassword === newPassword 
      ? true
      : false;

  async function changeHandler() {

    let user = await Auth.currentAuthenticatedUser();
    let result = await Auth.updateUserAttributes(user, {
      family_name: input.familyName,
      given_name: input.givenName,
      phone_number: input.phoneNumber,
      "custom:companyProvided": input.companyProvided,
    })
      .then(() => {
          setDataSuccess("Saved.")
          log.debug(result); // SUCCESS
      })
      .catch((err) => {
        setDataError(err.message);
      });
  }

  const dataChanges = userInfo?.familyName !== input.familyName ||
        userInfo?.givenName !== input.givenName ||
        userInfo?.companyProvided !==input.companyProvided ||
        userInfo?.phoneNumber !==input.phoneNumber

  async function handleChangeClick(event: any) {
    event.preventDefault();

    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(currentUser, oldPassword, newPassword)
      .then(() => {
          setSuccess("Password has been changed");
          setOldPassword("");
      })
      .catch((err) => {
        log.error(err);
        setPasswordError(err.message);
      })
      .finally(() => {
        setNewPassword("");
        setConfirmPassword("");
      });
  }

  return (
    <Container sx={{ alignItems: "center" }} maxWidth='sm'>
      <Grid item xs={12} md={6} lg={6} sx={{pb:4}}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "auto",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
              sx={{ textAlign: "center" }}
            >
              Profile
            </Typography>
            {dataSuccess ? (
              <Alert severity="success">
                <AlertTitle sx={{ textAlign: "center" }}>
                  {dataSuccess}
                </AlertTitle>
              </Alert>
            ) : (
              ""
            )}

            {dataError && !dataSuccess ? (
              <Alert sx={{ texAlign: "center", m: 2 }} severity="error">
                <AlertTitle sx={{ textAlign: "center" }}>
                  {dataError}
                </AlertTitle>
              </Alert>
            ) : (
              ""
            )}
            <Box sx={{ display: "flex" }}>
              <TextField
                sx={{ 
                  m: 1, 
                  width: '50%'
                }}
                value={input.givenName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInput({...input, givenName: e.target.value} );
                }}
                id="name"
                label="Name"
              />
              <TextField
                sx={{ 
                  m: 1, 
                  width: '50%'
                }}
                value={input.familyName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInput({ ...input, familyName: e.target.value });
                }}
                id="surname"
                label="Surname"
              />
            </Box>
            <TextField
              sx={{ m: 1 }}
              value={input.email}
              InputProps={{ readOnly: true }}
              disabled
              id="email"
              label="Email"
            />
            <TextField
              sx={{ m: 1 }}
              value={input.companyProvided}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInput({ ...input, companyProvided: e.target.value });
              }}
              disabled
              id="company"
              label="Company"
            />
            <TextField
              sx={{ m: 1 }}
              type="tel"
              value={input.phoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInput({...input, phoneNumber: e.target.value });
              }}
              id="phone"
              label="Phone"
            />
            {/* Organisation and role */}
            <TextField
              sx={{ m: 1 }}
              value={auth.role}
              disabled
              label="Role"
            />
            {auth.role !== Role.SYSADMIN ? <TextField
              sx={{ m: 1 }}
              value={auth.companyId}
              disabled
              label="Organisation"
            /> : null}
            <CardActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={changeHandler}
                disabled={!dataChanges}
              >
                Save
              </Button>
            </CardActions>
          </Box>
        </Paper>
      </Grid>

      {/* 2 */}

      <Grid item xs={12} md={6} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "auto",
          }}
        >
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            Change Password
          </Typography>
          <Box sx={{ display: "grid" }}>
            {passwordError && !success ? (
              <Alert sx={{ texAlign: "center", m: 1 }} severity="error">
                <AlertTitle sx={{ textAlign: "center" }}>
                  {passwordError}
                </AlertTitle>
              </Alert>
            ) : (
              ""
            )}
            {success ? (
              <Alert severity="success">
                <AlertTitle sx={{ textAlign: "center" }}>{success}</AlertTitle>
              </Alert>
            ) : (
              ""
            )}
            <form>
              <TextField
                margin="normal"
                autoComplete="Current Password"
                fullWidth
                required
                label="Current Password"
                type={showoldPassword ? "text" : "password"}
                value={oldPassword}
                error={newPassword.length > 0 && oldPassword === newPassword ? true : false}
                helperText={newPassword.length > 0 && 
                  oldPassword === newPassword ? 
                    "Current password can't be the same with new password." : 
                    null}
                onChange={(e) => oldPasswordHandler(e)}
                InputProps={{
                  endAdornment:
                    showoldPassword  ? (
                      <VisibilityOffOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={toggleOldPassword}
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={toggleOldPassword}
                      />
                    )
                }}
              />
              <PasswordField 
                password={newPassword}
                confirmPassword={confirmPassword} 
                setPassword={newPasswordHandler}
                setConfirmPassword={confirmPasswordHandler}
              />
            </form>  
            <CardActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={allFieldsValidation}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleChangeClick}
              >
                Change
              </Button>
            </CardActions>
          </Box>
        </Paper>
      </Grid>
    </Container>
  );
}
