import { Box, Chip } from "@mui/material";

export interface Attr {
  label: string,
  value: string,
  type?: string,
}

export default function DataView(props: {data: Attr[]}) {
  const { data } = props

  return (
    <>
      {data.map(item => (
        <Box key={item.label}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box sx={{ width: 100 }}>{item.label}</Box>
          {item?.type === 'chip' ?
            <Box sx={{ width: 200 }}>
              <Chip size='small' 
                label={item.value}
                color={item.value === 'COMPLETED' ? 'success' : item.value === 'FAILED' ? 'error' : 'info'} /></Box> :
            <Box sx={{ width: 200 }}>{item.value}</Box>
        }
        </Box>
      ))}
    </>
  )
}