import {
  IconButton,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'

interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}
export default function DialogTitleBox(props: DialogTitleProps) {
  const { children, onClose } = props;

  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'inherit'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    );
  }