import CloseFullcreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery, useTheme
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { BaseRoute, p } from '../../enums';
import { ComponentType, componentsDefinition } from "../../model/enums";
import { EventRecord } from "../../model/model";

interface EventsProps {
  allowFullscreen?: boolean,
  events: EventRecord[];
}

interface Column {
  id: "time" | "value" | "type" | "sourceId" | "sourceType" | "level";
  label: string;
  width?: number;
  align?: "right";
  show?: boolean;
  format?: (value: any) => any;
  formatRow?: (value: EventRecord) => any;
}

export default function DeviceEvents({ events, allowFullscreen = true }: EventsProps) {

  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate()

  const [fullScreen, setFullScreen] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const formatDate = (date: string) => {
    let dateStr = new Date(date)
    return `${dateStr.toLocaleDateString()}, ${dateStr.toLocaleTimeString()},${dateStr.getMilliseconds()}`
  }

  const columns: Column[] = [
    {
      id: "time",
      label: "Time",
      width: 150,
      show: true,
      format: (date) => formatDate(date)
    },
    {
      id: "type",
      label: "Code",
      width: 100,
      show: true,
      formatRow: (item: EventRecord) => {
        return <>
          <Chip size='small' label={item.level}
            color={item.level === 'ERROR' ? 'error' :
              item.level === 'WARN' ? 'warning' : 'default'} />
          {"\u00A0" + item.type}</>
      }
    },
    {
      id: "value",
      label: "Details",
      width: 270,
      show: true
    },
    {
      id: "sourceType",
      label: "Component",
      width: 100,
      show: showSmUp,
      format: (type: string) => componentsDefinition[type].name,
    }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (sourceType: string, sourceId: string) => {
    if (componentsDefinition[sourceType].type === ComponentType.SBK_DEVICE) {
      navigate(`${p(BaseRoute.DEVICES)}/${sourceId}`)
    }
  }

  let eventsTable =
    <Box>
      <TableContainer sx={{ maxHeight: 1000 }}>
        <Table stickyHeader size="small" aria-label="a dense sticky table">
          <TableHead>
            <TableRow>
              {columns.filter(item => item.show).map((column) => (
                <TableCell sx={{ m: 1, p: 1, width: column.width }}
                  key={column.id}
                  align={column.align}

                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} 
                    sx={{ cursor: 'pointer' }} 
                    onClick={() => handleRowClick(row.sourceType, row.sourceId)} >
                    {columns.filter(item => item.show).map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell sx={{ m: 1, p: 1 }} key={column.id} align={column.align}>
                          {column.format ? column.format(value) :
                            column.formatRow ? column.formatRow(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 250, 1000]}
        component="div"
        count={events.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

  return (
    <>
      {allowFullscreen && (
        <Box>
          <Button startIcon={<FullscreenIcon />} onClick={() => setFullScreen(true)}>Show Full Screen</Button>
        </Box>
      )}
      {fullScreen && (
        <Dialog fullScreen open={true}>
          <Box>
            <Button startIcon={<CloseFullcreenIcon />} onClick={() => setFullScreen(false)}>Close Full Screen</Button>
          </Box>
          {eventsTable}
        </Dialog>
      )}
      {!fullScreen && (<>{eventsTable}</>)}
    </>
  );
}
