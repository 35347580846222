import { Box, Backdrop, CircularProgress, Alert } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAppSelector } from '../../hooks';
import { selectCompanyId, selectCurrentAuth } from '../../reducers/authSlice';
import { useGetUsersQuery } from '../../reducers/apiSlice';
import EditUser from './EditUser';
import { GridToolbar } from '@mui/x-data-grid';
import { useMediaQuery, useTheme } from "@mui/material";
import { hasPermisssion, Permission } from '../../model/security';
import UserDetails from './UserDetails';
import { getErrorMessage } from '../../utils';
import { useState } from 'react';

export const Users = () => {

  const companyId = useAppSelector(selectCompanyId)
  const auth = useAppSelector(selectCurrentAuth);
  const theme = useTheme();
  const showSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [pageSize, setPageSize] = useState<number>(50);

  const columns: GridColDef[] = [
    {
      field: 'User',
      headerName: 'User',
      width: 160,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.givenName || ""} ${params.row.familyName || ""}`
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.5,
      hide: !showSmUp
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.7,
    },
    {
      field: 'companyProvided',
      headerName: 'Provided Company',
      flex: 1,
      hide: !showSmUp
    },
    {
      field: hasPermisssion(auth, Permission.MANAGE_USERS) ? 'Edit' : 'Info',
      headerName: hasPermisssion(auth, Permission.MANAGE_USERS) ? 'Edit' : 'Info',
      flex: 0.5,
      hide: showSmUp && !hasPermisssion(auth, Permission.MANAGE_USERS),
      disableExport: true,
      renderCell: (params) => {
        return (
          <>
            {hasPermisssion(auth, Permission.MANAGE_USERS) ?
              <EditUser user={params.row} /> : <UserDetails user={params.row} />}
          </>
        )
      }
    },
  ];

  const {
    data: users = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery({ companyId })

  let content

  if (isLoading) {
    content = <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else if (isSuccess) {
    if (Object.keys(users).length === 0) {
      content = <Alert severity="info">No users found.</Alert>
    } else {
      content =
        <Box sx={{ height: '800px', width: '100%', bgcolor: 'white' }}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowId={(row) => row.userId}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
    }
  } else if (isError) {
    content = <Alert severity="error">{getErrorMessage(error)}</Alert>
  }

  return (
    <>
      {content}
    </>
  );
}
