import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DeploymentTemplate } from '../../reducers/apiSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function RnNoteItem(props: {label: string, items: string[]}) {
  if (props.items.length === 0)
    return <></>
  else 
    return (
      <Box sx={{ display: 'flex', flexDirection: "column", m:1 }}>
        <Box sx={{ width: 80 }}>
          <b>{props.label}</b>
        </Box>
        <Box>
          {props.items.map(item => <Typography key={item} sx={{p:0}}>- {item}</Typography>)}
        </Box>
      </Box>
    )
}

export default function DialogUpgrade(props: {config: DeploymentTemplate, open: boolean, handleClose: any, handleUpdate: any}) {
  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Software Update
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            Notice
          </Typography>
          <Typography gutterBottom sx={{m:2}}>
            This will schedule the immediate device update.
            Make sure the device is connected to the Internet.
            It will take some time to download the required software
            so do not disconnect or restart it until the update finishes.
          </Typography>
          <Alert severity="warning" sx={{m:2}}>
            Device MUST be in idle state as it will be restarted.
          </Alert>
          <Typography variant="h6" gutterBottom>
            Release Notes 
          </Typography>
          <Typography gutterBottom>
            <RnNoteItem label='Date' items={[props.config.notes.date]}/>
            <RnNoteItem label='Added' items={props.config.notes.added}/>
            <RnNoteItem label='Changed' items={props.config.notes.changed}/>
            <RnNoteItem label='Fixed' items={props.config.notes.fixed}/>
            <RnNoteItem label='Removed' items={props.config.notes.removed}/>
          </Typography>
          {/* <Typography variant="h6" gutterBottom>
            Updated Components
          </Typography>
          <Typography gutterBottom>
            <List sx={{p:0, m:0}}>
            {Object.keys(props.config.components).map(item => <ListItem sx={{p:0, m:0}}>- {item}</ListItem>)}
            </List>
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => props.handleUpdate(props.config.version)}>
            Update
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}