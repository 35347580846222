import log from "loglevel"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { EventRecord } from "../model/model"
import { AppState } from "../store"
import { QueryType } from "../utils"
import { selectIdToken, signInUser } from "./authSlice"
import { SeverityLevel } from "../model/enums"
import { RequestStatus } from "./enums"
import { DynamicConfig, globalConfig } from "../configuration/config"

export type EventQuerySave = {
  severity: SeverityLevel;
  startTime: number;
  endTime: number;
};

type QueryBase = {
  sourceId?: string;
  severity: SeverityLevel;
  startTime: Date;
  endTime: Date;
};

export interface DeviceEventsQuery extends QueryBase {
  iotDevice: string;
};

export interface DeviceListEventsQuery extends QueryBase {
  iotDevices: string[];
};
 
export interface DeviceEventsQueryReq extends DeviceEventsQuery {
  type: QueryType
};

export interface DeviceListEventsQueryReq extends DeviceListEventsQuery {
  type: QueryType
};
 
export interface DeviceEvents {
  [index: string]: {
    // QueryType (user, dashboard)
    query: EventQuerySave;
    data: EventRecord[];
  };
}

export interface EventsStateSliceMap {
  [index: string]: DeviceEvents; // index is IoT device
}

export interface EventsStateSlice {
  status: RequestStatus
  error: string
  items: EventsStateSliceMap
}

const initialState: EventsStateSlice = {
  status: RequestStatus.idle,
  error: "",
  items: {},
};

const getFetchFn = (url: URL, thunkAPI: any): any => {
  return () => { 
    const token = selectIdToken(thunkAPI.getState() as AppState)
    return fetch(url, {
      method: "GET",
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
  }
}

export const getDeviceEvents = createAsyncThunk(
  "getDeviceEvents",
  async (
    params: DeviceEventsQueryReq,
    thunkAPI
  ) => {
    let events: EventRecord[] = []
    try {
      const config: DynamicConfig = globalConfig.get();
      const query : any = {
        iotDevice: params.iotDevice,
        startTime: +params.startTime,
        endTime: +params.endTime,
        severity: params.severity
      }
      if (params.sourceId !== undefined) {
        query.sourceId = params.sourceId
      }
      const searchParams = new URLSearchParams({ ...query }).toString()
      const url = new URL(`${config.sbk_api}/events?${searchParams}`);
      
      let response = await getFetchFn(url, thunkAPI)()
      if (response.status === 401) {
        await thunkAPI.dispatch(signInUser())
        response = await getFetchFn(url, thunkAPI)()
      }
      events = await response.json()
    } catch (err) {
      return (err instanceof Error) ? thunkAPI.rejectWithValue(err.message) :
        thunkAPI.rejectWithValue(JSON.stringify(err))
    }

    return {
      sourceId: params.sourceId,
      iotDevice: params.iotDevice,
      startTime: +params.startTime,
      endTime: +params.endTime,
      severity: params.severity,
      type: params.type,
      events: events,
    };
  }
);

export const getDeviceListEvents = createAsyncThunk(
  "getDeviceListEvents",
  async (
    params: DeviceListEventsQueryReq,
    thunkAPI
  ) => {
    let events: EventRecord[] = []
    try {
      const config: DynamicConfig = globalConfig.get();

      // Fetch all events in a serial way
      for (let iotDevice of params.iotDevices) {
        const query: any = {
          iotDevice: iotDevice,
          startTime: +params.startTime,
          endTime: +params.endTime,
          severity: params.severity
        }

        const searchParams = new URLSearchParams({ ...query }).toString()
        const url = new URL(`${config.sbk_api}/events?${searchParams}`);

        let response = await getFetchFn(url, thunkAPI)()
        if (response.status === 401) {
          await thunkAPI.dispatch(signInUser())
          response = await getFetchFn(url, thunkAPI)()
        }
        let deviceEvents: EventRecord[] = await response.json()
        deviceEvents.forEach(item => events.push(item))
      }
    } catch (err) {
      return (err instanceof Error) ? thunkAPI.rejectWithValue(err.message) :
        thunkAPI.rejectWithValue(JSON.stringify(err))
    }

    return {
      // iotDevice: params.iotDevice,
      startTime: +params.startTime,
      endTime: +params.endTime,
      severity: params.severity,
      type: params.type,
      events: events,
    };
  }
);


export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDeviceEvents.pending, (state, action) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getDeviceEvents.fulfilled, (state, action) => {
        if (!state.items[action.payload.iotDevice]) {
          state.items[action.payload.iotDevice] = {};
        }
        state.status = RequestStatus.succeeded
        state.items[action.payload.iotDevice][action.payload.type] = {
          data: action.payload.events.sort((x ,y) => x.time > y.time ? -1 : 1),
          query: {
            startTime: +action.payload.startTime,
            endTime: +action.payload.endTime,
            severity: action.payload.severity
          },
        };
      })
      .addCase(getDeviceEvents.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.payload as string
        log.error(action.payload);
      })
      .addCase(getDeviceListEvents.pending, (state, action) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getDeviceListEvents.fulfilled, (state, action) => {
        if (!state.items['group']) {
          state.items['group'] = {};
        }
        state.status = RequestStatus.succeeded
        state.items['group'][action.payload.type] = {
          data: action.payload.events.sort((x ,y) => x.time > y.time ? -1 : 1),
          query: {
            startTime: +action.payload.startTime,
            endTime: +action.payload.endTime,
            severity: action.payload.severity
          },
        };
      })
      .addCase(getDeviceListEvents.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.payload as string
        log.error(action.payload);
      });

  },
});

export const selectEventsQueryParams =
  (deviceId: string, type: QueryType) =>
    (state: AppState): EventQuerySave => {
      if (state.events.items?.[deviceId]?.[type]) {
        return state.events.items[deviceId][type].query;
      } else return { startTime: 0, endTime: 0, severity: SeverityLevel.WARN };
    };

export const selectLoadingStatus =
  (state: AppState): { status: RequestStatus, error: string } => {
    return { status: state.events.status, error: state.events.error }
  }

export const selectDeviceEvents =
  (deviceId: string, type: QueryType) =>
    (state: AppState): EventRecord[] => {
      if (state.events.items?.[deviceId]?.[type]) {
        // Add unique identified for each line
        let i = 0;
        return state.events.items[deviceId][type].data.map((item: EventRecord) => ({
          ...item,
          // Add space to allow for word wrap
          value: item.value.replaceAll(":", ": ").replaceAll(";", "; "),
          id: (i++).toString(),
        }));
      } else return [];
    };

export const selectDeviceListEvents =
  (type: QueryType) =>
    (state: AppState): EventRecord[] => {
      if (state.events.items?.['group']?.[type]) {
        // Add unique identified for each line
        let i = 0;
        return state.events.items['group'][type].data.map((item: EventRecord) => ({
          ...item,
          // Add space to allow for word wrap
          value: item.value.replaceAll(":", ": ").replaceAll(";", "; "),
          id: (i++).toString(),
        }));
      } else return [];
    };


export default eventsSlice.reducer;
